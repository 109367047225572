.StatsContainer {
  /* background-color: rgb(17, 17, 17); */
  position: relative;
  /* background-size: 100% 100%; */
  /* border-radius: 5px; */
  /* background-image: url("../../../../photos/Stats-value.jpg"); */
}
.statsOfMobileAppContainer {
  width: max-content;
  margin-left: 330px;
  margin-top: 75px;
  margin-bottom: 10px;
  text-align: center;
}
.statsOfDownloadContainer {
  margin-bottom: 30px;
  text-align: end;
  margin-right: 55px;
  margin-top: -50px;
}
.statsOfYearsContainer {
  width: max-content;
  margin-left: 140px;
  margin-bottom: 30px;
  text-align: center;
}
.statsOfReviewContainer {
  width: max-content;
  margin-left: 430px;
  margin-bottom: 30px;
  text-align: center;
}
.mobileappsContainer{
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
  margin-top: 0px;
}
.downloadAppsContainer{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6px;
  margin-top: 0px;
}
.rotatingEffect {
  animation-direction: alternate;
  animation: rotate 1s infinite;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -1s);
  animation-iteration-count: 2;
  animation-fill-mode: both;
}

#container {
  perspective: 40px;
}

#inner {
  /* width: 20em;
  height: 18em; */
  /* background-color: white; */
  /* box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.2); */
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -o-transition: transform 0.5s;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(12deg);
  }
}

@keyframes shake {
  0% {
    transform: skewY(2deg);
  }
  100% {
    transform: skewY(-2deg);
  }
}

.StatsLeftSection {
  padding: 50px 0px 50px 0px;
  /* width: 750px; */
  /* margin: 0 auto; */
  position: relative;
}
.valubleStatsTitle {
  font-size: 55px;
  font-family: "Circular-Std-Medium";
  font-weight: 700;
  letter-spacing: -1px;
  color: rgb(97, 97, 97);
  padding-left: 120px;
  line-height: 1.2;
  margin-bottom: 10px;
}
.statsIcon__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}
.statsCount {
  font-size: 42px;
  font-family: Circular-Std-Bold;
  font-weight: 700;
}
.statsStarCount {
  font-size: 42px;
  font-family: Circular-Std-Bold;
  font-weight: 700;
  margin-left: 10px;
}
.statsIcon {
  height: 65px;
  align-self: center;
}

.statsName {
  font-size: 20px;
  font-family: Circular-Std-Book;
  text-align: center;
}

.firstStatsContainer {
  text-align: end;
  padding: 10px 30px 20px 30px;
  position: relative;
  color: #fff;

  border-radius: 8px;

  margin-top: 10px;
  margin-right: 20px;
}

.secondStatsContainer {
  text-align: start;
  padding: 10px 30px 20px 30px;
  position: relative;
  color: #fff;

  border-radius: 8px;

  margin-top: 10px;
  margin-left: 20px;
}

.thirdStatsContainer {
  text-align: end;
  padding: 10px 30px 20px 30px;
  position: relative;
  color: #fff;

  border-radius: 8px;

  margin-top: 30px;
  margin-right: 20px;
}

.fourthStatsContainer {
  text-align: start;
  padding: 10px 30px 20px 30px;
  position: relative;
  color: #fff;

  border-radius: 8px;

  margin-top: 30px;
  margin-left: 0px;
}

.statsImg {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.modal_about_section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.statusVideoContainer {
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  background-size: cover;
  background-image: url(https://images.domaincer.com/images/about_company_space.jpg);
}
.playIcon {
  font-size: 50px !important;
  color: #fff !important;
  cursor: pointer;
  margin-top: 300px;
}
.statsFirstRow {
  display: flex;
  padding-left: 150px;
}
.circleValubleStats__about {
  width: 70px;
  height: 70px;
  border: 30px solid #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  margin-bottom: -60px;
}
.telegramImgStats {
  position: absolute;
  top: 0;
  margin-top: 100px;
  margin-left: 30px;
}
.telegramImg {
  width: 120px;
  height: auto;
}
.floatingVerticalIcon {
  animation-name: floatingVertical;
  animation-duration: 2.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.about_youtubeCloseButton {
  font-weight: bold !important;
  cursor: pointer;
  font-size: 40px !important;
  color: rgb(255, 255, 255) !important;
}

.caseModalContainer__about {
  width: 70%;
  height: 70%;
  text-align: end;
}
.youtubeAboutVideo {
  width: 100%;
  height: 100%;
}

@keyframes floatingVertical {
  from {
    transform: translate(0px, 0px);
  }
  65% {
    transform: translate(0px, 15px);
  }
  to {
    transform: translate(0px, 0px);
  }
}

.statsValueText {
  color: #0077ff;
  font-size: 42px;
  font-family: Circular-Std-Bold;
}

.statsMobileName {
  color: #fff;
  font-size: 26px;
  font-family: Circular-Std-Medium;
}
.statsDownloadMobileName{
  color: #fff;
  font-size: 26px;
  font-family: Circular-Std-Medium;
  margin-right: -15px;
}

.diamondicon {
  color: #0077ff;
  font-size: 15px;
  font-family: Circular-Std-Bold;
}
.diamondDownload{
  margin-right: 50px;
}





.MobileAppStatsContainer{
  position: absolute;
  text-align: center;
  top: 170px;
    left: 340px;
}
.diamondiconMobileApp {
  color: #0077ff;
  font-size: 15px;
  font-family: Circular-Std-Bold;
}

.mobileappsInnerContainer{
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
  margin-top: 0px;
}

.statsValueMobileAppText {
  color: #0077ff;
  font-size: 42px;
  font-family: Circular-Std-Bold;
}
.statsMobileAppName {
  color: #fff;
  font-size: 26px;
  font-family: Circular-Std-Medium;
}
.DownloadStatsContainer {
 position: absolute;
 text-align: center;
 left: 660px;
 top: 260px;
}
.diamondiconDownload{
  color: #0077ff;
  font-size: 15px;
  font-family: Circular-Std-Bold;
}
.downloadInnerContainer{
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
  margin-top: 0px;
}
.statsValueDownloadText{
  color: #0077ff;
  font-size: 42px;
  font-family: Circular-Std-Bold;
}
.statsDownloadName{
  color: #fff;
  font-size: 26px;
  font-family: Circular-Std-Medium;
}
.YearsStatsContainer {
 position: absolute;
  text-align: center;
  top: 360px;
    left: 130px;
}
.diamondiconYears{
  color: #0077ff;
  font-size: 15px;
  font-family: Circular-Std-Bold;
}
.yearsInnerContainer{
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
  margin-top: 0px;
}
.statsValueYearsText{
  color: #0077ff;
  font-size: 42px;
  font-family: Circular-Std-Bold;
}
.statsYearsName{
  color: #fff;
  font-size: 26px;
  font-family: Circular-Std-Medium;
}
.ReviewStatsContainer {
 position: absolute;
 text-align: center;
 top: 475px;
 left: 430px;
}
.diamondiconReview{
  color: #0077ff;
  font-size: 15px;
  font-family: Circular-Std-Bold;
}
.ReviewInnerContainer{
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
  margin-top: 0px;
}
.statsValueReviewText{
  color: #0077ff;
  font-size: 42px;
  font-family: Circular-Std-Bold;
}
.statsReviewName{
  color: #fff;
  font-size: 26px;
  font-family: Circular-Std-Medium;
}

.StatsTitle {
  font-size: 52px;
  font-family: 'Circular-Std-Medium';
  font-weight: 700;
  letter-spacing: -1px;
  color: #fff;
  padding-left: 300px;
  line-height: 1.2;
  margin-bottom: 10px;
  position: absolute;
  padding-top: 75px;
}



@media only screen and (min-width: 321px) and (max-width: 768px) {
  .valubleStatsTitle {
    font-size: 42px;
    font-family: Circular-Std-Medium;
    font-weight: 700;
    letter-spacing: -1px;
    color: #fff;
    padding-left: 20px;
  }
  .caseModalContainer__about {
    width: 90%;
    height: 30%;
    text-align: end;
  }
  .statsIcon {
    height: 50px;
    align-self: center;
  }
  .statsStarCount {
    font-size: 30px;
    font-family: Circular-Std-Bold;
    font-weight: 700;
    margin-left: 10px;
  }
  .statsFirstRow {
    display: flex;
    padding-left: 5px;
  }
  .playIcon {
    margin-top: 70px;
  }
  .statusVideoContainer {
    height: 200px;
    margin-top: 30px;
  }
  .StatsContainer {
    /* padding: 120px 0px 130px 0px; */
    position: relative;
    /* background-size: cover; */
    /* background-image: url("../../../../photos/Statsmobile.jpg"); */
  }
  .StatsLeftSection {
    padding: 20px 0px 50px 0px;
    width: 100%;
    margin: 0 auto;
  }
  .firstStatsContainer {
    text-align: end;
    padding: 10px 15px 10px 15px;
    position: relative;
    color: #fff;
    border-radius: 8px;
    margin-top: 10px;
    margin-right: 0px;
  }
  .secondStatsContainer {
    text-align: start;
    padding: 10px 15px 10px 15px;
    position: relative;
    color: #fff;
    border-radius: 8px;
    margin-top: 10px;
    margin-left: 0px;
  }
  .thirdStatsContainer {
    text-align: end;
    padding: 10px 15px 10px 15px;
    position: relative;
    color: #fff;
    border-radius: 8px;
    margin-top: 30px;
    margin-right: 0px;
  }
  .fourthStatsContainer {
    text-align: start;
    padding: 10px 15px 10px 15px;
    position: relative;
    color: #fff;
    border-radius: 8px;
    margin-top: 30px;
    margin-left: 0px;
  }

  .statsCount {
    font-size: 30px;
    font-family: Circular-Std-Bold;
    font-weight: 700;
  }

  .statsName {
    font-size: 17px;
    font-family: Circular-Std-Book;
  }
  .telegramImgStats {
    position: absolute;
    top: 0;
  }
  .statsOfMobileAppContainer {
    width: max-content;
    margin-left: 110px;
    margin-right: auto;
    margin-bottom: 40px;
    text-align: center;
    margin-top: -92px;
  }
  .statsOfDownloadContainer {
    margin-bottom: 75px;
    text-align: initial;
    margin-left: 30px;
  }
  .downloadAppsContainer{
    display: flex;
    justify-content: inherit;
    margin-bottom: 6px;
    margin-top: 14px;
  }
  .statsOfYearsContainer {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    text-align: center;
  }
  .mobileappsContainer{
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
    margin-top: 14px;
  }
  .diamondDownload{
    margin-right: 0px;
    margin-left: 50px;
  }
  .statsOfReviewContainer {
    width: max-content;
    margin-left: 30px;
    margin-bottom: 30px;
    text-align: center;
  }



  .MobileAppStatsContainer{
    position: absolute;
    text-align: center;
    top: 120px;
    left: 70px;
  }
  .DownloadStatsContainer {
    position: absolute;
    text-align: center;
    left: 220px;
    top: 200px;
   }
   .YearsStatsContainer {
    position: absolute;
     text-align: center;
     top: 420px;
     left: 150px;
   }
   .ReviewStatsContainer {
    position: absolute;
    text-align: center;
    top: 600px;
    left: 50px;
   }
   .StatsTitle {
    font-size: 32px;
    font-family: Circular-Std-Medium;
    font-weight: 700;
    letter-spacing: -1px;
    color: #fff;
    padding-left: 45px;
    position: absolute;
    padding-top: 45px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 380px) {
  .StatsTitle {
    font-size: 32px;
    font-family: Circular-Std-Medium;
    font-weight: 700;
    letter-spacing: -1px;
    color: #fff;
    padding-left: 30px;
    position: absolute;
    padding-top: 45px;
  }

  .MobileAppStatsContainer{
    position: absolute;
    text-align: center;
    top: 120px;
    left: 40px;
  }
  .DownloadStatsContainer {
    position: absolute;
    text-align: center;
    left: 180px;
    top: 210px;
   }
   .YearsStatsContainer {
    position: absolute;
     text-align: center;
     top: 390px;
     left: 130px;
   }
   .ReviewStatsContainer {
    position: absolute;
    text-align: center;
    top: 550px;
    left: 30px;
   }
}

@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .statsFirstRow {
    display: flex;
    padding-left: 70px;
  }
  .valubleStatsTitle {
    font-size: 55px;
    font-family: "Circular-Std-Medium";
    font-weight: 700;
    letter-spacing: -1px;
    color: rgb(97, 97, 97);
    padding-left: 120px;
    line-height: 1.2;
    margin-bottom: 10px;
    width: 420px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1500px){

  .StatsTitle {
    font-size: 52px;
    font-family: 'Circular-Std-Medium';
    font-weight: 700;
    letter-spacing: -1px;
    color: #fff;
    padding-left: 350px;
    line-height: 1.2;
    margin-bottom: 10px;
    position: absolute;
    padding-top: 90px;
  }
  .MobileAppStatsContainer{
    position: absolute;
    text-align: center;
    top: 200px;
    left: 440px;
  }
  .DownloadStatsContainer {
    position: absolute;
    text-align: center;
    left: 840px;
    top: 350px;
   }
   .YearsStatsContainer {
    position: absolute;
     text-align: center;
     top: 450px;
     left: 200px;
   }
   .ReviewStatsContainer {
    position: absolute;
    text-align: center;
    top: 650px;
    left: 540px;
   }
   .telegramImgStats {
    position: absolute;
    top: 0;
    margin-top: 200px;
    margin-left: 50px; 
   }
}