.quotePage__container {
  display: flex;
  justify-content: center;
  height: fit-content;
  margin-top: 10vh;
  margin-bottom: 20vh;
}

.quotePage__body {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(97, 97, 97, 0.1);
  padding: 10px;
  border-radius: 5px;
}

.quote__question {
  font-family: Circular-Std-Medium;
  text-align: center;
  font-size: 48px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  margin-top: 20px !important;
}
.thankYou_title {
  font-family: Circular-Std-Medium;
  text-align: center;
  font-size: 48px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  margin-top: 30px;
  margin-bottom: 10px;
}

.quote__questionDescription {
  font-family: Circular-Std-Book;
  font-size: 18px;
  font-weight: 400;
  color: #616161;
  line-height: 1.5;
  margin-top: 10px;
  text-align: right;
}

.nextStep__btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
  margin-bottom: 30px;
  align-items: center;
}

.nextStep__btn {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  color: #4c9bfb !important;
  font-size: 32px !important;
  margin: 0px;
}
.nextStep__btnIcon {
  flex-direction: row;
  color: #4c9bfb !important;
  font-size: 28px !important;
  margin-top: 5px;
  margin-left: -10px;
}

.prevStep__div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.prevStep__btn {
  font-size: 32px !important;
}

.textInput__container {
  margin-top: 80px;
  margin-bottom: 80px;
}

.custom__inputField {
  font-family: Circular-Std-Medium;
  font-size: 100px;
  border: none;
  font-weight: 400;
  outline-width: 0px;
  -webkit-text-stroke: 1px #111111;
  -webkit-text-fill-color: white;
  border-bottom: 1px solid #111111;
}

.error__inputField {
  font-family: Circular-Std-Medium;
  font-size: 100px;
  border: none;
  font-weight: 400;
  outline-width: 0px;
  -webkit-text-stroke: 1px #111111;
  -webkit-text-fill-color: white;
  border-bottom: 1px solid #f80505;
}

.phonEmailContainer {
  display: flex;
}
.custom__onChangeInputField {
  font-family: Circular-Std-Medium;
  font-size: 100px;
  border: none;
  font-weight: 400;
  outline-width: 0px;
  border-bottom: 1px solid #111111;
}

.custom__inputFieldPhone {
  font-family: Circular-Std-Medium;
  font-size: 45px;
  border: none;
  font-weight: 400;
  outline-width: 0px;
  -webkit-text-stroke: 1px #111111;
  -webkit-text-fill-color: white;
  border-bottom: 1px solid #111111;
  margin-right: 20px;
}

.error__inputFieldPhone {
  font-family: Circular-Std-Medium;
  font-size: 45px;
  border: none;
  font-weight: 400;
  outline-width: 0px;
  -webkit-text-stroke: 1px #111111;
  -webkit-text-fill-color: white;
  border-bottom: 1px solid #f80505;
  margin-right: 20px;
}

.custom__onChangeInputFieldPhone {
  font-family: Circular-Std-Medium;
  font-size: 45px;
  border: none;
  font-weight: 400;
  outline-width: 0px;
  border-bottom: 1px solid #111111;
  margin-right: 20px;
}
.custom__inputFieldMail {
  font-family: Circular-Std-Medium;
  font-size: 45px;
  border: none;
  font-weight: 400;
  outline-width: 0px;
  -webkit-text-stroke: 1px #111111;
  -webkit-text-fill-color: white;
  border-bottom: 1px solid #111111;
  margin-left: 20px;
}

.error__inputFieldMail {
  font-family: Circular-Std-Medium;
  font-size: 45px;
  border: none;
  font-weight: 400;
  outline-width: 0px;
  -webkit-text-stroke: 1px #111111;
  -webkit-text-fill-color: white;
  border-bottom: 1px solid #f80505;
  margin-left: 20px;
}

.custom__onChangeInputFieldMail {
  font-family: Circular-Std-Medium;
  font-size: 45px;
  border: none;
  font-weight: 400;
  outline-width: 0px;
  border-bottom: 1px solid #111111;
  margin-left: 20px;
}

.custom__checkBox {
  color: #0077ff !important;
}

.image__container {
  width: 160px;
  margin: 5px;
}

.service__question {
  font-family: Circular-Std-Medium;
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  letter-spacing: -1px;
  margin: 20px;
}

.custom__textField {
  display: flex !important;
}

.brandingService__row {
  display: flex;
  margin: 40px;
  justify-content: space-around;
}
.brandingService__rowMobileOne {
  display: flex;
  margin: 40px;
  justify-content: space-around;
}
.brandingService__rowMobileTwo {
  display: flex;
  margin: 40px;
  justify-content: space-around;
}
.service__container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.service__button {
  display: flex;
  flex-direction: column;
  width: 120px !important;
  height: 120px !important;
  border-radius: 60px !important;
  background-color: #d5eaff !important;
  border: none !important;
}
.service__buttonIcon {
  width: 150px;
}

.service__buttonActive {
  display: flex;
  flex-direction: column;
  width: 120px !important;
  height: 120px !important;
  border-radius: 60px !important;
  background-color: #4c9bfb !important;
  border: 5px solid #d5eaff !important;
}
.service__buttonIconActive {
  font-size: 40px !important;
  color: #d5eaff !important;
  padding: 10px;
}

.service__title {
  font-family: Circular-Std-book;
  display: flex;
  color: rgb(0, 0, 0);
  margin-top: 10px;
  width: max-content;
}

.thankYou_text {
  font-family: Circular-Std-book;
  display: flex;
  color: rgb(0, 0, 0);
  font-size: 30px;
  width: max-content;
}

.custom__serviceLeftGrid {
  background-color: #111111 !important;
  border-radius: 5px;
}

.ourServices__font {
  font-family: Circular-Std-Medium;
  font-size: 90px;
  font-weight: 700;
  color: #616161;
  margin: 0px;
}

.ourServices__container {
  display: flex;
  flex-direction: column;
}

.subService__row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.subService__section {
  background-color: #d5eaff;
  padding: 15px;
  border-radius: 40px;
  width: max-content;
  margin-right: 30px;
  cursor: pointer;
}
.subService__title {
  font-family: Circular-Std-book;
  display: flex;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.subService__sectionActive {
  background-color: #4c9bfb;
  border: 5px solid #d5eaff;
  padding: 15px;
  border-radius: 40px;
  width: max-content;
  margin-right: 30px;
  cursor: pointer;
}
.subService__titleActive {
  font-family: Circular-Std-book;
  display: flex;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}

.custom__stepOneGrid {
  overflow: hidden;
}

.animated__gif {
  flex-basis: auto !important;
}

.quotePage__cardTitle {
  font-family: Circular-Std-Medium;
  display: inline;
  text-align: left;
  font-size: 20px;
  color: rgb(27, 27, 27);
  font-weight: 700;
  margin-top: 20px;
}

.quotePage__cardText {
  font-family: Circular-Std-Medium;
  display: inline;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: rgb(153, 153, 153);
  margin-top: 5px;
}
.quotePage__card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 3px 0px,
    rgba(0, 0, 0, 0.05) 0px 2px 6px 0px;
  border: none;
  border-radius: 10px !important;
  transition: all 0.15s ease 0s !important;
  transform: scale(1);
  cursor: pointer;
}
.quotePage__card:hover {
  transform: scale(0.98);
}
.quotePage__cardContent {
  margin-top: 20px;
  padding: 0px !important;
  text-align: center;
}

.submissionCard_img {
  /* width: 100px; */
  height: 135px;
}

.quotePage_cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submission_radioBtn {
  color: #0077ff !important;
  justify-content: flex-end !important;
  display: contents !important;
}

.handshake_image {
  height: 30% !important;
  width: 30% !important;
}

.getQuote_brandingImage {
  margin-top: 20px;
  width: 250px;
}
.userDetailsIcon {
  width: 40px;
  margin-right: 20px;
}
.userDetailsData {
  margin-left: 20px;
  font-size: 20px;
}
.userDetailsTitle {
  font-size: 20px;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .quotePage__container {
    display: flex;
    justify-content: center;
    height: fit-content;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .quote__question {
    font-family: Circular-Std-Medium;
    text-align: center;
    font-size: 42px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin-top: 20px !important;
  }
  .textInput__container {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .custom__onChangeInputField {
    font-family: Circular-Std-Medium;
    font-size: 25px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    border-bottom: 1px solid #111111;
    width: 100%;
  }
  .error__inputField {
    font-family: Circular-Std-Medium;
    font-size: 25px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 1px #111111;
    -webkit-text-fill-color: white;
    border-bottom: 1px solid #f80505;
    width: 100%;
  }
  .custom__inputField {
    font-family: Circular-Std-Medium;
    font-size: 25px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 1px #111111;
    -webkit-text-fill-color: white;
    border-bottom: 1px solid #111111;
    width: 100%;
  }
  .custom__onChangeInputFieldPhone {
    font-family: Circular-Std-Medium;
    font-size: 25px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    border-bottom: 1px solid #111111;
    width: 100%;
    margin-right: 0px;
  }
  .error__inputFieldPhone {
    font-family: Circular-Std-Medium;
    font-size: 25px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 1px #111111;
    -webkit-text-fill-color: white;
    border-bottom: 1px solid #f80505;
    width: 100%;
    margin-right: 0px;
  }
  .custom__inputFieldPhone {
    font-family: Circular-Std-Medium;
    font-size: 25px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 1px #111111;
    -webkit-text-fill-color: white;
    border-bottom: 1px solid #111111;
    width: 100%;
    margin-right: 0px;
  }
  .custom__onChangeInputFieldMail {
    font-family: Circular-Std-Medium;
    font-size: 25px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    border-bottom: 1px solid #111111;
    width: 100%;
    margin-left: 0px;
  }
  .error__inputFieldMail {
    font-family: Circular-Std-Medium;
    font-size: 25px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 1px #111111;
    -webkit-text-fill-color: white;
    border-bottom: 1px solid #f80505;
    width: 100%;
    margin-left: 0px;
  }
  .custom__inputFieldMail {
    font-family: Circular-Std-Medium;
    font-size: 25px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 1px #111111;
    -webkit-text-fill-color: white;
    border-bottom: 1px solid #111111;
    width: 100%;
    margin-left: 0px;
  }
  .phonEmailContainer {
    display: block;
  }
  .nextStep__btn {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    color: #4c9bfb !important;
    font-size: 25px !important;
    margin: 0px;
  }
  .userDetailsIcon {
    width: 30px;
    margin-right: 10px;
  }
  .userDetailsData {
    margin-left: 10px;
    font-size: 17px;
  }
  .userDetailsTitle {
    font-size: 17px;
  }
  .service__question {
    font-family: Circular-Std-Medium;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    letter-spacing: -1px;
    margin: 40px 0px;
  }
  .getQuote_brandingImage {
    margin-top: 50px;
    margin-bottom: 20px;
    width: 85%;
  }
  .brandingService__row {
    display: block;
    margin: 0px;
    justify-content: space-around;
  }
  .brandingService__rowMobileOne {
    display: flex;
    margin: 20px 0px;
    justify-content: space-around;
  }
  .brandingService__rowMobileTwo {
    display: flex;
    margin: 20px 0px;
    justify-content: space-around;
  }
  .service__buttonIcon {
    width: 100px;
  }

  .subService__row {
    display: block;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }
  .subService__section {
    background-color: #d5eaff;
    padding: 15px;
    border-radius: 40px;
    width: initial;
    cursor: pointer;
    margin: 10px;
  }
  .subService__sectionActive {
    background-color: #4c9bfb;
    border: 5px solid #d5eaff;
    padding: 15px;
    border-radius: 40px;
    width: initial;
    cursor: pointer;
    margin: 10px;
  }
  .subService__title {
    font-family: Circular-Std-book;
    display: block;
    color: rgb(0, 0, 0);
    cursor: pointer;
    text-align: center;
  }
  .subService__titleActive {
    font-family: Circular-Std-book;
    display: block;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
  }
  .thankYou_text {
    font-family: Circular-Std-book;
    display: flex;
    color: rgb(0, 0, 0);
    font-size: 18px;
    width: auto;
  }
  .thankYou_title {
    font-family: Circular-Std-Medium;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width:769px) and (max-width:1025px) {
  .custom__onChangeInputField {
    font-family: Circular-Std-Medium;
    font-size: 60px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    border-bottom: 1px solid #111111;
    width: 100%;
  }
  .error__inputField {
    font-family: Circular-Std-Medium;
    font-size: 60px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 1px #111111;
    -webkit-text-fill-color: white;
    border-bottom: 1px solid #f80505;
    width: 100%;
  }
  .custom__inputField {
    font-family: Circular-Std-Medium;
    font-size: 60px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 1px #111111;
    -webkit-text-fill-color: white;
    border-bottom: 1px solid #111111;
    width: 100%;
  }
  .custom__onChangeInputFieldPhone {
    font-family: Circular-Std-Medium;
    font-size: 35px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    border-bottom: 1px solid #111111;
    width: 100%;
    margin-right: 0px;
  }
  .error__inputFieldPhone {
    font-family: Circular-Std-Medium;
    font-size: 35px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 1px #111111;
    -webkit-text-fill-color: white;
    border-bottom: 1px solid #f80505;
    width: 100%;
    margin-right: 0px;
  }
  .custom__inputFieldPhone {
    font-family: Circular-Std-Medium;
    font-size: 35px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 1px #111111;
    -webkit-text-fill-color: white;
    border-bottom: 1px solid #111111;
    width: 100%;
    margin-right: 0px;
  }
  .custom__onChangeInputFieldMail {
    font-family: Circular-Std-Medium;
    font-size: 35px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    border-bottom: 1px solid #111111;
    width: 100%;
    margin-left: 20px;
  }
  .error__inputFieldMail {
    font-family: Circular-Std-Medium;
    font-size: 35px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 1px #111111;
    -webkit-text-fill-color: white;
    border-bottom: 1px solid #f80505;
    width: 100%;
    margin-left: 20px;
  }
  .custom__inputFieldMail {
    font-family: Circular-Std-Medium;
    font-size: 35px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 1px #111111;
    -webkit-text-fill-color: white;
    border-bottom: 1px solid #111111;
    width: 100%;
    margin-left: 20px;
  }
}