.casestudy_subheading {
  font-size: 48px;
  font-family: Circular-Std-Medium;
  font-weight: 700;
  letter-spacing: -1px;
  color: #222d38;
  text-align: center;
}
.casestudy_subtext {
  color: #617080;
  font-size: 22px;
  font-family: Circular-Std-Book;
  text-align: center;
  margin-top: 5px;
  width: 40%;
  margin: 10px auto;
}
.viewAllWorks {
  font-family: Circular-Std-Medium;
  display: inline;
  font-size: 22px;
  color: rgba(0, 119, 255, 0.99);
  font-weight: 800;
}
.ourWorksCardsSection {
  text-align: end;
  margin: 0px;
}
.thumbnailImgContainer {
  padding: 45px 20px 45px 20px;
  text-align: center;
}
.thumbnailImg {
  font-size: 50px !important;
  color: #fff !important;
  cursor: pointer;
}
.caseCard {
  /* height: 300px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 3px 0px,
    rgba(0, 0, 0, 0.05) 0px 2px 6px 0px !important;
  border-radius: 0px !important;
  background-color: #141519 !important;
  opacity: 0.9;
}
.caseCardBg_ryzone1 {
  min-height: 100%;
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url(https://images.domaincer.com/images/case_study_ryzone_image1.png)
      no-repeat top center;
  background-size: cover;
}
.caseCardBg_ryzone2 {
  min-height: 100%;
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url(https://images.domaincer.com/images/case_study_kkrice_thumbnail.png)
      no-repeat top center;
  background-size: cover;
}
.caseCardBg_fpm1 {
  min-height: 100%;

  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url(https://images.domaincer.com/images/case_study_fpm_image1.png) no-repeat
      top center;
  background-size: cover;
}
.caseCardBg_fpm2 {
  min-height: 100%;
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url(https://images.domaincer.com/images/case_study_praxis_thumbnail.png)
      no-repeat top center;
  background-size: cover;
}

.caseBtn {
  text-transform: initial !important;
  font-size: 18px !important;
  font-family: Circular-Std-Book !important;
  padding: 7px !important;
  margin: 0px !important;
  background-color: #28272b !important;
  border-radius: 0px !important;
}
.ViewArrow {
  margin-bottom: 0px;
  font-size: 27px !important;
  color: #fff !important;
}
.ViewArrowSuccess {
  margin-bottom: 0px;
  font-size: 27px !important;
  color: #9dc25f !important;
}

.ok_button {
  font-size: 12px !important;
  color: #28272b !important;
  background-color: #fff;
  padding: 2px 4px;
  font-weight: 700;
  font-family: "Circular-Std-Medium";
  border-radius: 2px;
  margin: 1px 0px;
  margin-right: 4px;
  transition: all 0.2s ease-in-out;
}

.caseBtn:hover > span > .viewProject {
  display: block;
  transition: all 0.2s ease-in-out;
}
.caseBtn:hover > span > .enterPIN_number {
  display: block;
  transition: all 0.2s ease-in-out;
}

.viewProject {
  display: block;
  color: #fff;
  font-size: 15px;
  animation: grow 0.5s forwards;
}
.enterPIN_number {
  display: none;
  color: #fff;
  font-size: 15px;
  animation: grow 0.5s forwards;
}
.enterPIN_numberOnChange {
  display: block;
  color: #fff;
  font-size: 15px;
  animation: grow 0.5s forwards;
}

@keyframes grow {
  0% {
    width: 0;
  }
  100% {
    width: 100px;
  }
}

.viewProject_error {
  color: #28272b;
}

.modal_case_section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.caseModalContainer {
  width: 70%;
  height: 70%;
  text-align: end;
}
.youtubeCaseVideo {
  width: 100%;
  height: 100%;
}
.cardHeading {
  font-size: 25px;
  font-family: Circular-Std-Book;
  padding: 10px 10px 10px 10px;
  text-align: start;
  color: #fff !important;
}
.cardSubHeading {
  font-size: 16px;
  font-family: Circular-Std-Book;
  padding: 0px 10px 0px 10px;
  text-align: start;
  color: #fff !important;
}
.closeCaseBtnModal {
  font-weight: bold !important;
  cursor: pointer;
  font-size: 40px !important;
  color: rgb(255, 255, 255) !important;
}

.getPin_input {
  background: transparent;
  border: none;
  color: white;
  font-weight: 900;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .casestudy_subheading {
    font-size: 42px;
    font-family: Circular-Std-Medium;
    font-weight: 700;
    letter-spacing: -1px;
    color: #222d38;
    text-align: center;
    margin-top: 50px;
  }
  .casestudy_subtext {
    color: #617080;
    font-size: 22px;
    font-family: Circular-Std-Book;
    text-align: center;
    margin-top: 5px;
    width: 80%;
    margin: 10px auto;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .casestudy_subheading {
    font-size: 42px;
    font-family: Circular-Std-Medium;
    font-weight: 700;
    letter-spacing: -1px;
    color: #222d38;
    text-align: center;
    margin-top: 50px;
  }
  .cardSubHeading {
    font-size: 16px;
    font-family: Circular-Std-Book;
    padding: 0px 10px 0px 10px;
    text-align: start;
    color: #fff !important;
    height: 40px;
  }
  .thumbnailImgContainer {
    padding: 25px 20px;
    text-align: center;
  }
}
