.instagram_blogHeaderContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 50px;
  margin-bottom: 20px;
  align-items: flex-end;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(97, 97, 97);
}
.instagram_blogHeading {
  font-size: 48px;
  font-weight: 700;
  font-family: Circular-Std-Medium;
  color: rgb(97, 97, 97);
  letter-spacing: -1px;
}
.instagramFeeds_titleRow {
  display: flex;
  justify-content: center;
}

.instagram_blogViewAll {
  font-size: 22px;
  font-family: Circular-Std-Medium;
  color: #0077ff;
  letter-spacing: -1px;
}

.instagram_blogSection {
  padding: 100px 0px 100px 0px;
  background-color: rgb(17, 17, 17);
}
.instagram_blogSectionImg {
  width: 100% !important;
  /* height: 200px; */
  border-radius: 3px;
}

.instagram_blogSectionLink {
  font-size: 17px;
  font-family: Circular-Std-Medium;
  color: rgb(0, 119, 255);
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.arrowRight {
  font-size: 20px !important;
  margin-bottom: -5px;
  margin-left: 10px;
}
.instagram_blogSectionCardContainer {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 3px 0px, rgb(0 0 0 / 5%) 0px 2px 6px 0px;
  border-radius: 5px;
  min-width: 300px;
  margin: 5px;
}

.instagram_captionHoverSection {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.8);
  color: #f1f1f1;
  min-width: 280px;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  padding: 10px;
  text-align: center;
  height: -webkit-fill-available;
  border-radius: 2px;
  padding-top: 30%;
}

.instagram_captionText {
  font-size: 14px;
  font-family: Circular-Std-Medium;
}

.instagram_blogSectionCardContainer:hover .instagram_captionHoverSection {
  opacity: 1;
}

.instagram_rightCaroselIcon {
  color: rgba(97, 97, 97);
  border-radius: 50%;
  border: 2px solid rgba(97, 97, 97);
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 3px 0px, rgb(0 0 0 / 5%) 0px 2px 6px 0px;
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
}
.instagram_leftCaroselIcon {
  color: rgba(97, 97, 97);
  border-radius: 50%;
  border: 2px solid rgba(97, 97, 97);
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 3px 0px, rgb(0 0 0 / 5%) 0px 2px 6px 0px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.instagram_blogMiddleSection {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  /* width: 1180px; */
  scroll-behavior: smooth;
}
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 0px violet;
}

.instagram_blogSectionContainer {
  justify-content: center;
  display: flex;
  margin: 20px;
}

.socialMediaIcons_row {
  display: flex;
}

.facebookIcon_domaincer {
  display: flex;
  border: 2px solid #4267b2;
  color: #4267b2;
  border-radius: 50px;
  padding: 4px;
  margin-right: 5px;
  box-shadow: inset 0 0 3px #4267b2;
}
.followUsButton_facebook {
  font-family: Circular-Std-Book !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #4267b2 !important;
  text-transform: capitalize !important;
  margin-right: 5px;
  margin-left: 3px;
  margin-top: 3px;
}

.instagramIcon_domaincer {
  display: flex;
  border: 2px solid #bc2a8d;
  color: #bc2a8d;
  border-radius: 50px;
  padding: 4px;
  font-size: 22px !important;
  margin-right: 5px;
  box-shadow: inset 0 0 3px #bc2a8d;
}
.followUsButton_instagram {
  font-family: Circular-Std-Book !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #bc2a8d !important;
  text-transform: capitalize !important;
  margin-right: 5px;
  margin-left: 3px;
  margin-top: 3px;
}
.linkedInIcon_domaincer {
  display: flex;
  border: 2px solid #0077b5;
  color: #0077b5;
  border-radius: 50px;
  padding: 4px;
  margin-right: 5px;
  box-shadow: inset 0 0 3px #0077b5;
}
.followUsButton_linkedIn {
  font-family: Circular-Std-Book !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #0077b5 !important;
  text-transform: capitalize !important;
  margin-right: 5px;
  margin-left: 3px;
  margin-top: 3px;
}
.youtubeIcon_domaincer {
  display: flex;
  border: 2px solid #ff0000;
  color: #ff0000;
  border-radius: 50px;
  padding: 4px;
  margin-right: 5px;
  box-shadow: inset 0 0 5px #ff0000;
}
.followUsButton_youtube {
  font-family: Circular-Std-Book !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #ff0000 !important;
  text-transform: capitalize !important;
  margin-right: 5px;
  margin-left: 3px;
  margin-top: 3px;
}

.viewPost__button {
  font-family: Circular-Std-Book !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  background-color: #0077ff !important;
  color: #fff !important;
  text-transform: capitalize !important;
  margin-left: 0px !important;
  margin-top: 20px !important;
}

.fade-in-right {
  -webkit-animation: fade-in-right 0.3s ease-in both;
  animation: fade-in-right 0.3s ease-in both;
}

@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .instagram_blogSection {
    padding: 50px 0px 50px 0px;
    background-color: rgb(17, 17, 17);
  }
  .instagram_blogHeaderContainer {
    display: flex;
    justify-content: space-between;
    margin: 0px 20px;
    margin-bottom: 20px;
    align-items: flex-end;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(97, 97, 97);
  }
  .instagram_blogHeading {
    font-size: 42px;
    font-weight: 700;
    font-family: Circular-Std-Medium;
    color: rgb(97, 97, 97);
    letter-spacing: -1px;
  }
  .instagram_blogMiddleSection {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    width: 100%;
    scroll-behavior: smooth;
  }
  .instagram_leftCaroselIcon {
    color: rgba(97, 97, 97);
    border-radius: 50%;
    border: 1px solid rgba(97, 97, 97);
    padding: 3px;
    cursor: pointer;
    margin-right: 5px;
  }
  .instagram_rightCaroselIcon {
    color: rgba(97, 97, 97);
    border-radius: 50%;
    border: 1px solid rgba(97, 97, 97);
    padding: 3px;
    cursor: pointer;
    margin-left: 5px;
  }
  .instagram_blogSectionCardContainer {
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 3px 0px,
      rgb(0 0 0 / 5%) 0px 2px 6px 0px;
    border-radius: 8px;
    min-width: 240px;
    margin: 5px;
  }
  .instagram_blogSectionImg {
    width: 100%;
    height: "400px";
    border-radius: 5px 5px 0px 0px;
  }

  .instagram_captionHoverSection {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.8);
    color: #f1f1f1;
    min-width: auto;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    padding: 10px;
    text-align: center;
    height: -webkit-fill-available;
    border-radius: 2px;
    padding-top: 30%;
  }
  .followUsButton_facebook {
    display: none;
  }
  .followUsButton_instagram {
    display: none;
  }
  .followUsButton_linkedIn {
    display: none;
  }
  .followUsButton_youtube {
    display: none;
  }
}
