/* banner section */
.BSSliderImgContainer_mobileapp {
  text-align: center;
  align-self: flex-end;
  margin-bottom: -50px;
}

.brandStrategySliderImg_mobileapp {
  margin-top: 20px;
  width: 450px;
}
.BSSliderRightSection_mobileapp {
  margin: 50px 0px 0px 0px;
}
.sliderHeading_mobileapp {
  font-size: 55px;
  font-family: "Circular-Std-Medium";
  font-weight: 700;
  letter-spacing: -1px;
  margin-bottom: 30px;
  z-index: -999;
}
.sliderSubHeading_mobileapp {
  font-family: Circular-Std-Book;
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 1.5;
  color: rgb(97, 97, 97);
}
.serviceTabsSection_mobileapp {
  padding: 30px 0px 60px 0px;
}
.MTabsWidth_mobileapp {
  width: auto;
  box-shadow: none !important;
}
.MTabsContainer > .MuiTabs-fixed {
  overflow-x: scroll !important;
}
.video_play {
  font-family: Circular-Std-Book !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  background-color: #fff !important;
  border: 1px solid #0077ff !important;
  color: #0077ff !important;
  text-transform: capitalize !important;
}
.video_play::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-color: #0077ff;
  transition: transform 300ms ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
}
.video_play:hover::before,
.video_play:focus::before {
  transform: scaleX(1);
}
.video_play {
  transition: color 300ms ease-in-out;
  z-index: 1;
}
.video_play:hover,
.video_play:focus {
  color: white !important;
}
.video_closeIcon_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 30px;
}
.video_closeIcon {
  font-size: 40px !important;
  cursor: pointer;
}

.video_Second_container {
  width: 70%;
  margin: 0 auto;
}

.video_iframe {
  width: 100%;
  margin: 0 auto;
}
/* how service will help you */

.particalsSection_mobileapp {
  position: absolute;
  width: 100%;
  height: 570px;
}
.BSHelpYouRightSection_mobileapp {
  background-color: rgb(17, 17, 17);
  padding: 150px;
}
.wespecialise_container {
  background-color: rgb(17, 17, 17);
  padding: 20px 0px;
}
.caseStudiesRightImg_mobileapp {
  margin-left: -100px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.BSHelpYouHeading_mobileapp {
  font-size: 55px;
  font-family: "Circular-Std-Medium";
  font-weight: 700;
  letter-spacing: -1px;
  color: rgb(97, 97, 97);
  max-width: 800px;
  margin: 0 auto;
}
.BSHelpYouPara_mobileapp {
  font-family: Circular-Std-Book;
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 1.5;
  color: #fff;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
}
.circleBSHelpYou_mobileapp {
  width: 70px;
  height: 70px;
  border: 30px solid #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  margin-bottom: -60px;
}

/* service content section */

.service_subheading_mobileapp {
  font-size: 55px;
  font-family: "Circular-Std-Medium";
  font-weight: 700;
  letter-spacing: -1px;
  color: #222d38;
  text-align: center;
}
.service_subtext_mobileapp {
  color: #617080;
  font-size: 22px;
  font-family: Circular-Std-Book;
  text-align: center;
  margin-top: 5px;
  width: 42%;
  margin: 10px auto;
}
.subServiceTabSection_mobileapp {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  scroll-behavior: smooth;
}
.subServiceContaincer_mobileapp {
  display: flex;
  padding: 15px 50px;
  cursor: pointer;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.subServiceImg_mobileapp {
  width: 45px;
  height: 45px;
  margin-right: 15px;
}
.subServiceTitle_mobileapp {
  font-family: Circular-Std-Book;
  color: #222d38;
  font-size: 15px;
  line-height: 22px;
}

.subServiceSubTitle_mobileapp {
  font-family: Circular-Std-Book;
  color: #617080;
  font-size: 15px;
  line-height: 22px;
}
.subServiceParaContainer_mobileapp {
  background-color: rgba(247, 249, 250, 0.6);
  cursor: pointer;
  margin: 0 auto;
  padding-bottom: 50px;
}
.subServiceParaContainer_mobileapp:hover {
  margin: 0 auto;
  background-color: rgba(1, 119, 255, 0.06);
  opacity: 1;
}

.subServiceFixedSection_mobileapp {
  width: 925px;
  position: relative;
}

.glowEffectBrand_mobileapp {
  position: absolute;
  left: 0%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}
.serviceContent_mobileapp {
  padding: 50px 100px;
  display: flex;
}
.subServicePara_mobileapp {
  margin-bottom: 8px;
  color: #222d38;
  font-size: 18px;
  font-family: Circular-Std-Book;
  padding: 0px 130px;
  text-align: center;
}
.subSericeVideo_mobileapp {
  text-align: center;
  padding: 0 100px;
}
.youtubeVideo_mobileapp {
  width: 80%;
  height: 370px;
  margin-bottom: 50px;
}

.glowEffectDigital_mobileapp {
  position: absolute;
  left: 30%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}
.glowEffectWorkFlow_mobileapp {
  position: absolute;
  left: 59%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}

/* blog section */
.blogSection_mobileapp {
  padding: 100px 0px 100px 0px;
  background-color: rgb(17, 17, 17);
}

.blogSectionContainer_mobileapp {
  justify-content: center;
  display: flex;
}
.leftCaroselIcon_mobileapp {
  color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.blogMiddleSection_mobileapp {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  width: 1180px;
  scroll-behavior: smooth;
}
.blogSectionCardContainer_mobileapp {
  border: 1px solid rgb(97, 97, 97);
  border-radius: 8px;
  min-width: 350px;
  margin: 20px 15px;
}
.blogSectionImg_mobileapp {
  width: 100%;
  height: 230px;
  border-radius: 5px 5px 0px 0px;
}
.blogSectionHeading_mobileapp {
  font-size: 28px;
  font-weight: 700;
  font-family: Circular-Std-Medium;
  margin-bottom: 10px;
  margin-top: 20px;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  color: #fff;
}
.blogSectionHeading_mobileapp::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -7px;
  z-index: 100;
  width: 200px;
  height: 1px;
  background: rgb(97, 97, 97);
}
.blogSectionSubText_mobileapp {
  font-size: 16px;
  font-family: Circular-Std-Book;
  color: #fff;
  line-height: 1.7;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
}
.blogSectionLink_mobileapp {
  font-size: 17px;
  font-family: Circular-Std-Medium;
  color: rgb(0, 119, 255);
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.arrowRight_mobileapp {
  font-size: 20px !important;
  margin-bottom: -5px;
  margin-left: 10px;
}
.rightCaroselIcon_mobileapp {
  color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 0px violet;
}

/* why choose us */
.whyChooseUsSection_mobileapp {
  padding: 100px 0px;
}
.whyChooseUsTitle_mobileapp {
  font-size: 55px;
  font-family: "Circular-Std-Medium";
  font-weight: 700;
  letter-spacing: -1px;
  text-align: center;
  margin-bottom: 50px;
}
.whyChooseColumnUpContainer_mobileapp {
  margin-bottom: 50px;
  text-align: end;
  margin-top: 50px;
}
.chooseTitle_mobileapp {
  font-size: 22px;
  font-family: Circular-Std-Book;
  font-weight: 600;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.8);
}

.choosePara_mobileapp {
  font-size: 18px;
  font-family: Circular-Std-Book;
  font-weight: 500;
  line-height: 1.5;
  color: rgb(97, 97, 97);
}
.whyChooseColumnDownContainer_mobileapp {
  text-align: end;
}
.chooseUsImageRow_mobileapp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chooseUsMiddleImg_mobileapp {
  width: 70%;
  margin-bottom: 15%;
}
.whyChooseColumn3UpContainer_mobileapp {
  margin-bottom: 50px;
  margin-top: 50px;
  text-align: left;
}
.whyChooseColumn3DownContainer_mobileapp {
  text-align: left;
}

.floatingUpDown {
  animation-name: floatingUpDown;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floatingUpDown {
  from {
    transform: translate(0px, 0px);
  }
  65% {
    transform: translate(0px, 10px);
  }
  to {
    transform: translate(0px, 0px);
  }
}

.onDemand_heading {
  font-size: 42px;
  font-family: Circular-Std-Medium;
  font-weight: 700;
  letter-spacing: -1px;
  color: #fff;
  margin-bottom: 20px;
  animation: servicev2heading 0.7s;
  margin-right: 20px;
}
.flutter_heading {
  font-size: 42px;
  font-family: Circular-Std-Medium;
  font-weight: 700;
  letter-spacing: -1px;
  color: #222d38;
  margin-bottom: 20px;
  animation: servicev2heading 0.7s;
  margin-right: 20px;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  /* banner section */
  .brandStrategySliderImg_mobileapp {
    margin-top: 70px;
    width: 80%;
    margin-bottom: 70px;
  }
  .sliderHeading_mobileapp {
    font-size: 42px;
    font-family: "Circular-Std-Medium";
    font-weight: 700;
    letter-spacing: -1px;
    margin-bottom: 30px;
  }
  .BSSliderRightSection_mobileapp {
    margin: 30px 0px 30px 0px;
  }
  .MTabsWidth_mobileapp {
    width: inherit;
    box-shadow: none !important;
  }
  .MTabsContainer > .MuiTabs-fixed {
    overflow-x: scroll !important;
  }
  .video_closeIcon_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-top: 200px;
    padding-bottom: 10px;
  }
  .video_Second_container {
    width: 70%;
    margin: 0 auto;
    padding-right: 10px;
  }

  /* how service will help you */
  .BSHelpYouRightSection_mobileapp {
    background-color: rgb(17, 17, 17);
    padding: 100px 15px;
  }
  .BSHelpYouHeading_mobileapp {
    font-size: 42px;
    font-family: "Circular-Std-Medium";
    font-weight: 700;
    letter-spacing: -1px;
    color: rgb(97, 97, 97);
    max-width: 800px;
    margin: 0 auto;
  }
  .BSHelpYouPara_mobileapp {
    font-family: Circular-Std-Book;
    font-size: 18px;
    line-height: 1.5;
    color: #fff;
    max-width: 800px;
    margin: 30px auto;
  }

  /* service content section */
  .service_subheading_mobileapp {
    font-size: 42px;
    font-family: "Circular-Std-Medium";
    font-weight: 700;
    letter-spacing: -1px;
    padding: 0px 10px;
  }
  .service_subtext_mobileapp {
    color: #617080;
    font-size: 22px;
    font-family: Circular-Std-Book;
    text-align: center;
    margin-top: 5px;
    width: 75% !important;
    margin: 10px auto;
  }
  .subServiceTabSection_mobileapp {
    overflow: scroll;
    display: -webkit-box !important;
    width: 100% !important;
    margin: 0 !important;
    scroll-behavior: smooth;
  }

  .glowEffectBrand_mobileapp {
    position: absolute;
    left: 6%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .serviceContent_mobileapp {
    padding: 20px 10px !important;
    display: flex !important;
  }
  .serviceContent_mobileappTwo {
    padding: 20px 10px 110px 10px;
    display: flex;
  }
  .serviceContent_mobileappThree {
    padding: 20px 10px 130px 10px;
    display: flex;
  }
  .subServicePara_mobileapp {
    margin-bottom: 8px;
    color: #222d38;
    font-size: 18px;
    font-family: Circular-Std-Book;
    padding: 0px 10px !important;
    text-align: left;
  }
  .subSericeVideo_mobileapp {
    text-align: center;
    padding: 0 10px !important;
  }
  .youtubeVideo_mobileapp {
    width: 100% !important;
    height: 200px !important;
    margin-bottom: 50px;
  }

  .glowEffectDigital_mobileapp {
    position: absolute;
    left: 30%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .glowEffectWorkFlow_mobileapp {
    position: absolute;
    left: 54%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .subServiceContaincer_mobileapp {
    display: flex;
    padding: 10px;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    margin-bottom: 15px;
    border-color: #0077ff;
  }
  .subServiceImg_mobileapp {
    width: 45px;
    height: 45px;
    margin-right: 15px;
  }
  .subServiceFixedSection_mobileapp {
    width: 925px;
    position: relative;
    height: 400px;
  }
  /* blog section */
  .blogMiddleSection_mobileapp {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    width: 100%;
    scroll-behavior: smooth;
  }
  .leftCaroselIcon_mobileapp {
    color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 3px;
    cursor: pointer;
    margin-right: 5px;
  }
  .rightCaroselIcon_mobileapp {
    color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 3px;
    cursor: pointer;
    margin-left: 5px;
  }
  .blogSectionCardContainer_mobileapp {
    border: 1px solid rgb(97, 97, 97);
    border-radius: 8px;
    min-width: 250px;
    margin: 10px;
  }
  .blogSectionImg_mobileapp {
    width: 100%;
    height: 175px;
    border-radius: 5px 5px 0px 0px;
  }

  /* why choose us */
  .whyChooseColumnUpContainer_mobileapp {
    margin-bottom: 50px;
    text-align: center;
    margin-top: 50px;
  }
  .whyChooseColumnDownContainer_mobileapp {
    text-align: center;
  }

  .whyChooseColumn3UpContainer_mobileapp {
    margin-bottom: 50px;
    margin-top: 50px;
    text-align: center;
  }
  .whyChooseColumn3DownContainer_mobileapp {
    text-align: center;
  }
  .chooseUsMiddleImg_mobileapp {
    width: 70%;
    margin-bottom: 0%;
    margin-top: 20%;
  }
  .whyChooseUsTitle_mobileapp {
    font-size: 42px;
    font-family: "Circular-Std-Medium";
    font-weight: 700;
    letter-spacing: -1px;
    text-align: center;
    margin-bottom: 0px;
  }
}

/* Tablet */
@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .glowEffectBrand_mobileapp {
    position: absolute;
    left: -8%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
  }
  .glowEffectDigital_mobileapp {
    position: absolute;
    left: 25%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
  }
  .glowEffectWorkFlow_mobileapp {
    position: absolute;
    left: 64%;
    right: 0;
    width: 370px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 80%
    );
    opacity: 0.4;
  }
}


@media screen and (max-width:600px){
  .blockchain-page{
    overflow: hidden;
  }
  .subServiceTabSection_portal{
    height:430px !important;
    overflow: hidden;
  }
}

.checkMaxWidth_block{
  max-width:700px !important;
  overflow-y: scroll !important;
}

@media screen and (max-width:1400px) {
  .checkMaxWidth_block{
    max-width:450px !important;
  }
}