.mollio-section {
  margin: 120px;
  margin-top: 100px;
  margin-bottom: 100px;
}
.mollio-sectiontow {
  margin: 120px;
  margin-top: 100px;
  margin-bottom: 100px;
}
.mollio__textDark {
  font-family: Circular-Std-Medium;
  font-size: 55px;
  font-weight: 700;
  color: rgb(0, 0, 0);
  line-height: 1.2;
  margin: 0px;
  letter-spacing: -1px;
  display: inline;
}
.mollio__textLight {
  font-family: Circular-Std-Medium;
  font-size: 55px;
  letter-spacing: -1px;
  color: rgb(153, 153, 153);
  font-weight: 700;
  line-height: 1.2;
  display: inline;
}

.mollio__textSmall {
  font-family: Circular-Std-Medium;
  font-size: 26px;
  color: rgb(0, 0, 0);
  font-weight: 800;
  margin: 0px;
}
.mollio__textBlue {
  font-family: Circular-Std-Medium;
  display: inline;
  font-size: 22px;
  color: rgba(0, 119, 255, 0.99);
  font-weight: 800;
  margin: 0px;
}

.mollio__card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 3px 0px,
    rgba(0, 0, 0, 0.05) 0px 2px 6px 0px;
  border: none;
  border-radius: 10px;
  transition: all 0.15s ease 0s !important;
  transform: scale(1);
  cursor: pointer;
}
.mollio__card:hover {
  transform: scale(0.98);
}

.mollio__textXXsmallDark {
  font-family: Circular-Std-Medium;
  display: inline;
  text-align: left;
  font-size: 16px;
  color: rgb(0, 0, 0);
  font-weight: 800;
  line-height: 1.5;
}

.mollio__textXXsmallLight {
  font-family: Circular-Std-Medium;
  display: inline;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: rgb(153, 153, 153);
}

.card-deck {
  margin-right: 0px !important;
}
.cardContent {
  margin-top: 20px;
  padding: 0px !important;
}

.viewAllArrow {
  font-size: 20px !important;
  margin: 0px 0px -5px 4px;
  color: rgba(0, 119, 255, 0.99);
}
.sectionTwoImg {
  width: 80px !important;
  height: 80px !important;
}

.leftSide__grid {
  padding-right: 30px !important;
}
.rightSide__grid {
  padding-left: 30px !important;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .mollio-section {
    margin: 100px 0px 50px 0px;
  }
  .mollio-sectiontow {
    margin: 0px;
    margin-bottom: 100px;
  }
  .mollio__textDark {
    font-family: Circular-Std-Medium;
    font-size: 42px;
    font-weight: 700;
    color: rgb(0, 0, 0);
    line-height: 1.2;
    margin: 0px;
    letter-spacing: -1px;
    display: inline;
  }
  .mollio__textLight {
    font-family: Circular-Std-Medium;
    font-size: 42px;
    letter-spacing: -1px;
    color: rgb(153, 153, 153);
    font-weight: 700;
    line-height: 1.2;
    display: inline;
  }
  .mollio__textXXsmallLight {
    font-family: Circular-Std-Medium;
    display: inline;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: rgb(153, 153, 153);
    display: none;
  }
  .sectionTwoImg {
    width: 60px !important;
    height: 60px !important;
    margin: 0 auto;
  }
  .cardContent {
    margin-top: 20px;
    padding: 0px !important;
    text-align: center;
  }
  .leftSide__grid {
    padding-right: 15px !important;
  }
  .rightSide__grid {
    padding-left: 15px !important;
  }
}


@media only screen and (min-width:769px) and (max-width:1025px) {
  .mollio__textXXsmallLight {
    font-family: Circular-Std-Medium;
    display: inline;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: rgb(153, 153, 153);
    display: none;
  }
  .cardContent {
    margin-top: 20px;
    padding: 0px !important;
    text-align: center;
  }
  .sectionTwoImg {
    width: 60px !important;
    height: 60px !important;
    margin: 0 auto;
  }
}
