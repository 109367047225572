.whyChooseUsSection {
  padding: 100px 0px;
}
.whyChooseUsTitle {
  font-size: 48px;
  font-family: Circular-Std-Medium;
  font-weight: 700;
  letter-spacing: -1px;
  text-align: center;
  margin-bottom: 30px;
}
.chooseTitle {
  font-size: 22px;
  font-family: Circular-Std-Book;
  font-weight: 600;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.8);
}
.choosePara {
  font-size: 18px;
  font-family: Circular-Std-Book;
  font-weight: 500;
  line-height: 1.5;
  color: rgb(97, 97, 97);
}
.about__chooseUsImageRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about_chooseUsMiddleImg {
  width: 70%;
  margin-bottom: 15%;
}
.about_chooseUsMiddleImg2 {
  width: 18%;
  position: absolute;
  margin-top: 100px;
}
.whyChooseColumnUpContainer {
  margin-bottom: 50px;
  text-align: end;
  margin-top: 50px;
}
.whyChooseColumnDownContainer {
  text-align: end;
}

.whyChooseColumn3UpContainer {
  margin-bottom: 50px;
  margin-top: 50px;
  text-align: left;
}
.whyChooseColumn3DownContainer {
  text-align: left;
}

.floatingUpDown {
  animation-name: floatingUpDown;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floatingUpDown {
  from {
    transform: translate(0px, 0px);
  }
  65% {
    transform: translate(0px, 10px);
  }
  to {
    transform: translate(0px, 0px);
  }
}
@media only screen and (min-width: 321px) and (max-width: 768px) {
  .whyChooseUsTitle {
    font-size: 42px;
    font-family: Circular-Std-Medium;
    font-weight: 700;
    letter-spacing: -1px;
    text-align: center;
    margin-bottom: 0px;
  }
  .whyChooseColumnUpContainer {
    margin-bottom: 50px;
    text-align: center;
    margin-top: 50px;
  }
  .whyChooseColumnDownContainer {
    text-align: center;
  }

  .whyChooseColumn3UpContainer {
    margin-bottom: 50px;
    margin-top: 50px;
    text-align: center;
  }
  .whyChooseColumn3DownContainer {
    text-align: center;
  }
  .about_chooseUsMiddleImg {
    width: 70%;
    margin-bottom: 0%;
    margin-top: 20%;
  }
}

@media only screen and (min-width:769px) and (max-width:1025px) {
  .whyChooseColumnDownContainer {
    text-align: end;
    margin-top: 50px;
  }
}