.pageNotFound_imageContainer {
  margin-top: 5vw;
  margin-bottom: 5vw;
  text-align: center;
}

.pageNotFound_image {
  width: 80%;
}

.pageNotFound_Title {
  font-family: Circular-Std-Medium;
  font-weight: 700;
  letter-spacing: -1px;
  font-size: 55px;
  color: #999;
  margin-top: 50px;
}

.pageNotFound_subTitle {
  font-family: Circular-Std-Medium;
  font-weight: 700;
  letter-spacing: -1px;
  font-size: 42px;
  color: rgb(0, 0, 0);
}

.pageNotFound_description {
  font-family: Circular-Std-Book;
  font-size: 42px;
  letter-spacing: -2px;
  color: rgb(97, 97, 97);
  line-height: 1.4;
  margin-bottom: 10px;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .pageNotFound_imageContainer {
    margin-top: 100px;
    margin-bottom: 20px;
    text-align: center;
  }

  .pageNotFound_image {
    width: 90%;
  }

  .pageNotFound_Title {
    font-family: Circular-Std-Medium;
    font-weight: 700;
    letter-spacing: -1px;
    font-size: 42px;
    color: #999;
    margin-top: 50px;
  }

  .pageNotFound_subTitle {
    font-family: Circular-Std-Medium;
    font-weight: 700;
    letter-spacing: -1px;
    font-size: 32px;
    color: rgb(0, 0, 0);
  }

  .pageNotFound_description {
    font-family: Circular-Std-Book;
    font-size: 32px;
    letter-spacing: -2px;
    color: rgb(97, 97, 97);
    line-height: 1.4;
    margin-bottom: 10px;
  }
}
