.ryzoneHeaderImage {
  /* width: 90%; */
  height: 360px;
  border: none;
}
.riceportalHeaderImage {
  height: 360px;
  border: none;
}
.fpmarketsHeaderImage {
  height: 360px;
  border: none;
}
.praxisHeaderImage {
  height: 360px;
  border: none;
}

.logoTextContaincer {
  display: flex;
  align-self: center;
  padding: 80px 0px 0px 0px;
}
.caseStudiesLogo {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 10px;
}
.caseStudieTitle {
  font-size: 60px;
  font-family: Circular-Std-Bold;
  margin-bottom: 30px;
}
.caseStudiesSubTitle {
  font-size: 18px;
  font-family: Circular-Std-Book;
  margin-bottom: 30px;
}
.caseStudiesDescript {
  font-size: 17px;
  font-family: Circular-Std-Book;
  margin-bottom: 30px;
  line-height: 1.8;
  color: #333333;
  margin-bottom: 20px;
}
.labelsSection {
  display: flex;
  margin-bottom: 50px;
  margin-top: 40px;
}
.labelsContainerReact_ryzone {
  display: flex;
  padding-right: 30px;
  border-right: 1px solid rgba(97, 97, 97, 0.5);
}
.labelsIcon {
  width: 50% !important;
  border: none !important;
  margin-right: 5px;
  height: 100%;
}
.labelsIcon_kkrice {
  width: 50% !important;
  border: none !important;
  margin-right: 5px;
  height: 100%;
}
.labelsText {
  font-size: 18px;
  font-family: Circular-Std-Book;
  align-self: center;
}
.labelsContainer_ryzone {
  display: flex;
  padding-right: 30px;
  border-right: 1px solid rgba(97, 97, 97, 0.5);
  margin-left: 5px;
}
.labelsContainer_kkrice {
  display: flex;
  padding-right: 60px;
  border-right: 1px solid rgba(97, 97, 97, 0.5);
  margin-left: 5px;
}
.viewWebsiteContainer {
  font-family: Circular-Std-Book !important;
  display: inline;
  font-size: 16px !important;
  color: #fff !important;
  font-weight: 800 !important;
  background-color: #0077ff !important;
  text-transform: initial !important;
  padding: 7px 14px !important;
}
.viewWebsite__arrow {
  font-size: 22px !important;
  margin: 3px 0px 0px 5px;
  color: #fff;
}
.caseStudiesSectionTwo_ryzone {
  background-color: #111111;
  opacity: 1;
  padding-left: 170px;
  padding-right: 170px;
}
.logoTextSectionTwo {
  display: flex;
  align-self: center;
  margin: 100px 0px 0px 0px;
}
.circleDirector__caseStudy {
  width: 70px;
  height: 70px;
  border: 30px solid #fff;
  border-radius: 50%;
  margin-top: -170px;
  margin-left: 10px;
}
.dottedLineContainer {
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  margin-left: 20px;
}
.dottedLine1 {
  color: #fff;
  font-size: 30px;
  line-height: 0.5;
}
.casestudy_icon_row {
  display: flex;
  flex-direction: row;
}
.caseStudieSectionTwoTitle {
  font-size: 35px;
  font-weight: 800;
  font-family: Circular-Std-Book;
  margin-bottom: 50px;
  color: rgb(97, 97, 97);
  margin-right: 10px;
  margin-left: -140px;
}
.caseStudiesSectionTwoDescript {
  font-size: 17px;
  font-family: Circular-Std-Book;
  line-height: 1.6;
  color: #fff;
  width: 80%;
  margin-top: -20px;
}
.caseStudiesRightImg {
  margin-left: -100px;
  margin-top: 0px;
  margin-bottom: 50px;
}
.caseStudiesSectionThree_ryzone {
  background-color: #fff;
  padding-left: 100px;
  padding-right: 100px;
}
.caseStudiesleftImg {
  margin-left: -100px;
  margin-top: 0px;
  margin-bottom: 50px;
}
.caseStudieSectionThreeTitle {
  font-size: 35px;
  font-weight: 800;
  font-family: Circular-Std-Book;
  margin-bottom: 50px;
  color: #333333;
}
.caseStudiesSectionThreeDescript {
  font-size: 17px;
  font-family: Circular-Std-Book;
  line-height: 1.6;
  color: #333333;
  width: 80%;
}
.caseStudiesSectionTestimonial_ryzone {
  background-color: #ffffff;
  opacity: 1;
  padding-left: 170px;
  padding-right: 170px;
  padding-top: 20px;
}
.caseStudiesTestimonial {
  font-size: 20px;
  font-family: Circular-Std-Book;
  margin: 100px auto;
  width: 60%;
  text-align: center;
}
.caseStudiesTestimonialText {
  font-size: 17px;
  font-family: Circular-Std-Book;
  line-height: 1.8;
  color: #333333;
  width: 97%;
  padding-top: 30px;
}
.OwnerText {
  font-size: 22px;
  color: rgb(97, 97, 97);
  font-family: Circular-Std-Book;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
}
.testimonialImg {
  margin-right: 20px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 -5px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: cadetblue;
  margin-top: 10px;
}
.companyName {
  font-size: 18px;
  font-family: Circular-Std-Book;
  font-weight: 500;
  color: rgb(97, 97, 97);
}
.testimonialsCard_ryzone {
  background-color: #ffffff !important;
  padding-left: 30px;
  width: 97%;
  margin-top: 70px;
  padding-bottom: 20px;
  box-shadow: none;
  border-radius: 20px !important;
  overflow: inherit !important;
}
.StarRating {
  color: gold !important;
  margin-top: 10px;
}
.ownerAlign {
  float: left;
}
.directorImgLeft {
  width: 100px;
  margin-top: -45px;
  transform: rotatey(180deg);
}
.directorImgRight {
  width: 100px;
  margin-bottom: -60px;
}

/* Mobile
---------------------------------------------------------- */

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .labelsContainer_ryzone {
    display: flex;
    padding-right: 40px;
    border-right: 1px solid rgba(97, 97, 97, 0.5);
    margin-left: 10px;
  }
  .labelsContainer_kkrice {
    display: flex;
    padding-right: 40px;
    border-right: 1px solid rgba(97, 97, 97, 0.5);
    margin-left: 10px;
  }
  .labelsIcon_kkrice {
    width: 30% !important;
    border: none !important;
    margin-right: 5px;
    height: 100%;
  }
  .dottedLineContainer {
    position: absolute;
    bottom: 0;
    margin-bottom: 40px;
    margin-left: 20px;
    display: none;
  }
  .caseStudiesSectionThreeDescript {
    font-size: 17px;
    font-family: Circular-Std-Book;
    line-height: 1.6;
    color: #333333;

    width: 80%;
    margin-left: 20px;
  }
  .caseStudiesSectionTestimonial_ryzone {
    background-color: #ffffff;
    opacity: 1;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
  }
  .testimonialsCard_ryzone {
    background-color: #ffffff !important;
    padding-left: 10px;
    width: 97%;
    margin-top: 70px;
    padding-bottom: 20px;
    box-shadow: none;
    border-radius: 20px !important;
    overflow: inherit !important;
  }
  .ryzoneHeaderImage {
    width: 100%;
    height: 200px;
    border: none;
    margin-top: 100px;
  }
  .praxisHeaderImage {
    width: 100%;
    height: 200px;
    border: none;
    margin-top: 100px;
  }
  .fpmarketsHeaderImage {
    width: 100%;
    height: 200px;
    border: none;
    margin-top: 100px;
  }
  .riceportalHeaderImage {
    width: 100%;
    height: 200px;
    border: none;
    margin-top: 100px;
  }
  .labelsSection {
    overflow: scroll;
  }
  .logoTextSectionTwo {
    display: flex;
    align-self: center;
    margin: 100px 0px 0px 20px;
  }
  .caseStudiesSectionTwoDescript {
    font-size: 17px;
    font-family: Circular-Std-Book;
    line-height: 1.8;
    color: rgb(97, 97, 97);
    margin-left: 20px;
    width: 80%;
  }
  .caseStudiesRightImg {
    margin: 50px 0px 0px 0px;
    width: 100%;
    height: 100%;
  }
  .caseStudiesleftImg {
    margin: 50px 0px 0px 0px;
    width: 100%;
    height: 100%;
  }
  .QuoteOne {
    margin-top: 50px;
  }

  .caseStudiesSectionTwo_ryzone {
    padding-bottom: 100px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .caseStudiesSectionThree_ryzone {
    padding-bottom: 100px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Tablet */

@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .ryzoneHeaderImage {
    width: 100%;
    height: 100%;
    border: none;
  }
  .riceportalHeaderImage {
    width: 90%;
    height: 100%;
    border: none;
  }
  .fpmarketsHeaderImage {
    width: 90%;
    height: 100%;
    border: none;
  }
  .praxisHeaderImage {
    width: 90%;
    height: 100%;
    border: none;
  }
  .caseStudiesSectionTwo_ryzone {
    background-color: #111111;
    opacity: 1;
    padding-left: 120px;
    padding-right: 70px;
    padding-bottom: 150px;
  }
  .caseStudiesSectionThree_ryzone {
    background-color: #fff;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 100px;
  }
  .caseStudiesSectionTwoDescript {
    font-size: 17px;
    font-family: Circular-Std-Book;
    line-height: 1.6;
    color: #fff;
    width: 90%;
    margin-top: -20px;
  }
  .caseStudiesleftImg {
    margin-left: -50px;
    margin-top: 100px;
    margin-bottom: 50px;
    width: 100%;
    height: 70%;
  }
  .caseStudiesRightImg {
    margin-left: 0px;
    margin-top: 100px;
    margin-bottom: 50px;
    width: 100%;
    height: 75%;
  }
  .dottedLineContainer {
    position: absolute;
    bottom: -150px;
    margin-bottom: 20px;
    margin-left: 20px;
  }
}
