.user_agreement_container {
  background-color: #f7f7f7;
  padding: 20px;
}

.user_agreement_inner_container {
  max-width: 1100px;
  margin: 0 auto;
}

.user_agreement_header {
  font-family: Circular-Std-Medium;
  font-weight: 600;
  letter-spacing: -1px;
  font-size: 35px;
  color: rgb(0, 0, 0);
  line-height: 1.1;
  margin-bottom: 30px;
}
.user_agreement_content_con {
  background-color: white;
  border: 1px solid #ced2d6;
  border-radius: 5px;
  margin-bottom: 20px;
  padding:20px;
}

.user_agreement_content_container {
  padding-bottom: 10px;
}
.user_agreement_content_header {
  font-family: Circular-Std-Medium;
  font-weight: 400;
  letter-spacing: -1px;
  font-size: 20px;
  color: rgb(0, 0, 0);
  line-height: 1.1;
  padding-left: 15px;
  margin: 0 !important;
  padding-bottom: 5px;
}

.user_agreement_content_para {
  color: #aeafb0;
  font-family: Circular-Std-Medium;
  font-size: 16px;
  line-height: 2;
  padding-left: 15px;
}
.user_agreement_content_inner_para {
  color: #aeafb0;
  font-family: Circular-Std-Medium;
  font-size: 16px;
  line-height: 2;
  padding-left: 15px;
  padding-bottom: 4px;
}
.user_agreement_inital_container{
    padding-bottom: 10px;
}

.user_agreement_list_style{
  list-style-type: circle;
  margin: 0 20px 0 20px;
}