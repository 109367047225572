.bannerLeftSection {
  padding: 140px 0px;
  transform: translate3d(0px, 100px, 0px);
  animation: 0.9s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 normal forwards running
    slideup;
}
.bannertitle {
  font-family: Circular-Std-Medium;
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  color: rgb(0, 0, 0);
  line-height: 1.1;
  margin-bottom: 30px;
  letter-spacing: -1px;
}
.bannersubtext {
  font-family: Circular-Std-Book;
  font-size: 30px;
  color: rgb(97, 97, 97);
  line-height: 1.4;
  margin-bottom: 20px;
  letter-spacing: -1px;
}

.bannerleftlink {
  font-family: "Circular-Std-Medium";
  font-weight: 700;
  letter-spacing: -1px;
  font-size: 35px;
  color: rgb(0, 119, 255);
}

.signupArrow {
  font-size: 26px !important;
  margin-bottom: -5px;
}

.header_temp_image {
  display: none;
  width: 2px;
  height: 2px;
}
.header_temp_image_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@keyframes slideup {
  0% {
    opacity: 0;
    transform: translate3d(0px, 100px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}
.videoContainer {
  position: absolute;
  top: 80px;
  z-index: -99999;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .header_temp_image {
    width: 1px;
    height: 1px;
    display: none;
  }
  .bannerLeftSection {
    padding: 20px 0px 70px 0px;
  }
  .bannertitle {
    font-family: Circular-Std-Medium;
    font-weight: 700;
    font-size: 45px;
    color: rgb(0, 0, 0);
    line-height: 1.1;
    margin-bottom: 30px;
  }
  .bannersubtext {
    font-family: Circular-Std-Book;
    font-size: 25px;
    color: rgb(97, 97, 97);
    line-height: 1.3;
    margin-bottom: 25px;
  }

  .bannerleftlink {
    font-family: "Circular-Std-Medium";
    font-weight: 700;
    letter-spacing: -1px;
    font-size: 25px;
    color: rgb(0, 119, 255);
  }

  .signupArrow {
    font-size: 20px !important;
    margin-bottom: -4px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .header_temp_image {
    width: 1px;
    height: 1px;
    display: none;
  }
}
