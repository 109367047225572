.ksXnbQ {
  position: relative;
  width: 100%;
  height: auto;
}
.kgegep {
  /* position: absolute; */
  top: 0px;
  bottom: 0px;
  left: 0px;
  /* display: flex; */
}
.processTabsSection {
  position: absolute;
  bottom: 200px;
  left: 100px;
}
.MTabsWidthProcess_mobileapp {
  width: max-content;
  box-shadow: none !important;
  background-color: transparent !important;
}

.processbg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.movingMobile {
  animation: moving 0.5s;
  height: 850px;
}
@keyframes moving {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.processContentSection {
  position: absolute;
  bottom: 300px;
  left: 100px;
}
.sprintText {
  font-size: 62px;
  font-family: Circular-Std-Bold;
  letter-spacing: -3.15px;
  line-height: 70px;
  white-space: pre-line;
  opacity: 0.9;
  color: #fff;
  width: 1000px;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .MTabsWidthProcess_mobileapp {
    width: 350px;
    box-shadow: none !important;
  }
  .MTabsContainer > .MuiTabs-fixed {
    overflow-x: scroll !important;
  }
  .processTabsSection {
    position: absolute;
    bottom: 45px;
    left: 20px;
  }
  .processContentSection {
    position: absolute;
    bottom: 78px;
    left: 0px;
  }
  .sprintText {
    font-size: 32px;
    font-family: Circular-Std-Bold;
    letter-spacing: -2px;
    line-height: 36px;
    white-space: pre-line;
    opacity: 0.9;
    color: #fff;
    width: auto;
    padding: 20px;
  }
  .movingMobile {
    animation: moving 0.5s;
    height: 500px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 380px) {
  .MTabsWidthProcess_mobileapp {
    width: 320px;
    box-shadow: none !important;
  }
}
