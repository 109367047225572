.home_blogSection {
  padding: 100px 0px 100px 0px;
  /* background-color: rgb(17, 17, 17); */
  /* margin-bottom: 100px; */
}
.home_blogHeaderContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 50px;
  margin-bottom: 20px;
  align-items: flex-end;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(97, 97, 97, 0.12);
}
.home_blogTitleRow {
  display: flex;
  justify-content: center;
}
.home_blogHeading {
  font-size: 48px;
  font-weight: 700;
  font-family: Circular-Std-Medium;
  color: rgb(97, 97, 97);
  letter-spacing: -1px;
}
.home_blogViewAll {
  font-size: 22px;
  font-family: Circular-Std-Medium;
  color: #0077ff;
  letter-spacing: -1px;
}
.blogSectionImg {
  width: 100%;
  height: auto;
  border-radius: 5px 5px 0px 0px;
}

.blogSectionHeading_home {
  font-size: 22px;
  font-family: Circular-Std-Medium;
  position: relative;
  margin: 15px;
  color: rgba(17, 17, 17);
}
.blogSectionHeading_home::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -7px;
  z-index: 100;
  width: 200px;
  height: 1px;
  background: rgb(97, 97, 97, 0.12);
}
.blogSectionSubText_home {
  font-size: 16px;
  font-family: Circular-Std-Book;
  color: rgb(97, 97, 97);
  line-height: 1.7;
  margin: 15px;
}
.blogSectionHeading {
  font-size: 26px;
  font-family: Circular-Std-Medium;
  position: relative;
  margin: 15px;
  color: #fff;
}
.blogSectionHeading::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -7px;
  z-index: 100;
  width: 200px;
  height: 1px;
  background: rgb(97, 97, 97, 0.12);
}
.blogSectionSubText {
  font-size: 16px;
  font-family: Circular-Std-Book;
  color: rgb(97, 97, 97);
  line-height: 1.7;
  margin: 15px;
}

.blogSectionLink {
  font-size: 17px;
  font-family: Circular-Std-Medium;
  color: rgb(0, 119, 255);
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.arrowRight {
  font-size: 20px !important;
  margin-bottom: -5px;
  margin-left: 10px;
}
.home_blogSectionCardContainer {
  border: 1px solid rgba(97, 97, 97, 0.12);
  border-radius: 8px;
  min-width: 350px;
  margin: 5px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 3px 0px, rgb(0 0 0 / 5%) 0px 2px 6px 0px;
}

.rightCaroselIcon {
  color: rgb(97, 97, 97);
  border-radius: 50%;
  border: 2px solid rgb(97, 97, 97);
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 3px 0px, rgb(0 0 0 / 5%) 0px 2px 6px 0px;
}
.leftCaroselIcon {
  color: rgb(97, 97, 97);
  border-radius: 50%;
  border: 2px solid rgb(97, 97, 97);
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 3px 0px, rgb(0 0 0 / 5%) 0px 2px 6px 0px;
}
.blogMiddleSection {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  scroll-behavior: smooth;
}
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 0px violet;
}

.home_blogSectionContainer {
  justify-content: center;
  display: flex;
  margin: 20px;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .home_blogHeading {
    font-size: 42px;
    font-weight: 700;
    font-family: Circular-Std-Medium;
    color: rgb(97, 97, 97);
    letter-spacing: -1px;
  }
  .blogMiddleSection {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    width: 100%;
    scroll-behavior: smooth;
  }
  .leftCaroselIcon {
    color: rgba(97, 97, 97, 0.12);
    border-radius: 50%;
    border: 1px solid rgba(97, 97, 97, 0.12);
    padding: 3px;
    cursor: pointer;
    margin-right: 5px;
  }
  .rightCaroselIcon {
    color: rgba(97, 97, 97, 0.12);
    border-radius: 50%;
    border: 1px solid rgba(97, 97, 97, 0.12);
    padding: 3px;
    cursor: pointer;
    margin-left: 5px;
  }
  .home_blogSectionCardContainer {
    border: 1px solid rgba(97, 97, 97, 0.12);
    border-radius: 8px;
    min-width: 240px;
    margin: 5px;
  }
  .blogSectionImg {
    width: 100%;
    height: 175px;
    border-radius: 5px 5px 0px 0px;
  }
}
