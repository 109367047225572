.carouselWrapper {
  position: relative;
  overflow: hidden;
  margin: auto;
  box-sizing: border-box;
}

.imgWrap {
  position: "absolute";
  top: 0;
  left: 0;
}
.imgWrap:hover {
  cursor: pointer;
}

li {
  list-style-type: none;
}
.dn {
  display: none;
}

.servicev2_headingContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  position: relative;
}
.playVideoContainer_Home {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
}
.blob {
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: 40px;
  width: 40px;
  transform: scale(-1);
  animation: pulse-black 2s infinite;
}

.playVideoStrIcon {
  font-size: 51px !important;
  position: absolute;
  left: -5px;
}

.playVideoDesIcon {
  font-size: 51px !important;
  position: absolute;
  left: -5px;
}

.playVideoDevIcon {
  font-size: 51px !important;
  position: absolute;
  left: -5px;
}
.playVideoMarIcon {
  font-size: 51px !important;
  position: absolute;
  left: -5px;
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.playVideoTextHome {
  color: #fff;
  font-size: 18px;
  font-family: Circular-Std-Book;
  margin-left: 10px;
}

.servicev2Home_heading {
  font-size: 48px;
  font-family: Circular-Std-Medium;
  font-weight: 700;
  letter-spacing: -1px;
  color: rgb(97, 97, 97);
  margin-bottom: 20px;
  animation: servicev2heading 0.7s;
  margin-right: 20px;
}
.servicev2Home_subtext {
  color: #fff;
  font-size: 18px;
  font-family: Circular-Std-Book;
  margin-top: 5px;
  animation: servicev2subtext 0.9s;
}
@keyframes servicev2heading {
  0% {
    opacity: 0;
    transform: translate3d(0px, -100px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes servicev2subtext {
  0% {
    opacity: 0;
    transform: translate3d(0px, -70px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}

.dotsContainer_Home {
  position: absolute;
  right: 140px;
  display: none;
}

.FirstDot_Home {
  width: 8px;
  border-radius: 50%;
  height: 8px;
  background-color: #fff;
}

.SecondDot_Home {
  width: 8px;
  border-radius: 50%;
  height: 8px;
  background-color: #fff;
}
.ThirdDot_Home {
  width: 8px;
  border-radius: 50%;
  height: 8px;
  background-color: #fff;
}
.FourthDot_Home {
  width: 8px;
  border-radius: 50%;
  height: 8px;
  background-color: #fff;
}

.carouselWrapper {
  margin-left: -50px;
}

.cursorContaincerHome {
  width: fit-content;
  height: auto;
  background-color: rgba(17, 17, 17, 0.8);
  border-radius: 7px;
  padding: 8px 10px;
  text-align: center;
  position: absolute;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  z-index: 10000001;
  pointer-events: none;
  display: flex;
  justify-content: center;
  left: -1000px;
}
.cursorContaincerHome > .cursorTextHome {
  color: #fff;
  font-size: 14px;
  font-family: Circular-Std-Medium;
  font-weight: 300;
  word-wrap: break-word;
  align-self: center;
}

.SlideContainer {
  background-color: black;
  /* margin: 10%; */
  height: 100%;
}
.youtubeCloseButtonSlide {
  font-weight: bold !important;
  cursor: pointer;
  font-size: 40px !important;
  color: rgb(255, 255, 255) !important;
  position: absolute;
  top: -50px;
  right: 0px;
}
.youtubeVideo_carosel {
  width: 100%;
  height: 100%;
}

.categoryHomeTitle_section {
  /* display: flex; */
  /* justify-content: space-between; */
  margin-bottom: 50px;
  /* align-items: flex-end; */
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(97, 97, 97);
}

.categroyHomeTitle_text {
  font-size: 48px;
  font-weight: 700;
  font-family: Circular-Std-Medium;
  color: #fff;
  letter-spacing: -1px;
}
.categroyHomeTitle_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.categroyHomeMenu_row {
  display: flex;
}
.categoryHomeMenu_inActive {
  font-size: 18px;
  font-family: Circular-Std-Medium;
  color: rgba(97, 97, 97);
  padding: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.categoryHomeMenu_active {
  font-size: 18px;
  font-family: Circular-Std-Medium;
  color: rgb(0, 119, 255);
  padding: 5px 10px;
  border: 2px solid rgb(0, 119, 255);
  margin-right: 10px;
  border-radius: 50px;
  box-shadow: inset 0 0 5px #0077ff;
  cursor: pointer;
}
.serviceNameTextMobile {
  background-color: #fff;
  color: #000;
  font-size: 15px;
  font-family: Circular-Std-Book;
  top: 120px;
  padding: 5px 10px;
  border-radius: 5px;
  left: 120px;
  display: none;
}
.dotFirstParentDiv_Home {
  border-radius: 50%;
  cursor: pointer;
}
.dotsParentDiv_Home {
  border-radius: 50%;
  cursor: pointer;
  margin-top: 20px;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .categoryHomeTitle_section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
    align-items: flex-end;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(97, 97, 97);
  }
  .categroyHomeTitle_row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .categroyHomeTitle_text {
    font-size: 42px;
    font-weight: 700;
    font-family: Circular-Std-Medium;
    color: #fff;
    letter-spacing: -1px;
    margin-bottom: 10px;
  }
  .categoryHomeMenu_inActive {
    font-size: 15px;
    font-family: Circular-Std-Medium;
    color: rgba(97, 97, 97);
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .categoryHomeMenu_active {
    font-size: 15px;
    font-family: Circular-Std-Medium;
    color: rgb(0, 119, 255);
    padding: 5px;
    border: 2px solid rgb(0, 119, 255);
    margin-right: 10px;
    border-radius: 30px;
    box-shadow: inset 0 0 5px #0077ff;
    cursor: pointer;
  }
  .servicev2Home_heading {
    font-size: 42px;
    font-family: Circular-Std-Medium;
    font-weight: 700;
    letter-spacing: -1px;
    color: rgb(97, 97, 97);
    margin-bottom: 5px;
    margin-top: 10px;
    text-align: center;
    animation: servicev2heading 0.7s;
    margin-right: 0px;
  }
  .carouselWrapper {
    margin-left: 0px;
  }
  .playVideoStrIcon {
    font-size: 51px !important;
    position: absolute;
    left: 99px;
  }
  .playVideoDesIcon {
    font-size: 51px !important;
    position: absolute;
    left: 99px;
  }

  .playVideoDevIcon {
    font-size: 51px !important;
    position: absolute;
    left: 99px;
  }
  .playVideoMarIcon {
    font-size: 51px !important;
    position: absolute;
    left: 99px;
  }

  .dotsContainer_Home {
    position: absolute;
    right: 140px;
    transform: rotate(270deg);
    margin-right: 35px;
    top: 170px;
    display: none;
  }
  .servicev2Home_subtext {
    color: #fff;
    font-size: 18px;
    font-family: Circular-Std-Book;
    margin-top: 5px;
    text-align: center;
    animation: servicev2subtext 0.9s;
    margin-bottom: 50px;
  }

  .servicev2_headingContainer {
    display: block;
    align-items: center;
    margin-bottom: 5px;
    position: relative;
  }
  .playVideoContainer_Home {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .youtubeCloseButtonSlide {
    font-weight: bold !important;
    cursor: pointer;
    font-size: 40px !important;
    color: rgb(255, 255, 255) !important;
    position: absolute;
    top: 50px;
    right: 0px;
  }
  .youtubeVideo_carosel {
    width: 100%;
    height: 50%;
  }
  .serviceNameTextMobile {
    background-color: #fff;
    color: #000;
    font-size: 15px;
    font-family: Circular-Std-Book;
    top: 150px;
    padding: 5px 10px;
    border-radius: 5px;
    left: 75px;
    width: 200px;
    display: block;
    text-align: center;
  }
  .dotFirstParentDiv_Home {
    border-radius: 50%;
    cursor: pointer;
  }
  .dotsParentDiv_Home {
    border-radius: 50%;
    cursor: pointer;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 380px) and (max-width: 768px) {
  .categoryHomeTitle_section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
    align-items: flex-end;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(97, 97, 97);
  }
  .categroyHomeTitle_row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .categroyHomeTitle_text {
    font-size: 42px;
    font-weight: 700;
    font-family: Circular-Std-Medium;
    color: #fff;
    letter-spacing: -1px;
    margin-bottom: 10px;
  }
  .categoryHomeMenu_active {
    font-size: 14px;
    font-family: Circular-Std-Medium;
    color: rgb(0, 119, 255);
    padding: 5px;
    border: 2px solid rgb(0, 119, 255);
    margin-right: 10px;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: inset 0 0 5px #0077ff;
  }
  .playVideoStrIcon {
    font-size: 51px !important;
    position: absolute;
    left: 117px;
  }
  .playVideoDesIcon {
    font-size: 51px !important;
    position: absolute;
    left: 117px;
  }

  .playVideoDevIcon {
    font-size: 51px !important;
    position: absolute;
    left: 117px;
  }
  .playVideoMarIcon {
    font-size: 51px !important;
    position: absolute;
    left: 117px;
  }
  .dotsContainer_Home {
    position: absolute;
    right: 160px;
    transform: rotate(270deg);
    margin-right: 35px;
    top: 170px;
    display: none;
  }
  .serviceNameTextMobile {
    background-color: #fff;
    color: #000;
    font-size: 15px;
    font-family: Circular-Std-Book;
    top: 150px;
    padding: 5px 10px;
    border-radius: 5px;
    left: 85px;
    width: 200px;
    display: block;
    text-align: center;
  }
  .dotFirstParentDiv_Home {
    border-radius: 50%;
    cursor: pointer;
  }
  .dotsParentDiv_Home {
    border-radius: 50%;
    cursor: pointer;
    margin-top: 20px;
  }
  .carouselWrapper {
    margin-left: 20px;
  }
}
