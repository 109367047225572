.partnersCardContainer {
  /* background-color: #171a1b; */
  /* margin: 30px; */
  border-radius: 5px;
  text-align: center;
  padding: 20px;
  height: auto;
  /* border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 3px 0px, rgb(0 0 0 / 5%) 0px 2px 6px 0px; */
}
.partnersCardContainer:hover {
  /* background-image: url("https://colabrio.ams3.cdn.digitaloceanspaces.com/ohio-demo-2/oh__img142-768x901.jpg"); */
  /* background-color: #171a1b; */
  background-size: cover;
  transform: scale3d(1.1, 1.1, 1);
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
}
.partnerSection {
  margin: 60px 0px 75px 0px;
}
.partnersImgContainer{
    width: 75px;
    height: auto;
    margin: 0 auto;
}

.deploymentPartnerHeading {
  font-size: 48px;
  font-family: "Circular-Std-Medium";
  font-weight: 700;
  letter-spacing: -1px;
  color: #222d38;
  text-align: center;
  margin-bottom: 10px;
}
.deploymentPartnerSubHeading {
  color: #617080;
  font-size: 18px;
  font-family: Circular-Std-Book;
  text-align: center;
  margin-top: 5px;
  width: 42%;
  margin: 10px auto;
}
.deploymentPartnerCardText {
  color: #fff;
  font-size: 18px;
  font-family: Circular-Std-Book;
  font-weight: 300;
  text-align: center;
  margin-top: 30px;
  line-height: 1.5;
  margin: 20px 0px;
}
.deploymentPartnerCardHeading {
  color: #0077ff;
  font-size: 22px;
  font-family: Circular-Std-Bold;
  text-align: center;
  margin-top: 15px;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .deploymentPartnerHeading {
    font-size: 38px;
    font-family: "Circular-Std-Medium";
    font-weight: 700;
    letter-spacing: -1px;
    padding: 0px 10px;
  }
  .deploymentPartnerSubHeading {
    color: #617080;
    font-size: 22px;
    font-family: Circular-Std-Book;
    text-align: center;
    margin-top: 5px;
    width: 75% !important;
    margin: 10px auto;
  }
}
