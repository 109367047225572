.visionMissionContainer {
  padding: 100px 0px 50px 0px;
}
.visionMissionImg {
  width: 70%;
}

.vissionMissionHeading {
  font-size: 48px;
  font-family: Circular-Std-Medium;
  font-weight: 700;
  letter-spacing: -1px;
  margin-bottom: 20px;
}
.vissionMissionPara {
  font-family: Circular-Std-Book;
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 1.5;
  color: rgb(97, 97, 97);
  width: 90%;
}

.visionContainer {
  background-color: rgb(17, 17, 17);
  padding: 20px 30px;
  border-radius: 7px;
  width: 70%;
}
.visionTitleContainer {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.visionTitle {
  font-size: 20px;
  font-family: Circular-Std-Book;
  color: #fff;
}
.DownArrowIcon {
  color: #fff !important;
  font-size: 30px !important;
}
.visionText {
  color: #fff;
  font-size: 16px;
  font-family: Circular-Std-Book;
  color: #fff;
  padding-top: 20px;
  line-height: 1.6;
}
.missionContainer {
  background-color: rgb(17, 17, 17);
  padding: 20px 30px;
  border-radius: 7px;
  width: 70%;
  margin-top: 30px;
}
.missionText {
  color: #fff;
  font-size: 16px;
  font-family: Circular-Std-Book;
  color: #fff;
  padding-top: 20px;
  line-height: 1.6;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .visionMissionImg {
    margin-left: 0px;
    width: 75%;
    height: 100%;
  }
  .vissionMissionHeading {
    font-size: 42px;
    font-family: Circular-Std-Medium;
    font-weight: 700;
    letter-spacing: -1px;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 50px;
  }
  .vissionMissionPara {
    font-family: Circular-Std-Book;
    font-size: 18px;
    line-height: 1.5;
    color: rgb(97, 97, 97);
    width: 90%;
    margin: 30px auto;
    text-align: center;
  }
  .visionContainer {
    background-color: rgb(17, 17, 17);
    border-radius: 7px;
    width: 90%;
    padding: 0px;
    margin: 30px auto;
  }
  .visionTitleContainer {
    display: flex;
    padding: 20px 30px;
    justify-content: space-between;
    cursor: pointer;
  }
  .missionContainer {
    background-color: rgb(17, 17, 17);
    padding: 0px;
    border-radius: 7px;
    width: 90%;
    margin: 30px auto;
  }
  .missionText {
    color: #fff;
    font-size: 16px;
    font-family: Circular-Std-Book;
    color: #fff;
    line-height: 1.6;
    padding: 30px;
    padding-top: 0px;
  }
  .visionText {
    color: #fff;
    font-size: 16px;
    font-family: Circular-Std-Book;
    color: #fff;
    padding: 30px;
    padding-top: 0px;
    line-height: 1.6;
  }
}
