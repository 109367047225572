.aboutHome_videoSectionContainer {
  position: relative;
}

.aboutHome_customTextField {
  font-family: Circular-Std-Medium;
  font-size: 80px;
  border: none;
  font-weight: 400;
  outline-width: 0px;
  -webkit-text-stroke: 2px rgb(17, 17, 17);
  color: transparent;
  position: absolute;
  z-index: 1;
}
.aboutHome_customTextFieldHover {
  font-family: Circular-Std-Medium;
  font-size: 80px;
  border: none;
  font-weight: 400;
  outline-width: 0px;
  -webkit-text-stroke: 2px rgb(17, 17, 17);
  -webkit-text-fill-color: rgb(17, 17, 17);
  position: absolute;
  z-index: 1;
  opacity: 0.7;
}

.aboutHome_hoverVideo {
  width: 300px;
  height: 300px;
  border-radius: 300px;
  position: relative;
  margin-left: -50px;
  margin-top: -100px;
  z-index: 0;
}
.aboutHome_hoverVideoShow {
  width: 300px;
  height: 300px;
  border-radius: 300px;
  position: relative;
  margin-left: -50px;
  margin-top: -100px;
  z-index: 0;
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.scale-out-center {
  -webkit-animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .aboutHome_customTextField {
    font-family: Circular-Std-Medium;
    font-size: 60px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 2px rgb(17, 17, 17);
    color: transparent;
    position: absolute;
    z-index: 1;
  }
  .aboutHome_customTextFieldHover {
    font-family: Circular-Std-Medium;
    font-size: 60px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 2px rgb(17, 17, 17);
    -webkit-text-fill-color: rgb(17, 17, 17);
    position: absolute;
    z-index: 2;
    opacity: 0.7;
  }
  .aboutHome_hoverVideo {
    width: 250px;
    height: 250px;
    border-radius: 250px;
    position: relative;
    margin-left: -25px;
    margin-top: -90px;
  }
  .aboutHome_hoverVideoShow {
    width: 250px;
    height: 250px;
    border-radius: 250px;
    position: relative;
    margin-left: -25px;
    margin-top: -90px;
  }
}
