.handshake_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thank_msg_one {
  font-family: "Circular-Std-Black";
  font-size: 22px;
  font-weight: bold;
  color: #707070 !important;
}

.thank_msg {
  font-family: "Circular-Std-Black";
  font-size: 20px;
  font-weight: bold;
  color: #707070 !important;
}

.thank_msg_container {
  padding: 20px 0px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.back_home {
  margin: 20px !important;
  margin-left: 20px !important;
  background: #4c9bfb !important;
  max-width: 335px !important;
  min-width: 335px !important;
  color: white !important;
  border-radius: 15px !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 18px !important;
}
.back_home .MuiButton-label {
  font-family: "Circular-Std-Black" !important;
}

.handshake_image_thanku {
  height: 25% !important;
  width: 25% !important;
}

@media screen and (max-width: 600px) {
  .handshake_container {
    margin-top: 116px;
  }
}
