.contactus_banner_section {
  padding-top: 100px;
  margin: 0px 170px;
  padding-bottom: 100px;
  text-align: center;
}
.contactusHeading {
  color: #000;
  font-family: Circular-Std-Medium;
  font-weight: 700;
  letter-spacing: -1px;
  text-align: center;
  font-size: 60px;
  margin: 20px 0px;
}
.contactusSubHeading {
  color: rgb(34, 34, 34);
  font-family: Circular-Std-Book;
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
}
.help_center_btn {
  background-color: #0077ff !important;
  color: #fff !important;
  padding: 8px 20px !important;
  text-transform: initial !important;
  font-family: Circular-Std-Book !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.contactUsCards {
  padding: 30px;
  background-color: rgb(251, 251, 251);
}
.contactCardIcon {
  height: 60px;
  margin-bottom: 20px;
}
.contactCardTitle {
  font-family: Circular-Std-Bold;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.contactCardSubTitle {
  font-family: Circular-Std-Book;
  color: #000;
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 1.5;
}
.contactCardAddress {
  font-family: Circular-Std-Book;
  color: #000;
  font-size: 12px;
  margin-bottom: 10px;
  line-height: 1.5;
}
.contactCardLink {
  font-family: Circular-Std-Bold;
  color: #0077ff;
  font-size: 18px;
}
.cardArrow {
  font-size: 20px !important;
  margin-bottom: -4px;
}
@media only screen and (min-width: 321px) and (max-width: 768px) {
  .contactus_banner_section {
    padding-top: 100px;
    margin: 0px;
    padding-bottom: 100px;
    text-align: center;
  }
  .contactCardAddress {
    font-family: Circular-Std-Book;
    color: #000;
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1.5;
  }
  .contactUsCards {
    padding: 30px;
    background-color: rgb(251, 251, 251);
  }
}

@media only screen and (min-width:769px) and (max-width:1025px) {
  .contactUsCards {
    padding: 15px;
    background-color: rgb(251, 251, 251);
  }
  .contactCardSubTitle {
    font-family: Circular-Std-Book;
    color: #000;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.5;
  }
  .contactCardLink {
    font-family: Circular-Std-Bold;
    color: #0077ff;
    font-size: 14px;
  }
  .contactCardAddress {
    font-family: Circular-Std-Book;
    color: #000;
    font-size: 11px;
    margin-bottom: 10px;
    line-height: 1.5;
  }
}