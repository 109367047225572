.footerSectionDesk {
  background-color: rgb(0, 0, 0);
  padding: 0px 80px;
  display: block;
}
.footerSectionMobile {
  display: none;
}
.footerinnerone {
  display: flex;
  padding: 40px 40px;
  border-bottom: 1px solid rgb(26, 26, 26);
}

.MuiList-root {
  margin-right: 45px !important;
}

.MuiListItem-root {
  background-color: transparent;
  color: rgb(102, 102, 102);
  font-size: 16px;
  font-family: Circular-Std-Book;
  padding: 0px;
  padding-bottom: 5px;
}
.MuiListItem-root:first-child {
  color: rgb(255, 255, 255);
  font-family: Circular-Std-Bold;
  margin-bottom: 15px;
  font-size: 17px;
}
.langHeading {
  color: rgb(255, 255, 255);
  font-family: Circular-Std-Bold;
  margin-bottom: 15px;
  font-size: 17px;
  margin-top: 15px;
}
.footerinnertwo {
  padding: 40px 40px;
}
.logowhiteText {
  font-family: Circular-Std-Book;
  color: #fff;
  text-align: center;
  font-size: 30px;
  margin-bottom: 0px;
  line-height: 1.1;
}
.logowhiteText > sup {
  font-size: 15px;
}
.privacyContainer {
  display: flex;
  font-size: 14px;
  font-family: Circular-Std-Book;
  color: rgb(102, 102, 102);
  justify-content: center;
  margin-top: 30px;
}
.privacylist1 {
  margin-left: 15px;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: Circular-Std-Book;
  font-weight: normal;
}
.privacylist1::before {
  content: "•";
  padding-right: 15px;
}
.privacylist2 {
  margin-left: 15px;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: Circular-Std-Book;
  font-weight: normal;
}
.privacylist2::before {
  content: "•";
  padding-right: 15px;
}
.privacylist0 {
  margin-bottom: 5px;
  font-size: 14px;
  font-family: Circular-Std-Book;
  font-weight: normal;
}
.copyrighttext {
  font-size: 14px;
  font-family: Circular-Std-Book;
  color: rgb(102, 102, 102);
  text-align: center;
  margin-bottom: 5px;
  font-weight: normal;
}
.domaincer__logoFooter {
  width: 358px;
  height: 100px;
}

.subMenuFotterLink {
  color: rgb(102, 102, 102);
  font-size: 16px;
  font-weight: normal;
}

.footer_container_link{
 color: rgb(102, 102, 102) !important;

}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .footerSectionDesk {
    background-color: rgb(0, 0, 0);
    padding: 0px 0px;
    display: none;
  }
  .footerSectionMobile {
    background-color: rgb(0, 0, 0);
    display: block;
  }
  .privacyContainer {
    display: block;
    font-size: 14px;
    font-family: Circular-Std-Book;
    color: rgb(102, 102, 102);
    justify-content: center;
    margin-top: 20px;
  }
  .footerMenuContainer {
    display: flex;
    justify-content: space-between;
  }
  .footerMenuHeading {
    font-family: Circular-Std-Bold;
    font-size: 20px;
    color: #fff;
    margin-bottom: 0px;
  }
  .MuiListItem-root:first-child {
    margin-bottom: 0px;
    color: rgb(102, 102, 102);
    margin-top: 20px;
    font-size: 16px;
    font-family: Circular-Std-Book;
  }
}

@media only screen and (max-width: 320px) {
  .footerSectionDesk {
    background-color: rgb(0, 0, 0);
    padding: 0px 0px;
  }
}
