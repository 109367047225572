/* Banner section */
.branding_BSSliderImgContainer {
  text-align: center;
  align-self: flex-end;
  margin-bottom: -50px;
}
.branding_SliderImg {
  margin-top: 20px;
  width: 450px;
}
.branding_BSSliderRightSection {
  margin: 50px 0px 0px 0px;
}
.branding_serviceTabsSection {
  padding: 30px 0px 60px 0px;
}
.branding_sliderHeading {
  font-family: 'Circular-Std-Medium';
  font-size: 55px;
  font-weight: 700;
  letter-spacing: -1px;
  margin-bottom: 30px;
}
.branding_sliderSubHeading {
  font-family: Circular-Std-Book;
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 1.5;
  color: rgb(97, 97, 97);
}
.branding_MTabsWidth {
  width: fit-content;
  box-shadow: none !important;
}

.branding_MTabsContainer {
  background-color: rgb(17, 17, 17);
  border-radius: 5px;
  padding: 5px 1px;
  min-height: inherit !important;
  overflow: scroll !important;
}
.branding_MTabBtn {
  text-transform: initial !important;
  font-size: 16px !important;
  font-family: Circular-Std-Book !important;
  color: #fff !important;
  padding: 2px 15px !important;
  margin: 0px 5px !important;
  min-width: fit-content !important;
  min-height: inherit !important;
  z-index: 999;
}
.PrivateTabIndicator-colorPrimary-2 {
  background-color: #0077ff !important;
}
.PrivateTabIndicator-root-1 {
  height: 30px !important;
  border-radius: 5px;
}
.branding_MTabBtn > .MuiTab-wrapper {
  z-index: 999;
}

/* how service will help you  */
.branding_BSHelpYouRightSection {
  background-color: rgb(17, 17, 17);
  padding: 150px;
}
.branding_BSHelpYouHeading {
  font-size: 55px;
  font-family: 'Circular-Std-Medium';
  font-weight: 700;
  letter-spacing: -1px;
  color: rgb(97, 97, 97);
  max-width: 800px;
  margin: 0 auto;
}
.branding_BSHelpYouImg {
  margin-right: -230px;
  border-radius: 8px;
}
.branding_BSHelpYouPara {
  font-family: Circular-Std-Book;
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 1.5;
  color: #fff;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
}
.branding_dashLine {
  background-image: linear-gradient(to right, #fff 33%, rgba(0, 0, 0, 0) 0%);
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}
.branding_circleBSHelpYou {
  width: 70px;
  height: 70px;
  border: 30px solid #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  margin-bottom: -60px;
}

.branding_particalsSection {
  position: absolute;
  width: 100%;
  height: 570px;
}

/* service content section */

.branding_service_Heading {
  font-size: 22px;
  font-family: Circular-Std-Book;
  color: #46586b;
  text-align: center;
  margin-bottom: 15px;
}
.branding_service_subheading {
  font-size: 55px;
  font-family: 'Circular-Std-Medium';
  font-weight: 700;
  letter-spacing: -1px;
  color: #222d38;
  text-align: center;
}
.branding_service_subtext {
  color: #617080;
  font-size: 22px;
  font-family: Circular-Std-Book;
  text-align: center;
  margin-top: 5px;
  width: 42%;
  margin: 10px auto;
}
.branding_subServiceTabSection {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  scroll-behavior: smooth;
}
.branding_subServiceContaincer {
  display: flex;
  padding: 15px 50px;
  cursor: pointer;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
.branding_subServiceImg {
  width: 45px;
  height: 45px;
  margin-right: 15px;
}
.branding_subServiceTitle {
  font-family: Circular-Std-Book;
  color: #222d38;
  font-size: 15px;
  line-height: 22px;
}
.branding_subServiceSubTitle {
  font-family: Circular-Std-Book;
  color: #617080;
  font-size: 15px;
  line-height: 22px;
}
.branding_subServiceParaContainer {
  background-color: rgba(247, 249, 250, 0.6);
  cursor: pointer;
  margin: 0 auto;
  padding-bottom: 50px;
}
.branding_subServiceFixedSection {
  width: 925px;
  position: relative;
}
.branding_subServiceParaContainer:hover {
  margin: 0 auto;
  background-color: rgba(1, 119, 255, 0.06);
  opacity: 1;
}
.branding_serviceContent {
  padding: 50px 100px;
  display: flex;
}
.branding_subServicePara {
  margin-bottom: 8px;
  color: #222d38;
  font-size: 18px;
  font-family: Circular-Std-Book;
  padding: 0px 130px;
  text-align: center;
}
.branding_subServiceLearnMore {
  color: #fff !important;
  font-size: 18px !important;
  font-family: Circular-Std-Book !important;
  text-align: center !important;
  background-color: #0077ff !important;
  padding: 5px 20px !important;

  text-transform: inherit !important;
}
.branding_glowEffectBrand {
  position: absolute;
  left: 5%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}
.branding_glowEffectDigital {
  position: absolute;
  left: 30%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}
.branding_glowEffectWorkFlow {
  position: absolute;
  left: 55%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}
.branding_youtubeVideo {
  width: 80%;
  height: 370px;
  margin-bottom: 50px;
}
.branding_subSericeVideo {
  text-align: center;
  padding: 0 100px;
}

/* blog section */
.branding_BlogSection {
  padding: 100px 0px 100px 0px;
  background-color: rgb(17, 17, 17);
}
.branding_blogSectionImg {
  width: 100%;
  height: 230px;
  border-radius: 5px 5px 0px 0px;
}
.branding_blogSectionHeading {
  font-size: 28px;
  font-weight: 700;
  font-family: Circular-Std-Medium;
  margin-bottom: 10px;
  margin-top: 20px;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  color: #fff;
}
.branding_blogSectionHeading::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -7px;
  z-index: 100;
  width: 200px;
  height: 1px;
  background: rgb(97, 97, 97);
}
.branding_blogSectionSubText {
  font-size: 16px;
  font-family: Circular-Std-Book;
  color: #fff;
  line-height: 1.7;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
}

.branding_blogSectionLink {
  font-size: 17px;
  font-family: Circular-Std-Medium;
  color: rgb(0, 119, 255);
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.branding_arrowRight {
  font-size: 20px !important;
  margin-bottom: -5px;
  margin-left: 10px;
}
.branding_blogSectionCardContainer {
  border: 1px solid rgb(97, 97, 97);
  border-radius: 8px;
  min-width: 350px;
  margin: 20px 15px;
}

.branding_rightCaroselIcon {
  color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
}
.branding_leftCaroselIcon {
  color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.branding_blogMiddleSection {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  width: 1180px;
  scroll-behavior: smooth;
}
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 0px violet;
}

.branding_blogSectionContainer {
  justify-content: center;
  display: flex;
}

/* why choose us */
.branding_whyChooseUsSection {
  padding: 100px 0px;
}
.branding_whyChooseUsTitle {
  font-size: 55px;
  font-family: 'Circular-Std-Medium';
  font-weight: 700;
  letter-spacing: -1px;
  text-align: center;
  margin-bottom: 50px;
}
.branding_chooseTitle {
  font-size: 22px;
  font-family: Circular-Std-Book;
  font-weight: 600;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.8);
}
.branding_choosePara {
  font-size: 18px;
  font-family: Circular-Std-Book;
  font-weight: 500;
  line-height: 1.5;
  color: rgb(97, 97, 97);
}
.branding_about__chooseUsImageRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.branding_about_chooseUsMiddleImg {
  width: 70%;
  margin-bottom: 15%;
}
.branding_about_chooseUsMiddleImg2 {
  width: 18%;
  position: absolute;
  margin-top: 100px;
}
.branding_whyChooseColumnUpContainer {
  margin-bottom: 50px;
  text-align: end;
  margin-top: 50px;
}
.branding_whyChooseColumnDownContainer {
  text-align: end;
}

.branding_whyChooseColumn3UpContainer {
  margin-bottom: 50px;
  margin-top: 50px;
  text-align: left;
}
.branding_whyChooseColumn3DownContainer {
  text-align: left;
}

.branding_floatingUpDown {
  animation-name: floatingUpDown;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floatingUpDown {
  from {
    transform: translate(0px, 0px);
  }
  65% {
    transform: translate(0px, 10px);
  }
  to {
    transform: translate(0px, 0px);
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  /* banner section */
  .branding_SliderImg {
    margin-top: 70px;
    width: 80%;
    margin-bottom: 70px;
  }
  .branding_sliderHeading {
    font-size: 42px;
    font-family: 'Circular-Std-Medium';
    font-weight: 700;
    letter-spacing: -1px;
    margin-bottom: 30px;
  }
  .branding_BSSliderRightSection {
    margin: 30px 0px 30px 0px;
  }
  .branding_MTabsWidth {
    width: inherit;
    box-shadow: none !important;
  }
  .branding_MTabsContainer > .MuiTabs-fixed {
    overflow-x: scroll !important;
  }

  /* how service will help you */
  .branding_BSHelpYouRightSection {
    background-color: rgb(17, 17, 17);
    padding: 100px 15px;
  }
  .branding_BSHelpYouHeading {
    font-size: 42px;
    font-family: 'Circular-Std-Medium';
    font-weight: 700;
    letter-spacing: -1px;
    color: rgb(97, 97, 97);
    max-width: 800px;
    margin: 0 auto;
  }
  .branding_BSHelpYouPara {
    font-family: Circular-Std-Book;
    font-size: 18px;
    line-height: 1.5;
    color: #fff;
    max-width: 800px;
    margin: 30px auto;
  }

  /* service content section */
  .branding_subServiceImg {
    width: 45px;
    height: 45px;
    margin-right: 15px;
  }
  .branding_subServiceFixedSection {
    width: 925px;
    position: relative;
    height: 250px;
  }
  .branding_service_subheading {
    font-size: 42px;
    font-family: 'Circular-Std-Medium';
    font-weight: 700;
    letter-spacing: -1px;
    padding: 0px 10px;
  }
  .branding_service_subtext {
    color: #617080;
    font-size: 22px;
    font-family: Circular-Std-Book;
    text-align: center;
    margin-top: 5px;
    width: 75% !important;
    margin: 10px auto;
  }
  .branding_serviceContent {
    padding: 20px 10px !important;
    display: flex !important;
  }
  .branding_serviceContentTwo {
    padding: 20px 10px 45px 10px;
    display: flex;
  }
  .branding_serviceContentThree {
    padding: 20px 10px 45px 10px;
    display: flex;
  }
  .branding_subServicePara {
    margin-bottom: 8px;
    color: #222d38;
    font-size: 18px;
    font-family: Circular-Std-Book;
    padding: 0px 10px !important;
    text-align: left;
  }
  .branding_subSericeVideo {
    text-align: center;
    padding: 0 10px !important;
  }
  .branding_youtubeVideo {
    width: 100% !important;
    height: 200px !important;
    margin-bottom: 50px;
  }
  .branding_subServiceTabSection {
    overflow: scroll;
    display: -webkit-box !important;
    width: 100% !important;
    margin: 0 !important;
    scroll-behavior: smooth;
  }
  .branding_glowEffectBrand {
    position: absolute;
    left: 6%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .branding_glowEffectDigital {
    position: absolute;
    left: 30%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .branding_glowEffectWorkFlow {
    position: absolute;
    left: 54%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .branding_subServiceContaincer {
    display: flex;
    padding: 10px;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    margin-bottom: 15px;
    border-color: #0077ff;
  }

  /* blog section */
  .branding_blogMiddleSection {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    width: 100%;
    scroll-behavior: smooth;
  }
  .branding_leftCaroselIcon {
    color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 3px;
    cursor: pointer;
    margin-right: 5px;
  }
  .branding_rightCaroselIcon {
    color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 3px;
    cursor: pointer;
    margin-left: 5px;
  }
  .branding_blogSectionCardContainer {
    border: 1px solid rgb(97, 97, 97);
    border-radius: 8px;
    min-width: 250px;
    margin: 10px;
  }
  .branding_blogSectionImg {
    width: 100%;
    height: 175px;
    border-radius: 5px 5px 0px 0px;
  }

  /* why choose us */
  .branding_whyChooseColumnUpContainer {
    margin-bottom: 50px;
    text-align: center;
    margin-top: 50px;
  }
  .branding_whyChooseColumnDownContainer {
    text-align: center;
  }

  .branding_whyChooseColumn3UpContainer {
    margin-bottom: 50px;
    margin-top: 50px;
    text-align: center;
  }
  .branding_whyChooseColumn3DownContainer {
    text-align: center;
  }
  .branding_about_chooseUsMiddleImg {
    width: 70%;
    margin-bottom: 0%;
    margin-top: 20%;
  }
  .branding_whyChooseUsTitle {
    font-size: 42px;
    font-family: 'Circular-Std-Medium';
    font-weight: 700;
    letter-spacing: -1px;
    text-align: center;
    margin-bottom: 0px;
  }
}

/* Tablet */
@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .branding_glowEffectBrand {
    position: absolute;
    left: -6%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
  }
  .branding_glowEffectDigital {
    position: absolute;
    left: 26%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
  }
  .branding_glowEffectWorkFlow {
    position: absolute;
    left: 64%;
    right: 0;
    width: 350px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 70%
    );
    opacity: 0.4;
  }
}
