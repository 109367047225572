/* banner section */
.BSSliderImgContainer_portal {
  text-align: center;
  align-self: flex-end;
  margin-bottom: -50px;
}

.brandStrategySliderImg_portal {
  margin-top: 20px;
  width: 450px;
}
.BSSliderRightSection_portal {
  margin: 50px 0px 0px 0px;
}
.sliderHeading_portal {
  font-size: 55px;
  font-family: 'Circular-Std-Medium';
  font-weight: 700;
  letter-spacing: -1px;
  margin-bottom: 30px;
}
.sliderSubHeading_portal {
  font-family: Circular-Std-Book;
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 1.5;
  color: rgb(97, 97, 97);
}
.serviceTabsSection_portal {
  padding: 30px 0px 60px 0px;
}
.MTabsWidth_portal {
  width: fit-content;
  box-shadow: none !important;
}

/* how service will help you */

.particalsSection_portal {
  position: absolute;
  width: 100%;
  height: 570px;
}
.BSHelpYouRightSection_portal {
  background-color: rgb(17, 17, 17);
  padding: 150px;
}
.BSHelpYouHeading_portal {
  font-size: 55px;
  font-family: 'Circular-Std-Medium';
  font-weight: 700;
  letter-spacing: -1px;
  color: rgb(97, 97, 97);
  max-width: 800px;
  margin: 0 auto;
}
.BSHelpYouPara_portal {
  font-family: Circular-Std-Book;
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 1.5;
  color: #fff;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
}
.circleBSHelpYou_portal {
  width: 70px;
  height: 70px;
  border: 30px solid #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  margin-bottom: -60px;
}

/* service content section */

.service_subheading_portal {
  font-size: 55px;
  font-family: 'Circular-Std-Medium';
  font-weight: 700;
  letter-spacing: -1px;
  color: #222d38;
  text-align: center;
}
.service_subtext_portal {
  color: #617080;
  font-size: 22px;
  font-family: Circular-Std-Book;
  text-align: center;
  margin-top: 5px;
  width: 42%;
  margin: 10px auto;
}
.subServiceTabSection_portal {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  scroll-behavior: smooth;
}
.subServiceContaincer_portal {
  display: flex;
  padding: 15px 50px;
  cursor: pointer;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.subServiceImg_portal {
  width: 45px;
  height: 45px;
  margin-right: 15px;
}
.subServiceTitle_portal {
  font-family: Circular-Std-Book;
  color: #222d38;
  font-size: 15px;
  line-height: 22px;
}

.subServiceSubTitle_portal {
  font-family: Circular-Std-Book;
  color: #617080;
  font-size: 15px;
  line-height: 22px;
}
.subServiceParaContainer_portal {
  background-color: rgba(247, 249, 250, 0.6);
  cursor: pointer;
  margin: 0 auto;
  padding-bottom: 50px;
}
.subServiceParaContainer_portal:hover {
  margin: 0 auto;
  /* width: 925px; */
  background-color: rgba(1, 119, 255, 0.06);
  opacity: 1;
}

.subServiceFixedSection_portal {
  width: 925px;
  position: relative;
}

.glowEffectBrand_portal {
  position: absolute;
  left: 5%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}
.serviceContent_portal {
  padding: 50px 100px;
  display: flex;
}
.subServicePara_portal {
  margin-bottom: 8px;
  color: #222d38;
  font-size: 18px;
  font-family: Circular-Std-Book;
  padding: 0px 130px;
  text-align: center;
}
.subSericeVideo_portal {
  text-align: center;
  padding: 0 100px;
}
.youtubeVideo_portal {
  width: 80%;
  height: 370px;
  margin-bottom: 50px;
}

.glowEffectDigital_portal {
  position: absolute;
  left: 29%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}
.glowEffectWorkFlow_portal {
  position: absolute;
  left: 54%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}

/* blog section */

.blogSection_portal {
  padding: 100px 0px 100px 0px;
  background-color: rgb(17, 17, 17);
}

.blogSectionContainer_portal {
  justify-content: center;
  display: flex;
}
.leftCaroselIcon_portal {
  color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.blogMiddleSection_portal {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  width: 1180px;
  scroll-behavior: smooth;
}
.blogSectionCardContainer_portal {
  border: 1px solid rgb(97, 97, 97);
  border-radius: 8px;
  min-width: 350px;
  margin: 20px 15px;
}
.blogSectionImg_portal {
  width: 100%;
  height: 230px;
  border-radius: 5px 5px 0px 0px;
}
.blogSectionHeading_portal {
  font-size: 28px;
  font-weight: 700;
  font-family: Circular-Std-Medium;
  margin-bottom: 10px;
  margin-top: 20px;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  color: #fff;
}
.blogSectionHeading_portal::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -7px;
  z-index: 100;
  width: 200px;
  height: 1px;
  background: rgb(97, 97, 97);
}
.blogSectionSubText_portal {
  font-size: 16px;
  font-family: Circular-Std-Book;
  color: #fff;
  line-height: 1.7;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
}
.blogSectionLink_portal {
  font-size: 17px;
  font-family: Circular-Std-Medium;
  color: rgb(0, 119, 255);
  /* color: rgb(97, 97, 97); */
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.arrowRight_portal {
  font-size: 20px !important;
  margin-bottom: -5px;
  margin-left: 10px;
}
.rightCaroselIcon_portal {
  color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 5px;
  cursor: pointer;
  margin-left: q0px;
}

/* why choose us */
.whyChooseUsSection_portal {
  padding: 100px 0px;
}
.whyChooseUsTitle_portal {
  font-size: 55px;
  font-family: 'Circular-Std-Medium';
  font-weight: 700;
  letter-spacing: -1px;
  text-align: center;
  margin-bottom: 50px;
}
.whyChooseColumnUpContainer_portal {
  margin-bottom: 50px;
  text-align: end;
  margin-top: 50px;
}
.chooseTitle_portal {
  font-size: 22px;
  font-family: Circular-Std-Book;
  font-weight: 600;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.8);
}

.choosePara_portal {
  font-size: 18px;
  font-family: Circular-Std-Book;
  font-weight: 500;
  line-height: 1.5;
  color: rgb(97, 97, 97);
}
.whyChooseColumnDownContainer_portal {
  text-align: end;
}
.chooseUsImageRow_portal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chooseUsMiddleImg_portal {
  width: 70%;
  margin-bottom: 15%;
}
.whyChooseColumn3UpContainer_portal {
  margin-bottom: 50px;
  margin-top: 50px;
  text-align: left;
}
.whyChooseColumn3DownContainer_portal {
  text-align: left;
}

.floatingUpDown {
  animation-name: floatingUpDown;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floatingUpDown {
  from {
    transform: translate(0px, 0px);
  }
  65% {
    transform: translate(0px, 10px);
  }
  to {
    transform: translate(0px, 0px);
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  /* banner section */
  .brandStrategySliderImg_portal {
    margin-top: 70px;
    width: 80%;
    margin-bottom: 70px;
  }
  .sliderHeading_portal {
    font-size: 42px;
    font-family: 'Circular-Std-Medium';
    font-weight: 700;
    letter-spacing: -1px;
    margin-bottom: 30px;
  }
  .BSSliderRightSection_portal {
    margin: 30px 0px 30px 0px;
  }
  .MTabsWidth_portal {
    width: inherit;
    box-shadow: none !important;
  }
  .MTabsContainer > .MuiTabs-fixed {
    overflow-x: scroll !important;
  }

  /* how serivce will help you */
  .BSHelpYouRightSection_portal {
    background-color: rgb(17, 17, 17);
    padding: 100px 15px;
  }
  .BSHelpYouHeading_portal {
    font-size: 42px;
    font-family: 'Circular-Std-Medium';
    font-weight: 700;
    letter-spacing: -1px;
    color: rgb(97, 97, 97);
    max-width: 800px;
    margin: 0 auto;
  }
  .BSHelpYouPara_portal {
    font-family: Circular-Std-Book;
    font-size: 18px;
    line-height: 1.5;
    color: #fff;
    max-width: 800px;
    margin: 30px auto;
  }

  /* service content section */
  .service_subheading_portal {
    font-size: 42px;
    font-family: 'Circular-Std-Medium';
    font-weight: 700;
    letter-spacing: -1px;
    padding: 0px 10px;
  }
  .service_subtext_portal {
    color: #617080;
    font-size: 22px;
    font-family: Circular-Std-Book;
    text-align: center;
    margin-top: 5px;
    width: 75% !important;
    margin: 10px auto;
  }
  .subServiceTabSection_portal {
    overflow: scroll;
    display: -webkit-box !important;
    width: 100% !important;
    margin: 0 !important;
    scroll-behavior: smooth;
  }

  .glowEffectBrand_portal {
    position: absolute;
    left: 6%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .serviceContent_portal {
    padding: 20px 10px !important;
    display: flex !important;
  }
  .serviceContent_portalOne {
    padding: 20px 10px 130px 10px;
    display: flex;
  }
  .serviceContent_portalThree {
    padding: 20px 10px 130px 10px;
    display: flex;
  }
  .subServicePara_portal {
    margin-bottom: 8px;
    color: #222d38;
    font-size: 18px;
    font-family: Circular-Std-Book;
    padding: 0px 10px !important;
    text-align: left;
  }
  .subSericeVideo_portal {
    text-align: center;
    padding: 0 10px !important;
  }
  .youtubeVideo_portal {
    width: 100% !important;
    height: 200px !important;
    margin-bottom: 50px;
  }

  .glowEffectDigital_portal {
    position: absolute;
    left: 30%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .glowEffectWorkFlow_portal {
    position: absolute;
    left: 54%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .subServiceContaincer_portal {
    display: flex;
    padding: 10px;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    margin-bottom: 15px;
    border-color: #0077ff;
  }
  .subServiceImg_portal {
    width: 45px;
    height: 45px;
    margin-right: 15px;
  }
  .subServiceFixedSection_portal {
    width: 925px;
    position: relative;
    height: 390px;
  }

  /* blog section */
  .blogMiddleSection_portal {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    width: 100%;
    scroll-behavior: smooth;
  }
  .leftCaroselIcon_portal {
    color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 3px;
    cursor: pointer;
    margin-right: 5px;
  }
  .rightCaroselIcon_portal {
    color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 3px;
    cursor: pointer;
    margin-left: 5px;
  }
  .blogSectionCardContainer_portal {
    border: 1px solid rgb(97, 97, 97);
    border-radius: 8px;
    min-width: 250px;
    margin: 10px;
  }
  .blogSectionImg_portal {
    width: 100%;
    height: 175px;
    border-radius: 5px 5px 0px 0px;
  }

  /* why choose us */
  .whyChooseColumnUpContainer_portal {
    margin-bottom: 50px;
    text-align: center;
    margin-top: 50px;
  }
  .whyChooseColumnDownContainer_portal {
    text-align: center;
  }

  .whyChooseColumn3UpContainer_portal {
    margin-bottom: 50px;
    margin-top: 50px;
    text-align: center;
  }
  .whyChooseColumn3DownContainer_portal {
    text-align: center;
  }
  .chooseUsMiddleImg_portal {
    width: 70%;
    margin-bottom: 0%;
    margin-top: 20%;
  }
  .whyChooseUsTitle_portal {
    font-size: 42px;
    font-family: 'Circular-Std-Medium';
    font-weight: 700;
    letter-spacing: -1px;
    text-align: center;
    margin-bottom: 0px;
  }
}

/* Tablet */
@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .glowEffectBrand_portal {
    position: absolute;
    left: -7%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
  }
  .glowEffectDigital_portal {
    position: absolute;
    left: 24%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
  }
  .glowEffectWorkFlow_portal {
    position: absolute;
    left: 60%;
    right: 0;
    width: 400px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 70%
    );
    opacity: 0.4;
  }
}

@media screen and (max-width: 768px) {
  .subServiceFixedSection_portal{
    height:150px;
  }
  .subServiceParaContainer_portal{
    padding-bottom: 0px;
  }
  .metaverse-page{
    overflow:hidden;
    }
}
