/* banner section */
.BSSliderImgContainer_socialmedia {
  text-align: center;
  align-self: flex-end;
  margin-bottom: -50px;
}
.brandStrategySliderImg_socialmedia {
  margin-top: 20px;
  width: 450px;
}
.BSSliderRightSection_socialmedia {
  margin: 50px 0px 0px 0px;
}
.sliderHeading_socialmedia {
  font-size: 55px;
  font-family: 'Circular-Std-Medium';
  font-weight: 700;
  letter-spacing: -1px;
  margin-bottom: 30px;
}
.sliderSubHeading_socialmedia {
  font-family: Circular-Std-Book;
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 1.5;
  color: rgb(97, 97, 97);
}
.serviceTabsSection_socialmedia {
  padding: 30px 0px 60px 0px;
}
.MTabsWidth_socialmedia {
  width: fit-content;
  box-shadow: none !important;
}

/* how service will help you */

.particalsSection_socialmedia {
  position: absolute;
  width: 100%;
  height: 570px;
}
.BSHelpYouRightSection_socialmedia {
  background-color: rgb(17, 17, 17);
  padding: 150px;
}
.BSHelpYouHeading_socialmedia {
  font-size: 55px;
  font-family: 'Circular-Std-Medium';
  font-weight: 700;
  letter-spacing: -1px;
  color: rgb(97, 97, 97);
  max-width: 800px;
  margin: 0 auto;
}
.BSHelpYouPara_socialmedia {
  font-family: Circular-Std-Book;
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 1.5;
  color: #fff;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
}
.circleBSHelpYou_socialmedia {
  width: 70px;
  height: 70px;
  border: 30px solid #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  margin-bottom: -60px;
}

/* service content section */

.service_subheading_socialmedia {
  font-size: 55px;
  font-family: 'Circular-Std-Medium';
  font-weight: 700;
  letter-spacing: -1px;
  color: #222d38;
  text-align: center;
}
.service_subtext_socialmedia {
  color: #617080;
  font-size: 22px;
  font-family: Circular-Std-Book;
  text-align: center;
  margin-top: 5px;
  width: 42%;
  margin: 10px auto;
}
.subServiceTabSection_socialmedia {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  scroll-behavior: smooth;
}
.subServiceContaincer_socialmedia {
  display: flex;
  padding: 15px 50px;
  cursor: pointer;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.subServiceImg_socialmedia {
  width: 45px;
  height: 45px;
  margin-right: 15px;
}
.subServiceTitle_socialmedia {
  font-family: Circular-Std-Book;
  color: #222d38;
  font-size: 15px;
  line-height: 22px;
}

.subServiceSubTitle_socialmedia {
  font-family: Circular-Std-Book;
  color: #617080;
  font-size: 15px;
  line-height: 22px;
}
.subServiceParaContainer_socialmedia {
  background-color: rgba(247, 249, 250, 0.6);
  cursor: pointer;
  margin: 0 auto;
  padding-bottom: 50px;
}
.subServiceParaContainer_socialmedia:hover {
  margin: 0 auto;
  background-color: rgba(1, 119, 255, 0.06);
  opacity: 1;
}

.subServiceFixedSection_socialmedia {
  width: 925px;
  position: relative;
}

.glowEffectBrand_socialmedia {
  position: absolute;
  left: -1%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}
.serviceContent_socialmedia {
  padding: 50px 100px;
  display: flex;
}
.subServicePara_socialmedia {
  margin-bottom: 8px;
  color: #222d38;
  font-size: 18px;
  font-family: Circular-Std-Book;
  padding: 0px 130px;
  text-align: center;
}
.subSericeVideo_socialmedia {
  text-align: center;
  padding: 0 100px;
}
.youtubeVideo_socialmedia {
  width: 80%;
  height: 370px;
  margin-bottom: 50px;
}

.glowEffectDigital_socialmedia {
  position: absolute;
  left: 28%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}
.glowEffectWorkFlow_socialmedia {
  position: absolute;
  left: 59%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}

/* blog section */
.blogSection_socialmedia {
  padding: 100px 0px 100px 0px;
  background-color: rgb(17, 17, 17);
}

.blogSectionContainer_socialmedia {
  justify-content: center;
  display: flex;
}
.leftCaroselIcon_socialmedia {
  color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.blogMiddleSection_socialmedia {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  width: 1180px;
  scroll-behavior: smooth;
}
.blogSectionCardContainer_socialmedia {
  border: 1px solid rgb(97, 97, 97);
  border-radius: 8px;
  min-width: 350px;
  margin: 20px 15px;
}
.blogSectionImg_socialmedia {
  width: 100%;
  height: 230px;
  border-radius: 5px 5px 0px 0px;
}
.blogSectionHeading_socialmedia {
  font-size: 28px;
  font-weight: 700;
  font-family: Circular-Std-Medium;
  margin-bottom: 10px;
  margin-top: 20px;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  color: #fff;
}
.blogSectionHeading_socialmedia::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -7px;
  z-index: 100;
  width: 200px;
  height: 1px;
  background: rgb(97, 97, 97);
}
.blogSectionSubText_socialmedia {
  font-size: 16px;
  font-family: Circular-Std-Book;
  color: #fff;
  line-height: 1.7;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
}
.blogSectionLink_socialmedia {
  font-size: 17px;
  font-family: Circular-Std-Medium;
  color: rgb(0, 119, 255);
  /* color: rgb(97, 97, 97); */
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.arrowRight_socialmedia {
  font-size: 20px !important;
  margin-bottom: -5px;
  margin-left: 10px;
}
.rightCaroselIcon_socialmedia {
  color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
}

/* why choose us */
.whyChooseUsSection_socialmedia {
  padding: 100px 0px;
}
.whyChooseUsTitle_socialmedia {
  font-size: 55px;
  font-family: 'Circular-Std-Medium';
  font-weight: 700;
  letter-spacing: -1px;
  text-align: center;
  margin-bottom: 50px;
}
.whyChooseColumnUpContainer_socialmedia {
  margin-bottom: 50px;
  text-align: end;
  margin-top: 50px;
}
.chooseTitle_socialmedia {
  font-size: 22px;
  font-family: Circular-Std-Book;
  font-weight: 600;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.8);
}

.choosePara_socialmedia {
  font-size: 18px;
  font-family: Circular-Std-Book;
  font-weight: 500;
  line-height: 1.5;
  color: rgb(97, 97, 97);
}
.whyChooseColumnDownContainer_socialmedia {
  text-align: end;
}
.chooseUsImageRow_socialmedia {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chooseUsMiddleImg_socialmedia {
  width: 70%;
  margin-bottom: 15%;
}
.whyChooseColumn3UpContainer_socialmedia {
  margin-bottom: 50px;
  margin-top: 50px;
  text-align: left;
}
.whyChooseColumn3DownContainer_socialmedia {
  text-align: left;
}

.floatingUpDown {
  animation-name: floatingUpDown;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floatingUpDown {
  from {
    transform: translate(0px, 0px);
  }
  65% {
    transform: translate(0px, 10px);
  }
  to {
    transform: translate(0px, 0px);
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  /* banner section */
  .brandStrategySliderImg_socialmedia {
    margin-top: 70px;
    width: 80%;
    margin-bottom: 70px;
  }
  .sliderHeading_socialmedia {
    font-size: 55px;
    font-family: 'Circular-Std-Medium';
    font-weight: 700;
    letter-spacing: -1px;
    margin-bottom: 30px;
  }
  .BSSliderRightSection_socialmedia {
    margin: 30px 0px 30px 0px;
  }
  .MTabsWidth_socialmedia {
    width: inherit;
    box-shadow: none !important;
  }
  .MTabsContainer > .MuiTabs-fixed {
    overflow-x: scroll !important;
  }
  /* how service will help you */
  .BSHelpYouRightSection_socialmedia {
    background-color: rgb(17, 17, 17);
    padding: 100px 15px;
  }
  .BSHelpYouHeading_socialmedia {
    font-size: 55px;
    font-family: 'Circular-Std-Medium';
    font-weight: 700;
    letter-spacing: -1px;
    color: rgb(97, 97, 97);
    max-width: 800px;
    margin: 0 auto;
  }
  .BSHelpYouPara_socialmedia {
    font-family: Circular-Std-Book;
    font-size: 18px;
    line-height: 1.5;
    color: #fff;
    max-width: 800px;
    margin: 30px auto;
  }

  /* service content section */
  .service_subheading_socialmedia {
    font-size: 55px;
    font-family: 'Circular-Std-Medium';
    font-weight: 700;
    letter-spacing: -1px;
    padding: 0px 10px;
  }
  .service_subtext_socialmedia {
    color: #617080;
    font-size: 22px;
    font-family: Circular-Std-Book;
    text-align: center;
    margin-top: 5px;
    width: 75% !important;
    margin: 10px auto;
  }
  .subServiceTabSection_socialmedia {
    overflow: scroll;
    display: -webkit-box !important;
    width: 100% !important;
    margin: 0 !important;
    scroll-behavior: smooth;
  }

  .glowEffectBrand_socialmedia {
    position: absolute;
    left: 6%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .serviceContent_socialmedia {
    padding: 20px 10px !important;
    display: flex !important;
  }
  .serviceContent_socialmediaOne {
    padding: 20px 10px 70px 10px;
    display: flex;
  }
  .serviceContent_socialmediaThree {
    padding: 20px 10px 70px 10px;
    display: flex;
  }
  .subServicePara_socialmedia {
    margin-bottom: 8px;
    color: #222d38;
    font-size: 18px;
    font-family: Circular-Std-Book;
    padding: 0px 10px !important;
    text-align: left;
  }
  .subSericeVideo_socialmedia {
    text-align: center;
    padding: 0 10px !important;
  }
  .youtubeVideo_socialmedia {
    width: 100% !important;
    height: 200px !important;
    margin-bottom: 50px;
  }

  .glowEffectDigital_socialmedia {
    position: absolute;
    left: 30%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .glowEffectWorkFlow_socialmedia {
    position: absolute;
    left: 54%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .subServiceContaincer_socialmedia {
    display: flex;
    padding: 10px;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    margin-bottom: 15px;
    border-color: #0077ff;
  }
  .subServiceImg_socialmedia {
    width: 45px;
    height: 45px;
    margin-right: 15px;
  }
  .subServiceFixedSection_socialmedia {
    width: 925px;
    position: relative;
    height: 350px;
  }

  /* blog section */
  .blogMiddleSection_socialmedia {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    width: 100%;
    scroll-behavior: smooth;
  }
  .leftCaroselIcon_socialmedia {
    color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 3px;
    cursor: pointer;
    margin-right: 5px;
  }
  .rightCaroselIcon_socialmedia {
    color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 3px;
    cursor: pointer;
    margin-left: 5px;
  }
  .blogSectionCardContainer_socialmedia {
    border: 1px solid rgb(97, 97, 97);
    border-radius: 8px;
    min-width: 250px;
    margin: 10px;
  }
  .blogSectionImg_socialmedia {
    width: 100%;
    height: 175px;
    border-radius: 5px 5px 0px 0px;
  }

  /* why choose us */
  .whyChooseColumnUpContainer_socialmedia {
    margin-bottom: 50px;
    text-align: center;
    margin-top: 50px;
  }
  .whyChooseColumnDownContainer_socialmedia {
    text-align: center;
  }

  .whyChooseColumn3UpContainer_socialmedia {
    margin-bottom: 50px;
    margin-top: 50px;
    text-align: center;
  }
  .whyChooseColumn3DownContainer_socialmedia {
    text-align: center;
  }
  .chooseUsMiddleImg_socialmedia {
    width: 70%;
    margin-bottom: 0%;
    margin-top: 20%;
  }
  .whyChooseUsTitle_socialmedia {
    font-size: 42px;
    font-family: 'Circular-Std-Medium';
    font-weight: 700;
    letter-spacing: -1px;
    text-align: center;
    margin-bottom: 0px;
  }
}

/* Tablet */
@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .glowEffectBrand_socialmedia {
    position: absolute;
    left: -8%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
  }
  .glowEffectDigital_socialmedia {
    position: absolute;
    left: 24%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
  }
  .glowEffectWorkFlow_socialmedia {
    position: absolute;
    left: 64%;
    right: 0;
    width: 350px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 80%
    );
    opacity: 0.4;
  }
}
