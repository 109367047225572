.blockTech-container {
  background-color: black;
  opacity: 0.9;
  height: 800px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.scroll-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: absolute;
  overflow: scroll;
  max-width: 1500px;
  margin: 0 auto;
  margin-left: 60px;
}
.blockTech-innerContainer {
  width: 1580px;
  position: absolute;
  margin-left: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.blockTech-test {
  margin-left: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
}
.blockTech-groupImage {
  max-width: 1800px;
  margin: 0 auto;
  position: absolute;
}
.blockTech-header {
  color: white;
  font-size: 50px;
  font-family: Circular-Std-Bold;
  letter-spacing: -3.15px;
  line-height: 55px;
  white-space: pre-line;
  opacity: 0.9;
}
.blockTech-content {
  font-size: 18px;
  font-family: Circular-Std-Book;
  font-weight: 500;
  line-height: 1.4;
  color: white;
  text-align: justify;
  padding-top: 30px;
  height: 200px;
}
.blockTech-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 743px;
  height: 400px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 5px 0;
  backdrop-filter: blur(5px);
  margin-right: 15px;
  border-radius: 25px;
  border: 2px solid #7f7f7f;
  opacity: 0.9;
}
.blockTech-content-container {
  padding-left: 20px;
  padding-right: 20px;
}
.blockTech-img-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
}
.blockTech-img {
  height: 300px;
}
.blockTech-buttonContainer {
  padding-top: 20px;
  margin-top: 20px;
}

.blockTech-button {
  font-family: Circular-Std-Book !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  background-color: #0077ff !important;
  color: #fff !important;
  text-transform: capitalize !important;
}
.blockTech-groupOne-container {
  position: absolute;
  right: 0;
  bottom: 0;
}
.blockTech-groupTwo-container {
  position: absolute;
  left: 0;
  top: 0;
}
.group-image {
  opacity: 0.5;
}

.leftCaroselIcon-benefits {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 15%);
  font-size: 28px !important;
  background-color: white;
  color: #535353;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.rightCaroselIcon_benefits {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 15%);
  font-size: 28px !important;
  background-color: white;
  color: #535353;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1680px;
  margin: 0 auto;
}
.left-one {
  margin-left: 35px;
}
.right-icon {
  margin-right: 23px;
}

@media screen and (max-width: 600px) {
  .blockTech-content-container {
    padding: 0px 10px;
  }
  .blockTech-card {
    padding: 5px;
  }
  .blockTech-content {
    height: 150px;
  }
  .scroll-container {
    overflow: scroll;
    max-width: 1500px;
    margin: 0 auto;
    margin-left: 1113px;
  }
  .blockTech-innerContainer {
    margin-left: 20px;
  }
  .left-one {
    margin-left: 0px !important;
  }
  .right-icon {
    margin-right: 10px;
  }
  .inner {
    width: 100%;
  }
  .blockTech-innerContainer {
    width: 100%;
  }
}


@media screen and (max-width:1500px) {
  .blockTech-container{
max-width: 100% !important;
  }
  
}