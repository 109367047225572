.FollowUsSection {
  position: fixed;
  display: flex;
  right: -164px;
  transform: rotate(-90deg) translate(50%, -50%);
  top: 750px;
  z-index: 99999;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
}
.followUsTitle {
  font-family: Circular-Std-Bold;
  color: #000;
  font-size: 18px;
  padding: 0px 10px;
  z-index: 1;
}
.spacebar {
  font-family: Circular-Std-Bold;
  padding: 0px 10px;
  /* font-weight: 600; */
}
.followUsIconBtn {
  transform: rotate(90deg);
  padding: 0px 10px;
  color: #000;
}
.followUsTitle2 {
  font-family: Circular-Std-Bold;
  color: #fff;
  font-size: 18px;
  padding: 0px 10px;
  z-index: 1;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .FollowUsSection {
    position: fixed;
    display: none;
    right: -164px;
    transform: rotate(-90deg) translate(50%, -50%);
    top: 550px;
    z-index: 1;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  }
}
