.hp-ctn-howItWorks {
  padding: 0px 0px 0px 0px;
  text-align: center;
  margin: 0px;
  width: 120px;
  height: 40px;
  background: #0477ff;
  z-index: 15;
  border-radius: 5px 5px 0px 0px;
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform-origin: bottom right;
  position: fixed;
  right: 0px;
  cursor: pointer;
}
.hp-ctn-howItWorks span p {
  color: #fff;
  display: inline-block;
  line-height: 40px;
  font-family: Circular-Std-Book !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 2;
  background-color: #0077ff !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.fixed_form_icon {
  transform: rotate(90deg);
  padding-top: 5px;
  color: white;
  font-size: 18px !important;
}
