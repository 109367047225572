.directorStatementSection {
  background-color: rgb(17, 17, 17);
  padding: 70px 0px 100px 0px;
  margin-top: 70px;
}
.directorHeading {
  font-size: 55px;
  font-family: Circular-Std-Medium;
  letter-spacing: -1px;
  font-weight: 700;
  color: rgb(97, 97, 97);
  margin-bottom: 20px;
}
.directorPara {
  font-size: 17px;
  font-family: Circular-Std-Book;
  color: #fff;
  line-height: 1.8;
  font-weight: 100;
  margin-top: 20px;
}
.circleDirector {
  width: 70px;
  height: 70px;
  border: 30px solid #fff;
  border-radius: 50%;
  margin-top: -130px;
  margin-left: 10px;
}
.directorImg {
  width: 50%;
  margin-top: -150px;
}
.dottedLine1 {
  color: #fff;
  font-size: 30px;
  line-height: 0.5;
}
.dottedLineContainer__about {
  position: absolute;
  bottom: 0;
  margin-bottom: -80px;
  margin-left: 20px;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .directorHeading {
    font-size: 42px;
    font-family: Circular-Std-Medium;
    font-weight: 700;
    letter-spacing: -1px;
    color: #fff;
    margin-bottom: 30px;
    margin-top: 50px;
  }
  .circleDirector {
    width: 70px;
    height: 70px;
    border: 30px solid #fff;
    border-radius: 50%;
    margin-bottom: -160px;
    margin-left: 10px;
    margin-top: 120px;
  }
  .dottedLineContainer {
    position: absolute;
    bottom: 0;
    margin-bottom: -100px;
    margin-left: 20px;
    right: 0;
  }
  .directorMobileImg {
    width: 40%;
    margin-top: -130px;
  }
  .dottedLineMobileContainer__about {
    position: relative;
    bottom: 0;
    margin-bottom: -80px;
    margin-left: 20px;
    margin-top: 50px;
  }
}
