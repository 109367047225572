.newQuotePage__container {
  display: flex;
  justify-content: center;
  height: fit-content;
  margin-top: 0vh;
  margin-bottom: 20vh;
}
.newQuotePageContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1500px;
  height: 650px;
  padding:40px;
}

.mainTitle_div {
  text-align: center;
  margin-bottom: 3vh;
}

.quote_title {
  font-family: "Circular-Std-Black";
  font-size: 32px;
  font-weight: 800;
}

.alert {
  position: fixed !important;
  right: 10px !important;
  top: 117px !important;
}

.carousel_div {
  border: 1px solid #cfcece;
  border-radius: 20px !important;
}

.card {
  margin: 20px;
  box-shadow: none !important;
  border-radius: 20px !important;
}

.title {
  font-size: 23px;
  font-weight: 700;
}

/* card */

.StatsContainer {
  text-align: end;
  padding: 10px 30px 20px 30px;
  position: relative;
  color: #fff;
  border-radius: 8px;
  margin-top: 10px;
  /* margin-right: 20px; */
}

.statsImg__row {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.callImg__row {
  display: flex;
  margin-bottom: 50px;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
}

.flagImg__row {
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
}

.statsImg {
  width: 15% !important;
  height: auto;
  align-self: center;
  margin-right: 10px;
}

.callImg {
  width: 20% !important;
  height: auto;
  align-self: center;
}

.flagImg {
  width: 5% !important;
  height: auto;
  align-self: center;
  margin-right: 10px;
}

.statsNum {
  font-size: 30px;
  font-weight: 600;
  color: #000;
}

.Name {
  font-size: 20px;
  font-family: Circular-Std-Book !important;
  text-align: center;
  color: #000;
  /*  padding-left: 80px; */
}

.divider {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.callNum {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.carousel_div .carousel .control-dots .dot {
  background: #0077ff;
}

.fields .MuiInput-underline:before {
  border-bottom: 0px !important;
}

.fields .MuiInput-underline:after {
  border-bottom: 0px !important;
  color: black !important;
}

.fields label + .MuiInput-formControl {
  margin-top: 22px !important;
}

.fields .MuiFormControl-root {
  height: 44px !important;
}

.textField_container .MuiFormControl-root {
  width: 100% !important;
}

.textField_container {
  width: 80%;
  border: 2px solid #4c9bfb;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 12px;
  margin-bottom: 25px;
  padding: 12px;
}

.row_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.textFiled_Icon {
  color: #4c9bfb !important;
  padding-left: 2px !important;
  padding-right: 8px !important;
}

.textField_container_description {
  border: 0.5px solid #cccccc;
  width: 100%;
  border-radius: 20px;
  opacity: 1;
  margin: 10px;
  margin-bottom: 0px;
  padding: 20px;
  position: relative;
}

.attach_icon_container {
  position: absolute;
}

.attach_icon {
  top: 21px !important;
  padding-left: -20px;
  left: -8px !important;
  position: absolute;
  padding-top: 20px;
  padding-right: 20px;
  margin-right: 20px;
  transform: rotate(45deg);
  cursor: pointer;
  color: #0077ff;
}

/* 
//choose requirements css */

.iconInner_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconInner_container_strategy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quote_label_before {
  font-family: "Circular-Std-Black";
  font-size: 15px;
  letter-spacing: 0px;
  padding-top: 10px;
  font-weight: 400;
  color: #707070;
}

.quote_label {
  font-family: "Circular-Std-Black";
  font-size: 15px;
  letter-spacing: 0px;
  color: #4c9bfb;
  padding-top: 10px;
  font-weight: 400;
}

.chooseReq_main_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.quote_img {
  height: 66px !important;
  width: 66px !important;
}

.hooseReq_Icon_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.selectSub_cat {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}

.eachIcon_container_before {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  box-shadow: 3px 6px 10px #00000029;
  border: 2px solid #4c9bfb;
  border-radius: 20px;
  filter: grayscale(1);
}

.eachIcon_container_before:hover {
  filter: grayscale(0);
}

.eachIcon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  box-shadow: 3px 6px 10px #00000029;
  border: 2px solid #4c9bfb;
  border-radius: 20px;
}

.chooseReq_Icon_container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.check_box {
  color: #4c9bfb !important;
  font-size: 20px !important;
  background-color: transparent !important;
}

.checkBoxes_container_one .MuiFormControlLabel-label {
  font-family: "Circular-Std-Black";
  color: #707070 !important;
}

.checkBox_container_two .MuiFormControlLabel-label {
  font-family: "Circular-Std-Black";
  color: #707070 !important;
}

.badge_form {
  color: #4c9bfb !important;
  border-color: #4c9bfb !important;
}

.form_header {
  font-family: "Circular-Std-Black";
  font-weight: 600;
  font-size: 18px;
  padding-left: 18px;
}

.des_header {
  font-size: 23px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600px;
}

.sub_header {
  font-family: "Circular-Std-Black";
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600;
}

.submit_button {
  font-family: "Circular-Std-Black" !important;
  margin-left: 20px !important;
  background: #4c9bfb !important;
  max-width: 300px !important;
  min-width: 300px !important;
  color: white !important;
  border-radius: 13px !important;
  padding: 10px !important;
  height: 50px !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 18px !important;
}

.quote_badge .MuiBadge-badge {
  top: 7px !important;
  right: 5px !important;
  border: 2px solid #4c9bfb !important;
  color: #4c9bfb !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  background-color: white !important;
}

.textField_mainContainer {
  position: relative;
}

.quote_helperText {
  color: red !important;
  padding-left: 20px;
  padding-bottom: 5px;
  position: absolute;
  margin-left: 15px !important;
  top: 53px;
}

.quote_helperText_description {
  color: red !important;
  padding-left: 20px;
  padding-bottom: 5px;
  margin-left: 15px !important;
  top: 53px;
}

.description_header {
  font-family: "Circular-Std-Black";
  letter-spacing: 0px;
  color: #000000;
  font-size: 20px;
  font-weight: 600 !important;
  padding-left: 20px;
}

.check_box_main_Container {
  display: flex;
  flex-direction: column;
}

.checkBoxes_container_one {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.checkBox_container_two {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* 
// file preview */

.file_preview_container {
  padding-top: 0px;
  padding-left: 30px;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.quote_pdf_image {
  height: 100px;
  width: 100px;
}

.quote_pdfName {
  font-size: 14px;
  text-align: center;
}

.quote_upload_image {
  width: 100px;
  height: 95px;
}

.quote_bottom_div {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.quote_image_size {
  font-size: 12px;
}

.quotePdf_main_container {
  height: 100px;
  width: 100px;
}

.selected_files_container {
  width: 100px;
  height: 100px;
  padding-bottom: 10px;
}

.error_container {
  display: flex;
  flex-direction: row;
  clear: center;
  justify-content: center;
}

.error_text {
  font-family: "Circular-Std-Black";
  font-size: 15px;
  font-weight: 100px;
  color: red;
}

/* // thankyour */

.handshake_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thank_msg_one {
  font-size: 22px;
  font-weight: bold;
  color: #707070 !important;
}

.thank_msg {
  font-size: 20px;
  font-weight: bold;
  color: #707070 !important;
}

.thank_msg_container {
  padding: 20px 0px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.back_home {
  margin: 20px !important;
  margin-left: 20px !important;
  background: #4c9bfb !important;
  max-width: 335px !important;
  min-width: 335px !important;
  color: white !important;
  border-radius: 15px !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 18px !important;
}

.handshake_image_thanku {
  height: 25% !important;
  width: 25% !important;
}

.form_container {
  margin-top: 0%;
}

.mobile_error {
  display: none;
}

.quote_helperText_description_desktop {
  color: red !important;
  padding-left: 20px;
  padding-bottom: 5px;
  margin-left: 15px !important;
  top: 53px;
}

/* .checkBox_container_two{
  width:100%;
}
.checkBox_container_two .row_one{
  width:72%;
}
.checkBox_container_two .row_two{
  width:30%;
} */

.Devops {
  padding-right: 92px;
}

@media screen and (max-width: 1268px) {
  .newQuotePage__container .MuiContainer-fixed {
    max-width: 1100px;
  }
}

@media screen and (max-width: 941px) {
  .newQuotePage__container .MuiContainer-fixed {
    max-width: 800px;
  }
  .eachIcon_container_before {
    width: 75px;
    height: 75px;
  }
  .eachIcon_container {
    width: 75px;
    height: 75px;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .newQuotePage__container {
    margin-top: 50px;
    margin-bottom: 10vh;
  }
  .newQuotePage__container .MuiContainer-root {
    padding-left: 24px;
    padding-right: 0px;
  }
  .row_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .textField_container_description {
    width: 89%;
    margin-bottom: 15px;
  }
  .submit_button {
    margin-top: 10px;
  }
}

@media screen and (max-width: 600px) {
  .sub_header {
    font-size: 20px;
  }
  .fields .MuiInputBase-input {
    font-size: 20px;
  }
  .quote_title {
    font-size: 46px;
  }
  .quote_helperText_description_desktop {
    display: none;
  }
  .mobile_error {
    display: block;
    align-items: flex-start;
    width: 100%;
  }
  .textField_container_description {
    width: 85%;
    margin-bottom: 10px;
  }
  .row_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .description_header {
    padding-left: 0px;
  }
  .newQuotePage__container {
    margin-top: 3vh;
  }
  .mainTitle_div {
    margin-top: 10vh;
  }
  .quote_img {
    height: 44px !important;
    width: 44px !important;
  }
  .textField_container {
    width: 90% !important;
  }
  .eachIcon_container_before {
    width: 75px;
    height: 75px;
  }
  .eachIcon_container {
    width: 75px;
    height: 75px;
  }
  .chooseReq_main_container {
    padding: 10px 0px;
  }
  .checkBoxes_container_one {
    padding-left: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
  .checkBox_container_two {
    padding-left: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
  .form_container {
    margin-top: 10%;
  }
  .form_header {
    font-size: 20px;
    padding-left: 0px;
  }
  .submit_button {
    min-width: 250px !important;
  }
  .iconInner_container_strategy {
    padding-right: 7px;
  }
}

.fields .MuiInputBase-input {
  padding: 0px;
  font-family: "Circular-Std-Black" !important;
}

@media screen and (max-width: 376px) {
  .newQuotePage__container .MuiContainer-root {
    padding-left: 10px;
  }
  .submit_button {
    min-width: 240px !important;
  }
}
