.benefits-container-blockchain {
  max-width: 1300px;
  margin: 0 auto;
}
.card-header-blockchain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.card-header {
  font-size: 55px;
  font-family: "Circular-Std-Medium";
  font-weight: 700;
  letter-spacing: -1px;
  text-align: center;
  margin-bottom: 50px;
}

.each-header-block {
  font-size: 22px;
  font-family: Circular-Std-Book;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.each-description-block {
  font-size: 18px;
  font-family: Circular-Std-Book;
  font-weight: 500;
  line-height: 1.4;
  color: rgb(97, 97, 97);
  text-align:justify;
}
.card-blockchain:hover {
  transform: scale(1.01);
  transform: 8s;
}
.card-blockchain:hover .each-header-block {
  color: #0077ff;
}
.card-blockchain:hover {
  box-shadow: 0px 2px 1px -1px rgb(4 119 225 / 25%),
    0px 1px 1px 0px rgb(4 119 225 / 50%), 0px 1px 3px 0px rgb(4 119 225 / 50%) !important;
}

.card-container {
  padding: 20px;
  margin-bottom: 40px;
}
.card-innerContainer {
  text-align: center;
}
.card-blockchain {
  max-width: 360px;
  border-radius: 20px;
  height: 450px;
  border-radius: 20px !important;
  cursor: pointer;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 12%),
    0px 1px 1px 0px rgb(0 0 0 / 12%), 0px 1px 3px 0px rgb(0 0 0 / 15%) !important;
}
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 25px;
}
.card-line {
  background-color: #0077ff;
  height: 0.5px;
}

.block-images{
  height:80px;
}


@media screen and (max-width: 600px) {
  .card-grid {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
}


@media screen and (max-width:376px){
  .card-blockchain {
    max-width:320px;
    border-radius: 20px;
    height:460px;
    border-radius:20px !important;
    cursor: pointer;
  }
  
}