.valubleStatsContainer {
  background-color: rgb(17, 17, 17);

  position: relative;
}
.valubleStatsLeftSection {
  padding: 80px 0px 100px 0px;
  width: 750px;
  margin: 0 auto;
}
.valubleStatsTitle {
  font-size: 55px;
  font-family: 'Circular-Std-Medium';
  font-weight: 700;
  letter-spacing: -1px;
  color: rgb(97, 97, 97);
  padding-left: 120px;
  line-height: 1.2;
  margin-bottom: 10px;
}
.statsIcon__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}
.statsCount {
  font-size: 42px;
  font-family: Circular-Std-Bold;
  font-weight: 700;
}
.statsStarCount {
  font-size: 42px;
  font-family: Circular-Std-Bold;
  font-weight: 700;
  margin-left: 10px;
}
.statsIcon {
  height: 65px;
  align-self: center;
}

.statsName {
  font-size: 20px;
  font-family: Circular-Std-Book;
  text-align: center;
}

.firstStatsContainer {
  text-align: end;
  padding: 10px 30px 20px 30px;
  position: relative;
  color: #fff;

  border-radius: 8px;

  margin-top: 10px;
  margin-right: 20px;
}

.secondStatsContainer {
  text-align: start;
  padding: 10px 30px 20px 30px;
  position: relative;
  color: #fff;

  border-radius: 8px;

  margin-top: 10px;
  margin-left: 20px;
}

.thirdStatsContainer {
  text-align: end;
  padding: 10px 30px 20px 30px;
  position: relative;
  color: #fff;

  border-radius: 8px;

  margin-top: 30px;
  margin-right: 20px;
}

.fourthStatsContainer {
  text-align: start;
  padding: 10px 30px 20px 30px;
  position: relative;
  color: #fff;

  border-radius: 8px;

  margin-top: 30px;
  margin-left: 0px;
}

.statsImg {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.modal_about_section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.statusVideoContainer {
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  background-size: cover;
  background-image: url(https://images.domaincer.com/images/about_company_space.jpg);
}
.playIcon {
  font-size: 50px !important;
  color: #fff !important;
  cursor: pointer;
  margin-top: 300px;
}
.statsFirstRow {
  display: flex;
  padding-left: 150px;
}
.circleValubleStats__about {
  width: 70px;
  height: 70px;
  border: 30px solid #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  margin-bottom: -60px;
}
.telegramImgValubleStats {
  position: absolute;
  top: 0;
  margin-top: 70px;
}
.telegramImg {
  width: 120px;
  /* height: 150px; */
}
.floatingVerticalIcon {
  animation-name: floatingVertical;
  animation-duration: 2.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.about_youtubeCloseButton {
  font-weight: bold !important;
  cursor: pointer;
  font-size: 40px !important;
  color: rgb(255, 255, 255) !important;
}

.caseModalContainer__about {
  width: 70%;
  height: 70%;
  text-align: end;
}
.youtubeAboutVideo {
  width: 100%;
  height: 100%;
}

@keyframes floatingVertical {
  from {
    transform: translate(0px, 0px);
  }
  65% {
    transform: translate(0px, 15px);
  }
  to {
    transform: translate(0px, 0px);
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .valubleStatsTitle {
    font-size: 42px;
    font-family: Circular-Std-Medium;
    font-weight: 700;
    letter-spacing: -1px;
    color: #fff;
    padding-left: 20px;
  }
  .caseModalContainer__about {
    width: 90%;
    height: 30%;
    text-align: end;
  }
  .statsIcon {
    height: 50px;
    align-self: center;
  }
  .statsStarCount {
    font-size: 30px;
    font-family: Circular-Std-Bold;
    font-weight: 700;
    margin-left: 10px;
  }
  .statsFirstRow {
    display: flex;
    padding-left: 5px;
  }
  .playIcon {
    margin-top: 70px;
  }
  .statusVideoContainer {
    height: 200px;
    margin-top: 30px;
  }
  .valubleStatsContainer {
    background-color: rgb(17, 17, 17);
    padding: 120px 0px 130px 0px;
  }
  .valubleStatsLeftSection {
    padding: 80px 0px 50px 0px;
    width: 100%;
    margin: 0 auto;
  }
  .firstStatsContainer {
    text-align: end;
    padding: 10px 15px 10px 15px;
    position: relative;
    color: #fff;
    border-radius: 8px;
    margin-top: 10px;
    margin-right: 0px;
  }
  .secondStatsContainer {
    text-align: start;
    padding: 10px 15px 10px 15px;
    position: relative;
    color: #fff;
    border-radius: 8px;
    margin-top: 10px;
    margin-left: 0px;
  }
  .thirdStatsContainer {
    text-align: end;
    padding: 10px 15px 10px 15px;
    position: relative;
    color: #fff;
    border-radius: 8px;
    margin-top: 30px;
    margin-right: 0px;
  }
  .fourthStatsContainer {
    text-align: start;
    padding: 10px 15px 10px 15px;
    position: relative;
    color: #fff;
    border-radius: 8px;
    margin-top: 30px;
    margin-left: 0px;
  }

  .statsCount {
    font-size: 30px;
    font-family: Circular-Std-Bold;
    font-weight: 700;
  }

  .statsName {
    font-size: 17px;
    font-family: Circular-Std-Book;
  }
  .telegramImgValubleStats {
    position: absolute;
    top: 0;
    margin-top: 20px;
    margin-left: 20px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .statsFirstRow {
    display: flex;
    padding-left: 70px;
  }
  .valubleStatsTitle {
    font-size: 55px;
    font-family: 'Circular-Std-Medium';
    font-weight: 700;
    letter-spacing: -1px;
    color: rgb(97, 97, 97);
    padding-left: 120px;
    line-height: 1.2;
    margin-bottom: 10px;
    width: 420px;
  }
}
