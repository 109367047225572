.aboutusBannerRightSection {
  padding: 80px 0px 0px 0px;
  transform: translate3d(0px, 100px, 0px);
  animation: 0.9s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 normal forwards running
    slideup;
}
.aboutusBannerTitle {
  font-family: Circular-Std-Medium;
  font-weight: 700;
  letter-spacing: -1px;
  font-size: 55px;
  color: rgb(0, 0, 0);
  line-height: 1.1;
  margin-bottom: 30px;
}
.aboutusBannerSubTitle {
  font-family: Circular-Std-Book;
  font-size: 17px;
  color: rgb(97, 97, 97);
  line-height: 1.4;
  margin-bottom: 10px;
}
.aboutusTabsSection {
  padding: 30px 0px 100px 0px;
}
.aboutusTabsContaincer {
  background-color: #000;
  border-radius: 5px;
  padding: 5px 1px;
  width: fit-content;
}
.tabsBtn {
  text-transform: initial !important;
  font-size: 16px !important;
  font-family: Circular-Std-Book !important;
  color: #fff !important;
  padding: 2px 15px !important;
  margin: 0px 5px !important;
  transition: all 500ms ease !important;
}
.overViewSection {
  height: 300px;
}

.aboutusImg {
  width: 645px;
  margin-top: 30px;
}

.MTabsContainer {
  background-color: rgb(17, 17, 17);
  border-radius: 5px;
  padding: 5px 1px;
  min-height: inherit !important;
}
.MTabBtn {
  text-transform: initial !important;
  font-size: 16px !important;
  font-family: Circular-Std-Book !important;
  color: #fff !important;
  padding: 2px 15px !important;
  margin: 0px 5px !important;
  min-width: fit-content !important;
  min-height: inherit !important;
  z-index: 999;
}
.PrivateTabIndicator-colorPrimary-2 {
  background-color: #0077ff !important;
}
.PrivateTabIndicator-root-1 {
  height: 30px !important;
  border-radius: 5px;
}
.MTabBtn > .MuiTab-wrapper {
  z-index: 999;
}


@media only screen and (min-width: 321px) and (max-width: 768px) {
  .aboutusImg {
    margin-left: 0px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
  }
  .aboutusBannerTitle {
    font-family: Circular-Std-Medium;
    font-size: 42px;
    letter-spacing: -1px;
    color: rgb(0, 0, 0);
    line-height: 1.1;
    margin-bottom: 30px;
  }
  .aboutusBannerRightSection {
    padding: 20px 10px 0px 10px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .aboutusImg {
    width: 480px;
    margin-top: 80px;
  }
}
