.announcementSection {
  display: flex;
  background-color: #0077ff;
  align-items: center;
  justify-content: center;
  padding: 10px 15px 10px 15px;
}
.sticky_announcementSection {
  display: flex;
  background-color: #0077ff;
  align-items: center;
  justify-content: center;
  padding: 10px 15px 10px 15px;
  z-index: 1000;
  position: fixed;
  width: 100%;
}
.announcementText {
  color: #ffffff;
  font-family: Circular-Std-Medium;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  letter-spacing: 1px;
  text-align: center;
  line-height: 1.4;
}

.announcementBtn {
  font-family: Circular-Std-Book !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  background-color: #fff !important;
  color: #0077ff !important;
  text-transform: capitalize !important;
  margin-left: 10px !important;
  padding: 4px 15px !important;
}
.sticky_announcementBtn {
  font-family: Circular-Std-Book !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  background-color: #fff !important;
  color: #0077ff !important;
  text-transform: capitalize !important;
  margin-left: 10px !important;
  padding: 2px 15px !important;
}
.announcementLink {
  font-family: Circular-Std-Medium;
  margin-left: 5px;
  color: #0077ff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}
.learnMore {
  padding-bottom: 3px;
}
.learnMoreArrow {
  margin-bottom: -5px;
  font-size: 18px !important;
}
.domaincer__navbar {
  background-color: #fff !important;
  box-shadow: none !important;
  color: #000 !important;
  display: block !important;
}

.domaincerLogo__container:hover {
  position: absolute;
  opacity: 0;
}

.domaincer__logo {
  height: 63px;
  width: 238px;
}

.dropdown-toggle {
  font-family: Circular-Std-Book !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: rgb(0, 0, 0) !important;
  align-items: center !important;
  font-size: 14px !important;
  letter-spacing: 1px !important;
}

.about__menu {
  color: rgb(0, 0, 0) !important;
  min-width: 100px !important;
  margin-bottom: 0px !important;
  justify-content: center !important;
}
.aboutMenuText {
  font-family: Circular-Std-Book !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: 1px !important;
  text-align: center !important;
  align-items: center !important;
  color: rgb(0, 0, 0) !important;
  margin-top: 4px;
}

.icon__button {
  padding: 0px !important;
  align-items: center;
}
.header__dropDownIcon {
  font-size: 18px !important;
  color: rgb(0, 0, 0) !important;
  padding-top: 2px;
}

.domaincer__NavbarDropdown {
  display: flex;
  background-color: #000;
  justify-content: center;
}

.strategy__Dropdown {
  padding: 20px;
  display: flex !important;
  justify-content: space-evenly;
}

.humanResource__Dropdown {
  padding: 20px;
  display: flex !important;
  justify-content: space-evenly;
}

.dropdown__menu {
  display: flex;
  align-items: center;
}

.dropdownMenu__content {
  display: flex;
  flex-direction: column;
}

.dropdown__title {
  font-family: Circular-Std-Book;
  font-weight: 600;
  text-align: left;
  margin-left: 10px;
  color: #fff;
  font-size: 16px;
}

.dropdown__subTitle {
  font-family: Circular-Std-Book;
  font-weight: 500;
  margin-left: 10px;
  margin-bottom: 0px;
  color: rgb(153, 153, 153);
}
.mobile_navbar_display {
  display: none;
}
.menuSectionMobile {
  display: none;
}

/* interactive cursor */
.cursor-inner-link {
  z-index: 10000001;
  width: 20px;
  height: 20px;
  background-color: #616161;
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  opacity: 0.8;
  top: -50px;
}

.subMenuMobileLink {
  color: rgb(102, 102, 102);
  font-size: 15px !important;
  font-family: Circular-Std-Book !important;
  padding-bottom: 5px;
  margin-top: 0px !important;
}

.contactUs__button {
  font-family: Circular-Std-Book !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  background-color: #0077ff !important;
  color: #fff !important;
  text-transform: capitalize !important;
  margin-left: 30px !important;
}

.mover {
  width: 4px;
  height: 30px;
  background: #fff;
  animation: move 5s infinite ease-in-out;
}
.join_mover {
  width: 4px;
  height: 30px;
  background: #0077ff;
  animation: move 5s infinite ease-in-out;
}
.area {
  width: 100px;
  height: 40px;
  margin: 0 auto;
}

@keyframes move {
  20%,
  100% {
    opacity: 0;
    transform: translate(100px, 0);
  }
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
}

/* mobile */
/* ------------------------------------------------------------- */
@media only screen and (min-width: 321px) and (max-width: 768px) {
  .announcementSection_mobile {
    display: flex;
    background-color: #0077ff;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    /* position: fixed; */
    z-index: 99999;
  }
  .sticky_announcementSection_mobile {
    display: block;
    background-color: #0077ff;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    z-index: 1000;
    position: fixed;
    width: 100%;
  }
  .announcementBtn_mobile {
    font-family: Circular-Std-Book !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    background-color: #fff !important;
    color: #0077ff !important;
    text-transform: capitalize !important;
    margin-left: 35% !important;
    margin-top: 3% !important;
    padding: 2px 15px !important;
  }
  .announcementText_mobile {
    color: #ffffff;
    font-family: Circular-Std-Medium;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
    letter-spacing: 1px;
    text-align: center;
    line-height: 1.4;
  }
  .mobile_navbar_display {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 99999;
    top: 0px;
  }
  .sticky_mobileHeader {
    position: relative;
    top: 0px;
  }
  .menuSectionMobile {
    height: 100%;
    background-color: rgb(0, 0, 0);
    display: block;
    position: fixed;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    margin-top: -3px;
  }

  .m_navbar_logo_bar {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    align-items: center;
    background-color: #000;
  }

  .domaincer_m_logo {
    width: 50px;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .menubar {
    font-size: 30px !important;
    color: #fff;
  }
  .domaincer__navbar {
    background-color: #fff !important;
    box-shadow: none !important;
    color: #000 !important;
    display: none !important;
  }
  .mobileMenuContainer {
    display: flex;
    justify-content: space-between;
  }
  .mobileMenuHeading {
    font-family: Circular-Std-Bold;
    font-size: 16px;
    color: #fff;
    margin-bottom: 0px;
  }
  .MobileMenuList > .MuiListItem-root {
    color: rgb(102, 102, 102);
    font-size: 15px !important;
    font-family: Circular-Std-Book !important;
    padding-bottom: 5px;
    margin-top: 0px !important;
  }
  .contact__us {
    color: #fff !important;
    font-size: 14px !important;
  }
  .contactUs__button {
    font-family: Circular-Std-Book !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    background-color: #0077ff !important;
    color: #fff !important;
    text-transform: capitalize !important;
    margin-left: 0px !important;
  }
  .domaincer__NavbarDropdown {
    display: none;
  }
}
/* mobile */

/* Ipad Pro*/
@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .tabpadiss {
    padding-left: 0px !important;
  }
  .about__menu {
    color: rgb(0, 0, 0) !important;
    min-width: 100px !important;
    margin-bottom: 0px !important;
    justify-content: center !important;
    min-width: fit-content !important;
  }
  .menutabwidth {
    min-width: fit-content;
    padding: 5px 10px !important;
  }
  .contactUs__button {
    font-family: Circular-Std-Book !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    background-color: #0077ff !important;
    color: #fff !important;
    text-transform: capitalize !important;
    margin-left: 0px !important;
    white-space: nowrap;
  }
}
/* Ipad Pro*/
@media screen and (min-width: 600px) and (max-width: 900px) {
  .announcementBtn_mobile {
    font-family: Circular-Std-Book !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    background-color: #fff !important;
    color: #0077ff !important;
    text-transform: capitalize !important;
    margin-left: 41% !important;
    margin-top: 3% !important;
    padding: 2px 15px !important;
  }
}
