.ourProcessMainHeading {
  font-family: "Circular-Std-Medium";
  font-size: 105px;
  font-weight: 800;
  color: rgb(97, 97, 97);
}
.ourProcessTitle {
  font-size: 60px;
  font-family: Circular-Std-Bold;
  text-align: center;
  color: rgb(97, 97, 97);
  margin-bottom: 10px;
}
.ourProcessSubTitle {
  font-size: 18px;
  color: #fff;
  font-family: Circular-Std-Book;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  line-height: 1.5;
}
.ourProcessCardContainer {
  text-align: center;
  padding: 20px 0px;
  border-radius: 8px;
  /* box-shadow: 0 7px 25px 0 rgba(255, 255, 255, 0.3), 0 0px 0px 0 rgba(255, 255, 255, 0.19) */
}
.ourProcessCardContainer:hover {
  box-shadow: 0 7px 25px 0 rgba(255, 255, 255, 0.3),
    0 0px 0px 0 rgba(255, 255, 255, 0.19);
  transform: translate3d(0, -20px, 0);
  transition: transform 0.3s;
}
.ourProcessCardIcon {
  margin-bottom: 20px;
}
.ourProcessCardheading {
  font-size: 25px;
  font-family: Circular-Std-Book;
  color: #fff;
  text-align: center;
}
.ourProcessCardDetail {
  font-size: 18px;
  font-family: Circular-Std-Book;
  color: #fff;
  text-align: center;
  line-height: 1.5;
  margin: 20px 0px;
}

.proccessImg {
  width: 100px;
  border-radius: 50%;
  /* border: 7px solid #0077FF; */
}
.processLeftHeading {
  font-size: 25px;
  font-family: Circular-Std-Book;
  color: #fff;
  margin-right: 20px;
  margin-bottom: 7px;
  /* width: 100px; */
  text-align: right;
}

.processLeftPara {
  font-size: 15px;
  font-family: Circular-Std-Book;
  color: rgb(97, 97, 97);
  margin-right: 20px;
  margin-bottom: 5px;
  text-align: right;
  width: 230px;
}
.processRightHeading {
  font-size: 25px;
  font-family: Circular-Std-Book;
  color: #fff;
  margin-left: 20px;
  margin-bottom: 7px;
  /* width: 100px; */
  text-align: left;
}
.processRightPara {
  font-size: 15px;
  font-family: Circular-Std-Book;
  color: rgb(97, 97, 97);
  margin-left: 20px;
  margin-bottom: 5px;
  text-align: left;
  width: 230px;
}

.dashArrow {
  color: #fff;
}
/* .dashArrow {
      width: 500px;
      height: 100px;
      border: solid 5px #fff;
      border-color: transparent transparent #fff transparent;
      border-radius: 0 0 240px 50%/60px;
    } */

.ourProcessContainer {
  background-color: rgb(17, 17, 17);
  padding: 50px 0px 0px 0px;
  /* background-color: rgb(33, 36, 38); */
  color: rgb(248, 248, 248);
  /* width: 100vw; */
  min-height: 100vh;
  position: relative;
  background-size: cover;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.fpFnAT {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: end;
  justify-content: flex-end;
  width: 50%;
  padding: 240px 0px 150px 200px;
}
.ffCCAC {
  width: 50%;
  margin-left: 30px;
  display: block;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 100px 200px 150px 0px;
  position: sticky;
  top: 10px;
  height: 50%;
}
.ijZHsr {
  margin-bottom: 100px;
  margin-top: 250px;
}
.cKSIpx_lang {
  font-size: 50px;
  font-family: Circular-Std-Bold;
  letter-spacing: -3.15px;
  line-height: 55px;
  white-space: pre-line;
  opacity: 0.9;
}
.cAPXpg {
  font-size: 20px;
  font-family: Circular-Std-Book;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 28px;
  white-space: pre-line;
  max-width: 600px;
  margin-top: 30px;
  margin-bottom: 50px;
}
.rrFdEf {
  /* position: sticky;
    top: 10px; */
  margin-top: 10px;
  /* bottom: 100px; */
  /* width: 100%;
    height: 100px; */
}
.dLWWCx {
  position: sticky;
  top: 180px;
  width: 350px;
  height: 550px;
  box-shadow: rgba(176, 176, 178, 0.16) 0px -5px 6px 0px inset,
    rgba(0, 0, 0, 0.24) 4px 6px 11px 1px inset;
  border-radius: 46px;
  padding: 16px 14px;
  margin: 0 auto;
}
.dBUlCg {
  background-color: rgb(39, 43, 45);
  border-radius: 36px;
  height: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  overflow: hidden;
}
.hZWWsa_flutter {
  width: 160px;
  transform: translate3d(0%, 0px, 0px);
  margin: 150px 0px 20px;
}
.hZWWsa_reactnative {
  width: 200px;
  transform: translate3d(0%, 0px, 0px);
  margin: 150px 0px 20px;
}
.hZWWsa_native {
  width: 160px;
  transform: translate3d(0%, 0px, 0px);
  margin: 150px 0px 20px;
}
.ourProcessMobileContainer {
  display: none;
}
.staticImgContainer {
  background-image: url("https://images.domaincer.com/images/Static.png");
  background-size: 100% 100%;
  height: 650px;
  background-repeat: no-repeat;
}
.static_img {
  padding: 20px 20px 20px 150px;
  width: 350px;
  height: 550px;
}

.languagesMobileTabs {
  margin-bottom: 50px;
  margin-left: 20px;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .languageContainer_mobileapp {
    background-color: rgb(17, 17, 17);
    color: rgb(248, 248, 248);
    padding: 70px 0px 50px 0px;
    min-height: auto;
    position: relative;
    background-size: cover;
    display: block;
    justify-content: center;
  }
  .ourProcessTitle_strategy {
    font-size: 50px;
    font-family: Circular-Std-Bold;
    text-align: center;
    color: rgb(97, 97, 97);
    padding-top: 70px;
    padding-bottom: 20px;
  }
  .dLWWCx_strategy {
    position: relative;
    top: 0px;
    width: 250px;
    height: 450px;
    box-shadow: rgba(176, 176, 178, 0.16) 0px -5px 6px 0px inset,
      rgba(0, 0, 0, 0.24) 4px 6px 11px 1px inset;
    border-radius: 46px;
    padding: 16px 14px;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .ijZHsr_strategy {
    margin: 0px;
  }
  .cKSIpx_strategy {
    font-size: 34px;
    font-family: Circular-Std-Bold;
    letter-spacing: -1px;
    line-height: 42px;
    white-space: pre-line;
    opacity: 0.9;
    text-align: center;
  }
  .cAPXpg_strategy {
    font-size: 17px;
    font-family: Circular-Std-Book;
    letter-spacing: 0px;
    line-height: 28px;
    white-space: pre-line;
    max-width: 600px;
    text-align: center;
    margin: 20px;
  }

  .staticImgContainer {
    background-image: url("https://images.domaincer.com/images/Static.png");
    background-size: 100% 100%;
    height: 400px;
    width: 70%;
    margin: 100px auto;
    background-repeat: no-repeat;
  }
  .hZWWsa_flutter {
    width: 90px;
    transform: translate3d(0%, 0px, 0px);
    margin: 100px 0px 20px;
  }
  .hZWWsa_reactnative {
    width: 110px;
    transform: translate3d(0%, 0px, 0px);
    margin: 105px 0px 20px;
  }
  .hZWWsa_native {
    width: 90px;
    transform: translate3d(0%, 0px, 0px);
    margin: 100px 0px 20px;
  }
}

@media only screen and (min-width: 1500px) {
  .staticImgContainer {
    background-image: url("https://images.domaincer.com/images/Static.png");
    background-size: 85% 100%;
    height: 800px;
    background-repeat: no-repeat;
  }
  .hZWWsa_flutter {
    width: 160px;
    transform: translate3d(0%, 0px, 0px);
    margin: 200px 0px 20px;
  }
  .hZWWsa_reactnative {
    width: 200px;
    transform: translate3d(0%, 0px, 0px);
    margin: 200px 0px 20px;
  }
  .hZWWsa_native {
    width: 160px;
    transform: translate3d(0%, 0px, 0px);
    margin: 200px 0px 20px;
  }
}
