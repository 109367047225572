.domaincer__loader {
  margin-top: 20vw;
  margin-left: 30vw;
}

@media only screen and (max-width: 767px) {
  .domaincer__loader {
    margin: 50px;
    margin-top: 40vh;
    width: 75%;
  }
}
