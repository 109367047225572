body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Circular-Std-Black";
  src: local("Circular-Std"),
    url(./fonts/Circular-Std-Black.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Circular-Std-Bold";
  src: local("Circular-Std"),
    url(./fonts//Circular-Std-Bold.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Circular-Std-Book";
  src: local("Circular-Std"),
    url(./fonts/Circular-Std-Book.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Circular-Std-Medium";
  src: local("Circular-Std"),
    url(./fonts/Circular-Std-Medium.ttf) format("truetype");
  font-display: swap;
}

p,
h1,
h2,
h3 {
  margin: 0;
  font-weight: normal;
}

/* Link */
a {
  text-decoration: none;
  color: #0077ff;
}

/* HoverEffect */
.hoverLine:after {
  display: block;
  content: "";
  border-bottom: solid 3px #0077ff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  cursor: pointer;
}
.hoverLine:hover:after {
  transform: scaleX(1);
  cursor: pointer;
}

/* Modal dark Effect */
.MuiBackdrop-root {
  background-color: rgb(0, 0, 0) !important;
}

/* Interactive Cursor */

.cursor-outer {
  width: 30px;
  height: 30px;
  border: 1px solid #616161;
  border-radius: 50%;
  position: absolute;
  transition-duration: 100ms;
  transition-timing-function: ease-out;
  z-index: 10000001;
  pointer-events: none;
  top: -50px;
}
.cursor-inner {
  z-index: 10000001;
  width: 10px;
  height: 10px;
  background-color: rgb(97, 97, 97);
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  opacity: 0.8;
  top: -10px;
}

/* Inner Active Menu */

.jss1 {
  height: 32px !important;
  border-radius: 5px;
  bottom: 0;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.jss2 {
  background-color: #0077ff !important;
}

/* Inner Active Menu */

@media only screen and (min-width: 321px) and (max-width: 768px) {
  /* Interactive Cursor */

  .cursor-outer {
    width: 30px;
    height: 30px;
    border: 1px solid #616161;
    border-radius: 50%;
    position: absolute;
    transition-duration: 100ms;
    transition-timing-function: ease-out;
    z-index: 10000001;
    pointer-events: none;
    top: -50px;
    display: none;
  }
  .cursor-inner {
    z-index: 10000001;
    width: 7px;
    height: 7px;
    background-color: #616161;
    border-radius: 50%;
    position: absolute;
    pointer-events: none;
    opacity: 0.8;
    top: -10px;
    display: none;
  }
  .mobileMarginTop {
    margin-top: 70px;
  }
  .subServiceTabLeftArrowHome {
    position: absolute;
    left: 0;
    padding: 20px 10px;
    font-size: 20px !important;
    color: #0077ff;
  }
  .subServiceTabRightArrowHome {
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px 10px;
    font-size: 20px !important;
    color: #0077ff;
  }
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 20px #06141f; */
  background-color: transparent;
  border-radius: 10px;
}


.tf-v1-sidetab-button{
  background-color:#0477ff !important;
}
.tf-v1-sidetab {
  bottom:0 !important;
  top:30% !important;
}

.zsiq_custommain{
  display: none !important;
}


.ButtonsWrapper-sc-__sc-1ckh2u2-2{
  display: none!important;
}

.LeftButtonsWrapper-sc-__sc-1ckh2u2-5P{
  display: none !important;
}