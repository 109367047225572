/* banner section */

.serviceSection_blogSection {
  padding: 100px 0px 100px 0px;
  background-color: rgb(17, 17, 17);
}

.serviceSection_blogHeaderContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 50px;
  margin-bottom: 20px;
  align-items: flex-end;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(97, 97, 97, 0.12);
}
.serviceSection_blogTitleRow {
  display: flex;
  justify-content: center;
}
.serviceSection_blogHeading {
  font-size: 48px;
  font-weight: 700;
  font-family: Circular-Std-Medium;
  color: rgb(97, 97, 97);
  letter-spacing: -1px;
}
.serviceSection_blogViewAll {
  font-size: 22px;
  font-family: Circular-Std-Medium;
  color: #0077ff;
  letter-spacing: -1px;
}
.serviceSection_blogSectionImg {
  width: 100%;
  height: 230px;
  border-radius: 5px 5px 0px 0px;
}

.serviceSection_rightCaroselIcon {
  color: rgb(97, 97, 97);
  border-radius: 50%;
  border: 2px solid rgb(97, 97, 97);
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 3px 0px, rgb(0 0 0 / 5%) 0px 2px 6px 0px;
}
.serviceSection_leftCaroselIcon {
  color: rgb(97, 97, 97);
  border-radius: 50%;
  border: 2px solid rgb(97, 97, 97);
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 3px 0px, rgb(0 0 0 / 5%) 0px 2px 6px 0px;
}

.serviceSection_blogSectionContainer {
  justify-content: center;
  display: flex;
  margin: 20px;
}

.serviceSection_blogMiddleSection {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  scroll-behavior: smooth;
}

.serviceSection_blogSectionCardContainer {
  border: 1px solid rgba(97, 97, 97, 0.12);
  border-radius: 8px;
  min-width: 350px;
  margin: 5px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 3px 0px, rgb(0 0 0 / 5%) 0px 2px 6px 0px;
}

/* ------------------------------
------------------------------ */

.BSSliderImgContainer {
  text-align: center;
  align-self: flex-end;
  margin-bottom: -50px;
}
.brandStrategySliderImg {
  margin-top: 20px;
  width: 450px;
}
.brandservice_blogTitleRow {
  display: flex;
  justify-content: center;
}

.BSSliderRightSection {
  margin: 50px 0px 0px 0px;
}
.sliderHeading {
  font-family: "Circular-Std-Medium";
  font-size: 55px;
  font-weight: 700;
  letter-spacing: -1px;
  margin-bottom: 30px;
}

.sliderSubHeading {
  font-family: Circular-Std-Book;
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 1.5;
  color: rgb(97, 97, 97);
}
.serviceTabsSection {
  padding: 30px 0px 60px 0px;
}
.MTabsWidth {
  width: fit-content;
  box-shadow: none !important;
}

/* how service will help you */
.particalsSection_strategy {
  position: absolute;
  width: 100%;
  height: 570px;
}
.BSHelpYouRightSection_strategy {
  background-color: rgb(17, 17, 17);
  padding: 150px;
}
.BSHelpYouHeading_strategy {
  font-size: 55px;
  font-family: "Circular-Std-Medium";
  font-weight: 700;
  letter-spacing: -1px;
  color: rgb(97, 97, 97);
  max-width: 800px;
  margin: 0 auto;
}
.BSHelpYouPara_strategy {
  font-family: Circular-Std-Book;
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 1.5;
  color: #fff;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
}

/* service content section */

.service_subheading_strategy {
  font-size: 55px;
  font-family: "Circular-Std-Medium";
  font-weight: 700;
  letter-spacing: -1px;
  color: #222d38;
  text-align: center;
}
.service_subtext_strategy {
  color: #617080;
  font-size: 22px;
  font-family: Circular-Std-Book;
  text-align: center;
  margin-top: 5px;
  width: 42%;
  margin: 10px auto;
}
.subServiceTabSection_strategy {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  scroll-behavior: smooth;
}
.subServiceContaincer_strategy {
  display: flex;
  padding: 15px 50px;
  cursor: pointer;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.subServiceImg_strategy {
  width: 45px;
  height: 45px;
  margin-right: 15px;
}
.subServiceTitle_strategy {
  font-family: Circular-Std-Book;
  color: #222d38;
  font-size: 15px;
  line-height: 22px;
}

.subServiceSubTitle_strategy {
  font-family: Circular-Std-Book;
  color: #617080;
  font-size: 15px;
  line-height: 22px;
}
.subServiceParaContainer_strategy {
  background-color: rgba(247, 249, 250, 0.6);
  cursor: pointer;
  margin: 0 auto;
  padding-bottom: 50px;
}
.subServiceParaContainer_strategy:hover {
  margin: 0 auto;
  background-color: rgba(1, 119, 255, 0.06);
  opacity: 1;
}

.subServiceFixedSection_strategy {
  width: 925px;
  position: relative;
}

.glowEffectBrand_strategy {
  position: absolute;
  left: 4%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}
.serviceContent_strategy {
  padding: 50px 100px;
  display: flex;
}
.subServicePara_strategy {
  margin-bottom: 8px;
  color: #222d38;
  font-size: 18px;
  font-family: Circular-Std-Book;
  padding: 0px 130px;
  text-align: center;
}

.glowEffectDigital_strategy {
  position: absolute;
  left: 32%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}
.glowEffectWorkFlow_strategy {
  position: absolute;
  left: 58%;
  right: 0;
  width: 520px;
  height: 160px;
  background-image: radial-gradient(
    circle farthest-side at 50% 0%,
    rgba(0, 119, 255, 0.71),
    rgba(31, 174, 150, 0) 60%
  );
  opacity: 0.4;
}

/* Blog section */
.service_blogHeaderContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 50px;
  margin-bottom: 20px;
  align-items: flex-end;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(97, 97, 97);
}
.service_blogHeading {
  font-size: 42px;
  font-family: Circular-Std-Medium;
  color: rgb(97, 97, 97);
  font-weight: 700;
}
.service_blogViewAll {
  font-size: 22px;
  font-family: Circular-Std-Book;
  color: #0077ff;
}

.leftCaroselIcon_strategy {
  color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.blogMiddleSection_strategy {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  width: 1180px;
  scroll-behavior: smooth;
}
.blogSectionCardContainer_strategy {
  border: 1px solid rgb(97, 97, 97);
  border-radius: 8px;
  min-width: 350px;
  margin: 20px 15px;
}
.blogSectionImg_strategy {
  width: 100%;
  height: 230px;
  border-radius: 5px 5px 0px 0px;
}
.blogSectionHeading_strategy {
  font-size: 28px;
  font-weight: 700;
  font-family: Circular-Std-Medium;
  margin-bottom: 10px;
  margin-top: 20px;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  color: #fff;
}
.blogSectionHeading_strategy::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -7px;
  z-index: 100;
  width: 200px;
  height: 1px;
  background: rgb(97, 97, 97);
}
.blogSectionSubText_strategy {
  font-size: 16px;
  font-family: Circular-Std-Book;
  color: #fff;
  line-height: 1.7;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
}
.blogSectionLink_strategy {
  font-size: 17px;
  font-family: Circular-Std-Medium;
  color: rgb(0, 119, 255);
  /* color: rgb(97, 97, 97); */
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.rightCaroselIcon_strategy {
  color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
}

/* Why choose us  */
.whyChooseUsSection_strategy {
  padding: 100px 0px;
}
.whyChooseUsTitle_strategy {
  font-size: 55px;
  font-family: "Circular-Std-Medium";
  font-weight: 700;
  letter-spacing: -1px;
  text-align: center;
  margin-bottom: 50px;
}
.whyChooseColumnUpContainer_strategy {
  margin-bottom: 50px;
  text-align: end;
  margin-top: 50px;
}
.chooseTitle_strategy {
  font-size: 22px;
  font-family: Circular-Std-Book;
  font-weight: 600;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.8);
}

.choosePara_strategy {
  font-size: 18px;
  font-family: Circular-Std-Book;
  font-weight: 500;
  line-height: 1.5;
  color: rgb(97, 97, 97);
}
.whyChooseColumnDownContainer_strategy {
  text-align: end;
}
.chooseUsImageRow_strategy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chooseUsMiddleImg_strategy {
  width: 70%;
  margin-bottom: 15%;
}
.whyChooseColumn3UpContainer_strategy {
  margin-bottom: 50px;
  margin-top: 50px;
  text-align: left;
}
.whyChooseColumn3DownContainer_strategy {
  text-align: left;
}

.floatingUpDown {
  animation-name: floatingUpDown;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floatingUpDown {
  from {
    transform: translate(0px, 0px);
  }
  65% {
    transform: translate(0px, 10px);
  }
  to {
    transform: translate(0px, 0px);
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  /* banner section */
  .brandStrategySliderImg {
    margin-top: 70px;
    width: 80%;
    margin-bottom: 70px;
  }
  .sliderHeading {
    font-family: "Circular-Std-Medium";
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -1px;
    margin-bottom: 30px;
  }
  .BSSliderRightSection {
    margin: 30px 0px 30px 0px;
  }
  .MTabsWidth {
    width: inherit;
    box-shadow: none !important;
  }
  .MTabsContainer > .MuiTabs-fixed {
    overflow-x: scroll !important;
  }

  /* how service will help you */
  .BSHelpYouRightSection_strategy {
    background-color: rgb(17, 17, 17);
    padding: 100px 15px;
  }
  .BSHelpYouHeading_strategy {
    font-size: 42px;
    font-family: "Circular-Std-Medium";
    font-weight: 700;
    letter-spacing: -1px;
    color: rgb(97, 97, 97);
    max-width: 800px;
    margin: 0 auto;
  }
  .BSHelpYouPara_strategy {
    font-family: Circular-Std-Book;
    font-size: 18px;
    line-height: 1.5;
    color: #fff;
    max-width: 800px;
    margin: 30px auto;
  }
  .circleBSHelpYou_strategy {
    width: 70px;
    height: 70px;
    border: 30px solid #fff;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    margin-bottom: -60px;
  }

  /* service content section */
  .subServiceFixedSection_strategy {
    width: 925px;
    position: relative;
    height: 250px;
  }
  .service_subheading_strategy {
    font-size: 42px;
    font-family: "Circular-Std-Medium";
    font-weight: 700;
    letter-spacing: -1px;
    padding: 0px 10px;
  }
  .service_subtext_strategy {
    color: #617080;
    font-size: 22px;
    font-family: Circular-Std-Book;
    text-align: center;
    margin-top: 5px;
    width: 75% !important;
    margin: 10px auto;
  }
  .subServiceTabSection_strategy {
    overflow: scroll;
    display: -webkit-box !important;
    width: 100% !important;
    margin: 0 !important;
    scroll-behavior: smooth;
  }

  .glowEffectBrand_strategy {
    position: absolute;
    left: 6%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .serviceContent_strategy {
    padding: 20px 10px !important;
    display: flex !important;
  }
  .serviceContentTwo_strategy {
    padding: 20px 10px 45px 10px;
    display: flex;
  }
  .serviceContentThree_strategy {
    padding: 20px 10px 45px 10px;
    display: flex;
  }
  .serviceContentOne_strategy {
    padding: 20px 10px 50px 10px;
    display: flex;
  }
  .subServicePara_strategy {
    margin-bottom: 8px;
    color: #222d38;
    font-size: 18px;
    font-family: Circular-Std-Book;
    padding: 0px 10px !important;
    text-align: left;
  }

  .glowEffectDigital_strategy {
    position: absolute;
    left: 30%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .glowEffectWorkFlow_strategy {
    position: absolute;
    left: 54%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
    display: none;
  }
  .subServiceContaincer_strategy {
    display: flex;
    padding: 10px;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    margin-bottom: 15px;
    border-color: #0077ff;
  }

  /* Bolg section */
  .blogMiddleSection_strategy {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    width: 100%;
    scroll-behavior: smooth;
  }
  .leftCaroselIcon_strategy {
    color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 3px;
    cursor: pointer;
    margin-right: 5px;
  }
  .rightCaroselIcon_strategy {
    color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 3px;
    cursor: pointer;
    margin-left: 5px;
  }
  .blogSectionCardContainer_strategy {
    border: 1px solid rgb(97, 97, 97);
    border-radius: 8px;
    min-width: 250px;
    margin: 10px;
  }
  .blogSectionImg_strategy {
    width: 100%;
    height: 175px;
    border-radius: 5px 5px 0px 0px;
  }

  /* Why choose us */
  .whyChooseColumnUpContainer_strategy {
    margin-bottom: 50px;
    text-align: center;
    margin-top: 50px;
  }
  .whyChooseColumnDownContainer_strategy {
    text-align: center;
  }

  .whyChooseColumn3UpContainer_strategy {
    margin-bottom: 50px;
    margin-top: 50px;
    text-align: center;
  }
  .whyChooseColumn3DownContainer_strategy {
    text-align: center;
  }
  .chooseUsMiddleImg_strategy {
    width: 70%;
    margin-bottom: 0%;
    margin-top: 20%;
  }
  .whyChooseUsTitle_strategy {
    font-size: 42px;
    font-family: "Circular-Std-Medium";
    font-weight: 700;
    letter-spacing: -1px;
    text-align: center;
    margin-bottom: 0px;
  }
}

/* Tablet */
@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .glowEffectBrand_strategy {
    position: absolute;
    left: -8%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
  }
  .glowEffectDigital_strategy {
    position: absolute;
    left: 27%;
    right: 0;
    width: 520px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 60%
    );
    opacity: 0.4;
  }
  .glowEffectWorkFlow_strategy {
    position: absolute;
    left: 68%;
    right: 0;
    width: 320px;
    height: 160px;
    background-image: radial-gradient(
      circle farthest-side at 50% 0%,
      rgba(0, 119, 255, 0.71),
      rgba(31, 174, 150, 0) 82%
    );
    opacity: 0.4;
  }
}
