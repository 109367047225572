.carouselWrapper {
    position: relative;
    overflow: hidden;
    margin: auto;
    box-sizing: border-box;
  }
  
  .imgWrap {
    position: "absolute";
    top: 0;
    left: 0;
  }
  .imgWrap:hover {
    cursor: pointer;
  }
  
  li {
    list-style-type: none;
  }
  .dn {
    display: none;
  }
  
  .serviceMobilev2_headingContainer{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
  }
  .playVideoContainer{
    display: flex;
    position: relative;
    align-items: center;
  }
  .blob {
    background: white;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    height: 40px;
    width: 40px;
    transform: scale(-1);
    animation: pulse-black 2s infinite;
  }
  
  .playVideoStrIcon {
    font-size: 51px !important;
    position: absolute;
    left: -5px;
  }
  
  .playVideoDesIcon {
    font-size: 51px !important;
    position: absolute;
    left: -5px;
  }
  
  .playVideoDevIcon {
    font-size: 51px !important;
    position: absolute;
    left: -5px;
  }
  .playVideoMarIcon {
    font-size: 51px !important;
    position: absolute;
    left: -5px;
  }
  
  @keyframes pulse-black {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
  .playVideoText {
    color: #222d38;
    font-size: 18px;
    font-family: Circular-Std-Book;
    margin-left: 10px;
  }
  
  .serviceMobilev2_heading {
    font-size: 48px;
    font-family: Circular-Std-Medium;
    font-weight: 700;
    letter-spacing: -1px;
    color: #222d38;
    margin-bottom: 20px;
    animation: servicev2heading 0.7s;
    margin-right: 20px;
  }
  .serviceMobilev2_subtext {
    color: #617080;
    font-size: 18px;
    font-family: Circular-Std-Book;
    margin-top: 5px;
    animation: servicev2subtext 0.9s;
  }
  @keyframes servicev2heading {
    0% {
      opacity: 0;
      transform: translate3d(0px, -100px, 0px);
    }
    100% {
      opacity: 1;
      transform: translate3d(0px, 0px, 0px);
    }
  }
  
  @keyframes servicev2subtext {
    0% {
      opacity: 0;
      transform: translate3d(0px, -70px, 0px);
    }
    100% {
      opacity: 1;
      transform: translate3d(0px, 0px, 0px);
    }
  }
  
  .dotsContainer {
    position: absolute;
    right: 140px;
  }
  
  .FirstDot {
    width: 8px;
    border-radius: 50%;
    height: 8px;
    background-color: #000;
  }
  
  .SecondDot {
    width: 8px;
    border-radius: 50%;
    height: 8px;
    background-color: #000;
  }
  .ThirdDot {
    width: 8px;
    border-radius: 50%;
    height: 8px;
    background-color: #000;
  }
  .FourthDot {
    width: 8px;
    border-radius: 50%;
    height: 8px;
    background-color: #000;
  }
  
  .carouselWrapper{
    margin-left: -50px;
  }

  .cursorContaincer{
    width: 75px;
    height: 75px;
    background-color: rgba(17,17,17,0.8);
    border-radius: 50%;
    text-align: center;
    position: absolute;
    transition-duration: 100ms;
    transition-timing-function: ease-out;
    z-index: 10000001;
    pointer-events: none;
    display: flex;
    justify-content: center;
    left: -1100px;
  }
  .cursorContaincer > .cursorText{
    color: #fff;
    font-size: 13px;
    font-family: Circular-Std-Medium;
    font-weight: 300;
    word-wrap: break-word;
    align-self: center;
  }

  .worldClassTextMobile{
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 15px;
    font-family: Circular-Std-Book;
    top: 120px;
    padding: 5px 10px;
    border-radius: 5px;
    left: 120px;
    display: none;
  }
  
  
  @media only screen and (min-width: 321px) and (max-width: 768px) {
  
  .serviceMobilev2_heading {
    font-size: 42px;
    font-family: Circular-Std-Medium;
    font-weight: 700;
    letter-spacing: -1px;
    color: #222d38;
    margin-bottom: 30px;
    text-align: center;
    animation: servicev2heading 0.7s;
    margin-right: 0px;
  }
  .carouselWrapper{
    margin-left: 0px;
  }
  .playVideoStrIcon {
    font-size: 51px !important;
    position: absolute;
    left: 99px;
  }
  .playVideoDesIcon {
    font-size: 51px !important;
    position: absolute;
    left: 99px;
  }
  
  .playVideoDevIcon {
    font-size: 51px !important;
    position: absolute;
    left: 99px;
  }
  .playVideoMarIcon {
    font-size: 51px !important;
    position: absolute;
    left: 99px;
  }
    
    .dotsContainer {
      position: absolute;
      right: 140px;
      transform: rotate(270deg);
      margin-right: 35px;
      margin-bottom: 550px;
    }
    .serviceMobilev2_subtext {
      color: #617080;
      font-size: 18px;
      font-family: Circular-Std-Book;
      margin-top: 5px;
      text-align: center;
      animation: servicev2subtext 0.9s;
      margin-bottom: 50px;
    }
  
  .serviceMobilev2_headingContainer{
    display: block;
    align-items: center;
    margin-bottom: 5px;
    position: relative;
  }
  .playVideoContainer{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
  }
  .worldClassTextMobile{
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 15px;
    font-family: Circular-Std-Book;
    top: 150px;
    padding: 5px 10px;
    border-radius: 5px;
    left: 110px;
    display: block;
    text-align: center;
    width: 120px;
  }
  }
  
  @media only screen and (min-width: 380px) and (max-width: 768px) {
    .playVideoStrIcon {
      font-size: 51px !important;
      position: absolute;
      left: 117px;
    }
    .playVideoDesIcon {
      font-size: 51px !important;
      position: absolute;
      left: 117px;
    }
    
    .playVideoDevIcon {
      font-size: 51px !important;
      position: absolute;
      left: 117px;
    }
    .playVideoMarIcon {
      font-size: 51px !important;
      position: absolute;
      left: 117px;
    }
    .dotsContainer {
      position: absolute;
      right: 160px;
      transform: rotate(270deg);
      margin-right: 35px;
      margin-bottom: 510px;
    }
    .worldClassTextMobile{
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
      font-size: 15px;
      font-family: Circular-Std-Book;
      top: 150px;
      padding: 5px 10px;
      border-radius: 5px;
      left: 120px;
      display: block;
      text-align: center;
      width: 120px;
    }
    .carouselWrapper{
      margin-left: 20px;
    }
  }