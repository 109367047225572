.about_domaincerTitle {
  font-size: 55px;
  font-family: Circular-Std-Medium;
  font-weight: 700;
  letter-spacing: -1px;
  color: rgb(0, 119, 255);
  margin: 0px;
}

.about_videoSectionContainer {
  position: relative;
}

.about_customTextField {
  font-family: Circular-Std-Medium;
  font-size: 36px;
  border: none;
  font-weight: 400;
  outline-width: 0px;
  -webkit-text-stroke: 2px rgb(17, 17, 17);
  color: transparent;
  position: absolute;
  z-index: 1;
}
.about_customTextFieldHover {
  font-family: Circular-Std-Medium;
  font-size: 36px;
  border: none;
  font-weight: 400;
  outline-width: 0px;
  -webkit-text-stroke: 2px rgb(17, 17, 17);
  -webkit-text-fill-color: rgb(17, 17, 17);
  position: absolute;
  z-index: 2;
  opacity: 0.7;
}

.about_hoverVideo {
  width: 200px;
  height: 200px;
  border-radius: 200px;
  position: relative;
  margin-left: 0px;
  margin-top: -80px;
  z-index: 0;
}
.about_hoverVideoShow {
  width: 200px;
  height: 200px;
  border-radius: 200px;
  position: relative;
  margin-left: 0px;
  margin-top: -80px;
  z-index: 0;
}

.specializedInSection {
  margin: 70px 0px 0px 0px;
  /* height: 430px; */
}
.WeSpecailizeHeading {
  font-size: 48px;
  font-family: "Circular-Std-Medium";
  font-weight: 700;
  letter-spacing: -1px;
  color: #222d38;
  text-align: center;
  margin-bottom: 40px;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .about_domaincerTitle {
    font-family: Circular-Std-Medium;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -1px;
    justify-content: center;
    color: rgb(0, 119, 255);
    align-self: center;
    margin: 0px;
  }

  .about_customTextField {
    font-family: Circular-Std-Medium;
    font-size: 20px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 2px rgb(17, 17, 17);
    -webkit-text-fill-color: #fff;
    z-index: 1;
  }
  .about_customTextFieldHover {
    font-family: Circular-Std-Medium;
    font-size: 20px;
    border: none;
    font-weight: 400;
    outline-width: 0px;
    -webkit-text-stroke: 2px rgb(17, 17, 17);
    -webkit-text-fill-color: rgb(17, 17, 17);
    z-index: 1;
  }

  .about_hoverVideo {
    width: 250px;
    height: 250px;
    border-radius: 250px;
    opacity: 0;
    position: absolute;
    margin-left: -120px;
    margin-top: -100px;
  }
  .about_hoverVideoShow {
    width: 250px;
    height: 250px;
    border-radius: 250px;
    opacity: 0.6;
    position: absolute;
    margin-left: -120px;
    margin-top: -100px;
  }

  .specializedInSection {
    margin: 50px 0px 70px 0px;
    /* height: 670px; */
  }
  .WeSpecailizeHeading {
    font-size: 36px;
    font-family: "Circular-Std-Medium";
    font-weight: 700;
    letter-spacing: -1px;
    padding: 0px 10px;
  }
  .specializeInNames {
    color: rgb(61, 61, 61);
    font-size: 18px;
    font-family: Circular-Std-Bold;
    text-align: center;
    margin-top: 15px;
  }
}
