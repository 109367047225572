.sectionone {
  color: rgb(255, 255, 255);
  background: rgb(17, 17, 17);
  padding-top: 100px;
}
.container {
  padding: 0px 100px;
}
.heading1 {
  font-family: Circular-Std-Medium;
  font-size: 55px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  margin: 0px;
}
.subtext {
  font-family: Circular-Std-Medium;
  font-size: 55px;
  font-weight: 700;
  color: rgb(97, 97, 97);
}
.explore {
  font-family: Circular-Std-Medium;
  font-size: 55px;
  font-weight: 800;
  color: rgb(0, 119, 255);
  margin: 0px;
}
.arrowani {
  animation: nudge 3s ease 0.1s infinite alternate;
}
.exploreArrow {
  font-size: 45px !important;
  margin-bottom: -7px;
}

@keyframes nudge {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(7px, 0);
  }

  80% {
    transform: translate(-7px, 0);
  }
}

.carselsectionone {
  padding-top: 100px;
  display: flex;
  overflow: scroll;
  position: relative;
  animation: 20s linear 0s infinite alternate-reverse movingone;
}

@keyframes movingone {
  from {
    margin-left: -30%;
  }
  to {
    margin-left: 0%;
  }
}
.carselsectionotwo {
  padding-top: 30px;
  display: flex;
  overflow: scroll;
  position: relative;
  animation: 80s linear 0s infinite alternate movingtwo;
}

@keyframes movingtwo {
  from {
    margin-left: -70%;
  }
  to {
    margin-left: 0%;
  }
}

.carselsectionothird {
  padding-top: 30px;
  padding-bottom: 100px;
  display: flex;
  overflow: scroll;
  position: relative;
  animation: 80s linear 0s infinite alternate-reverse movingthird;
}

@keyframes movingthird {
  from {
    margin-left: -90%;
  }
  to {
    margin-left: 0%;
  }
}

.cardSection {
  padding: 20px;
  margin: 0px 20px;
  border-radius: 10px;
  min-width: 300px;
  display: flex;
  transition: all 0.15s ease 0s !important;
  transform: scale(1);
  background-color: rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 3px 0px,
    rgba(0, 0, 0, 0.05) 0px 2px 6px 0px;
  cursor: pointer;
  justify-content: flex-start;
  text-align: justify;
}
.cardSection:hover {
  transform: scale(1.05);
}

.smalllogo {
  /* width: 32px;
  height: 32px; */
  border-radius: 50%;
  margin-right: 10px;
}
.cardTitle {
  font-family: Circular-Std-Book;
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.cardSubtitle {
  color: rgba(255, 255, 255, 0.8);
  font-weight: normal;
}
.cardTitleTextBold {
  color: rgb(255, 255, 255);
  font-weight: bold;
}
.modal_section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalContainer {
  background-color: #fff;
  padding: 20px 30px;
  width: 500px;
  border-radius: 5px;
}
.modal_heading {
  font-family: Circular-Std-Book;
  color: #000;
  font-size: 26px;
  font-weight: 800;
}
.modal_para {
  font-family: Circular-Std-Book;
  color: #000;
  font-size: 15px;
  margin: 20px 0px;
}
:focus {
  outline: none;
}
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.closeBtnModal {
  font-weight: bold !important;
  cursor: pointer;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .container {
    padding: 0px 20px;
  }
  .heading1 {
    font-family: Circular-Std-Medium;
    letter-spacing: -1px;
    font-size: 42px;
    font-weight: 700;
    color: rgb(255, 255, 255);
  }
  .subtext {
    font-family: Circular-Std-Medium;
    letter-spacing: -1px;
    font-size: 42px;
    font-weight: 700;
    color: rgb(97, 97, 97);
  }
  .explore {
    font-family: Circular-Std-Medium;
    font-size: 42px;
    letter-spacing: -1px;
    font-weight: 700;
    color: rgb(0, 119, 255);
  }
  .exploreArrow {
    font-size: 32px !important;
    margin-bottom: -5px;
  }
  .carselsectionone {
    padding-top: 100px;
    display: flex;
    overflow: scroll;
    position: relative;
    animation: 10s linear 0s infinite alternate-reverse movingoneM;
  }
  .carselsectionotwo {
    padding-top: 30px;
    display: flex;
    overflow: scroll;
    position: relative;
    animation: 20s linear 0s infinite alternate movingtwoM;
  }
  .carselsectionothird {
    padding-top: 30px;
    padding-bottom: 100px;
    display: flex;
    overflow: scroll;
    position: relative;
    animation: 30s linear 0s infinite alternate-reverse movingthirdM;
  }
  @keyframes movingoneM {
    from {
      margin-left: -30%;
    }
    to {
      margin-left: 0%;
    }
  }
  @keyframes movingtwoM {
    from {
      margin-left: -70%;
    }
    to {
      margin-left: 0%;
    }
  }
  @keyframes movingthirdM {
    from {
      margin-left: -90%;
    }
    to {
      margin-left: 0%;
    }
  }
}

@media only screen and (max-width: 320px) {
}
